
import { MyApps } from 'kls-catalog'
import { NewModelWizard } from '../module/new-model-wizard'

export default function MyAppsPage(props) {
    const mode = window.localStorage.getItem('mode')
    return (
        <div className={mode === 'dark' ? 'bg-light-dark' : ''} style={{ margin: '1.5rem auto', maxWidth: '1250px', width: '100%' }} >
            <MyApps gs={props.gs} creationWizard={creationWizard()} />
        </div>
    )
}

function creationWizard() {
    return (onOk: Function, onClose: Function) => <NewModelWizard onClose={onClose} />
}