import { useState, useEffect, useRef } from "react";
import "./appearance.css";
import { Input, Button, RadioButtons, addToast } from "kls-ui";
import { SettingsContainerHeader } from "../settings";
import { fetchLocalUserProfile } from "kls-commons/service/userService";
import { AppearanceServices, AppearanceUpdateServices } from "../settings-services/appearance-services";
import { error } from "console";
import { useHistory } from "react-router-dom";
export function Appearance(props: any) {
  const [selectedColor, setSelectedColor] = useState("blue");
  const [RadioBtnColorDate, setRadioBtnColorDate] = useState(true)
  const [RadioBtnColorDateReverse, setRadioBtnColorDateReverse] = useState(false)
  const [selectedThemeClair, setSelectedThemeClair] = useState(true);
  const [selectedThemeSombre, setSelectedThemeSombre] = useState(false);
  const [selectedThemeSystem, setSelectedThemeSystem] = useState(false);

  const setColor = (color) => {
    setSelectedColor(color);
  };
  const themeSelector = (c) => {
    if(c === "clair") {
      setSelectedThemeClair(true)
      setSelectedThemeSombre(false)
      setSelectedThemeSystem(false)
    }
    else if(c === "sombre") {
      setSelectedThemeClair(false)
      setSelectedThemeSombre(true)
      setSelectedThemeSystem(false)
    }
    else if(c === "system") {
      setSelectedThemeClair(false)
      setSelectedThemeSombre(false)
      setSelectedThemeSystem(true)
    }
    else {
      setSelectedThemeClair(true)
      setSelectedThemeSombre(false)
      setSelectedThemeSystem(false)
    }
  }

  const dateFormatSelector = (c) => {
    if(c === "jj/mm/aaaa") {
      setRadioBtnColorDate(true)
      setRadioBtnColorDateReverse(false)
    }
    else if(c === "aaaa-mm-jj") {
      setRadioBtnColorDate(false)
      setRadioBtnColorDateReverse(true)
    }
    else {
      setRadioBtnColorDate(true)
      setRadioBtnColorDateReverse(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [props])
  const fetchData = async () => {
    try {

      let loggedinUser = fetchLocalUserProfile();
      const fetchDataResult = await AppearanceServices(loggedinUser?.userId);
      console.log(fetchDataResult, 'fetchDataResult')
      setSelectedColor(fetchDataResult?.data?.color)
      let selectedMode = fetchDataResult?.data?.mode;
      themeSelector(selectedMode)
      let selectedFormatDate = fetchDataResult?.data?.formatDate;
      dateFormatSelector(selectedFormatDate)

    } catch (error) {
      console.log("Api Error", error);
    }
  }
  const appearanceChanges = async () => {
    try {
      let loggedinUser = fetchLocalUserProfile();
      let mode = selectedThemeClair ? "clair" : selectedThemeSombre ? "sombre" : selectedThemeSystem ? "system" : ''
      let formatDate = RadioBtnColorDate ? "jj/mm/aaaa" : RadioBtnColorDateReverse ? "aaaa-mm-jj" : ''
      await AppearanceUpdateServices(loggedinUser?.userId, mode, selectedColor, formatDate)
        .then(
          (result) => {
            console.log(result, "result")
            if (result?.error) {
              console.log("Error")
              addToast(result?.error, "error")
            } else if (result?.result == 'done') {
              console.log("Success")
              addToast("Paramètres d'apparence mis à jour", "success")
              window.location.reload();
            }
          },
          (error => {
            console.log("API_ERROR", error);
          })
        );
    }
    catch {
      console.log("Fetching error in organisation deletion")
    }
  }
  const history = useHistory()
  return (
    <div className="theme-container">
      <SettingsContainerHeader title="Apparence" subtitle="Modifiez le thème et les couleurs selon vos préférences. Exprimez-vous à travers votre style !" />
      <div className="theme-mode">
        <p className="theme-title2">Mode</p>
        <div className="theme-mode-color">
          <div className="auth-settings-apperance-div">
            {/* <div className={`auth-settings-apperance-div ${selectedThemeImg === 'appearance-whiteTheme' ? 'selected-img-theme appearance-BlueRatio' : ''}`} onClick={() => {setTheme('appearance-whiteTheme')}}><div className="appearance-themePicker" style={{ display: selectedThemeImg === "appearance-whiteTheme" ? 'block' : 'none', backgroundColor: selectedColor === "selectedThemeImg" ? 'blue' : '' }}></div> */}
            <p className="auth-settings-apperance-type">Clair</p>
            {/* <Input size="xs" args={{type: "radio", id: "clair", name: "theme-mode", className:"auth-settings-apperance-selectTheme"}} /> */}
            <div className="app-appearance-radioImgBtn appearance-clair-ratio">
              <div className="app-apperance-imgRadioPosition">
                <RadioButtons selectedRadio={selectedThemeClair} onclick={() => { setSelectedThemeSombre(false); setSelectedThemeSystem(false); setSelectedThemeClair(true) }} />
              </div>
            </div>
            <label htmlFor="clair">
              {/* <img src="photos/Mode-clair-theme.svg" alt="mode-clair" className="mode-img clair" /> */}
              <div className="clair"></div>
            </label>
          </div>
          <div className="auth-settings-apperance-div" onClick={() => console.log('Clicked')}>
            <p className="auth-settings-apperance-type somber-type">Sombre</p>
            {/* <Input size="xs" args={{type: "radio", id: "sombre", name: "theme-mode", className:"auth-settings-apperance-selectTheme"}} /> */}
            <div className="app-appearance-radioImgBtn appearance-somber-ratio">
              <div className="app-apperance-imgRadioPosition">
                <RadioButtons selectedRadio={selectedThemeSombre} onclick={() => { setSelectedThemeClair(false); setSelectedThemeSystem(false); setSelectedThemeSombre(true) }} />
              </div>
            </div>
            <label htmlFor="sombre">
              {/* <img src="photos/Mode-sombre-theme.svg" alt="mode-sombre" className="mode-img sombre" /> */}
              <div className="sombre"></div>
            </label>
          </div>
          <div className="auth-settings-apperance-div" onClick={() => console.log('Clicked')}>
            <p className="auth-settings-apperance-type system-type">Système</p>
            {/* <Input size="xs" args={{type: "radio", id: "system", name: "theme-mode", className:"auth-settings-apperance-selectTheme"}} /> */}
            <div className="app-appearance-radioImgBtn appearance-system-ratio">
              <div className="app-apperance-imgRadioPosition">
                <RadioButtons selectedRadio={selectedThemeSystem} onclick={() => { setSelectedThemeSombre(false); setSelectedThemeClair(false); setSelectedThemeSystem(true) }} />
              </div>
            </div>
            <label htmlFor="system">
              {/* <img src="photos/Mode-system-theme.svg" alt="mode-system" className="mode-img system" /> */}
              <div className="system"></div>
            </label>
          </div>
        </div>
      </div>
      <div className="auth-settings-apperance-colorThemeContainer">
        <div className="auth-settings-apperance-colorTheme">
          <p className="auth-settings-apperance-colorTitle">Couleur</p>
          <div className="auth-settings-apperance-colorThemeRadio">

            <div className={`app-appearance-colorPicker appearance-colorPurple ${selectedColor === '#B27FF9' ? 'selected-color-theme appearance-purple' : ''}`} onClick={() => setColor('#B27FF9')}><div className="appearance-colorpicker" style={{ display: selectedColor === "#B27FF9" ? 'block' : 'none', backgroundColor: selectedColor === "#B27FF9" ? '#B27FF9' : '' }}></div></div>
            {<div className={`app-appearance-colorPicker appearance-colorBlue ${selectedColor === '#2563EB' ? 'selected-color-theme appearance-blue' : ''}`} onClick={() => setColor('#2563EB')}><div className="appearance-colorpicker" style={{ display: selectedColor === "#2563EB" ? 'block' : 'none', backgroundColor: selectedColor === "#2563EB" ? '#2563EB' : '' }}></div></div>}
            <div className={`app-appearance-colorPicker appearance-colorGreen ${selectedColor === '#27C177' ? 'selected-color-theme appearance-green' : ''}`} onClick={() => setColor('#27C177')}><div className="appearance-colorpicker" style={{ display: selectedColor === "#27C177" ? 'block' : 'none', backgroundColor: selectedColor === "#27C177" ? '#27C177' : '' }}></div></div>
            <div className={`app-appearance-colorPicker appearance-colorSandal ${selectedColor === '#EFCD70' ? 'selected-color-theme appearance-sandal' : ''}`} onClick={() => setColor('#EFCD70')}><div className="appearance-colorpicker" style={{ display: selectedColor === "#EFCD70" ? 'block' : 'none', backgroundColor: selectedColor === "#EFCD70" ? '#EFCD70' : '' }}></div></div>
            <div className={`app-appearance-colorPicker appearance-colorOrange ${selectedColor === '#F88149' ? 'selected-color-theme appearance-orange' : ''}`} onClick={() => setColor('#F88149')}><div className="appearance-colorpicker" style={{ display: selectedColor === "#F88149" ? 'block' : 'none', backgroundColor: selectedColor === "#F88149" ? '#F88149' : '' }}></div></div>
            <div className={`app-appearance-colorPicker appearance-colorPink ${selectedColor === '#F764A4' ? 'selected-color-theme appearance-pink' : ''}`} onClick={() => setColor('#F764A4')}><div className="appearance-colorpicker" style={{ display: selectedColor === "#F764A4" ? 'block' : 'none', backgroundColor: selectedColor === "#F764A4" ? '#F764A4' : '' }}></div></div>
            <div className={`app-appearance-colorPicker appearance-colorRed ${selectedColor === '#FF6868' ? 'selected-color-theme appearance-red' : ''}`} onClick={() => setColor('#FF6868')}><div className="appearance-colorpicker" style={{ display: selectedColor === "#FF6868" ? 'block' : 'none', backgroundColor: selectedColor === "#FF6868" ? '#FF6868' : '' }}></div></div>
          </div>
        </div>
        <div className="auth-settings-apperance-dateContainer">
          <p className="auth-settings-apperance-colorTitle">Format de la date</p>
          <div className="auth-settings-apperance-dateRatio">
            <div className="auth-settings-apperance-dataRadio1">

              <RadioButtons selectedRadio={RadioBtnColorDate} onclick={() => { setRadioBtnColorDate(true); setRadioBtnColorDateReverse(false) }} />

              {/* <Input size="xs" args={{type: "radio", id: "date", name: "date-change"}} /> */}
              {/* <div className="app-apperance-radioButtonContainer" onClick={() => {setSelectedRadio('appearance-radioBtnColor'); console.log(selectedRadio)}}>
                <div className={`${selectedRadio === 'appearance-radioBtnColor' ? 'app-apperance-insideRadioBtn' : ''} `} style={{display: selectedRadio === "appearance-radioBtnColor" ? 'block' : 'none'}}></div>
              </div> */}
              <label htmlFor="date">
                <p className="auth-settings-apperance-dateChangeFont">jj-mm-aaaa</p>
              </label>
            </div>
            <div className="auth-settings-apperance-dataRadio1">
              {/* <Input size="xs" args={{type: "radio", id: "date-reverse", name: "date-change"}} /> */}
              <RadioButtons selectedRadio={RadioBtnColorDateReverse} onclick={() => { setRadioBtnColorDate(false); setRadioBtnColorDateReverse(true) }} />
              {/* <div className="app-apperance-radioButtonContainer" onClick={() => {setSelectedRadio('appearance-radioBtnColorReverse'); console.log(selectedRadio)}}>
                <div className={`${selectedRadio === 'appearance-radioBtnColorReverse' ? 'app-apperance-insideRadioBtn' : ''}`} style={{display: selectedRadio === "appearance-radioBtnColorReverse" ? 'block' : 'none'}}></div>
              </div> */}
              <label htmlFor="date-reverse">
                <p className="auth-settings-apperance-dateChangeFont">aaaa-mm-jj</p>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="app-appearance-btn">
        <div className="app-appearance-all-submit-buttons">
          <Button size="md" label="Annuler" styling="outline" color="neutral"  args={{ onClick: (e) => { history.push('/');   }, className: 'app-appearance-cancel' }} />
          <Button size="md" label="Enregistrer" styling="solid" color="primary" args={{ onClick: (e) => { appearanceChanges() } }} />
        </div>
      </div>
    </div>
  )
}