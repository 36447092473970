import { AdvancedDropDown, Input, Modal, SearchInput, ToggleButton } from "kls-ui";
import { SettingsContainerHeader } from "../../../settings/settings";
import './schema.css'
import { useEffect, useState } from "react";
import { FilterbarInput } from "kls-catalog";

export function ModuleDataSchema() {
    const [filterValue, setfilterValue] = useState('')
    const [isFilters, setisFilters] = useState(false)
    const [typeFilterDropdown, settypeFilterDropdown] = useState([{label: 'Chaîne', value: 'Chaîne'}])
    const [multipleFilterDropdown, setmultipleFilterDropdown] = useState([{label: 'Tous', value: 'Tous'}])
    const [indexFilterDropdown, setindexFilterDropdown] = useState([{label: 'Tous', value: 'Tous'}])
    const [protectionFilterDropdown, setprotectionFilterDropdown] = useState([{label: 'Aucune', value: 'Aucune'}, {label: 'Personnelle', value: 'Personnelle'}, {label: 'Médicale', value: 'Médicale'}])
    const [isMultipleFilter, setisMultipleFilter] = useState(false)
    const [multipleFilter, setmultipleFilter] = useState('')
    const [isIndexFilter, setisIndexFilter] = useState(false)
    const [IndexFilter, setIndexFilter] = useState('')
    const [isTypeFilter, setisTypeFilter] = useState(false)
    const [isProtectionFilter, setisProtectionFilter] = useState(false)
    const [filterToggle, setfilterToggle] = useState(false)
    const [schemaData, setschemaData] = useState([1])
    const [createSchema, setcreateSchema] = useState(false)
    const [editSchema, seteditSchema] = useState(false)
    const [moreOptions, setmoreOptions] = useState(false)
    const [isChooseParent, setisChooseParent] = useState(false)
    const [isChooseParentFilter, setisChooseParentFilter] = useState(false)
    const [displacerMoreOptions, setdisplacerMoreOptions] = useState(false)
    const [chooseParentFilter, setchooseParentFilter] = useState('')
    const [chooseParentDropdown, setchooseParentDropdown]= useState([{label: "Organisation", value: "Organisation"}])
    const [moreOptionsDropdown, setmoreOptionsDropdown] = useState([{label: "Ajouter sous élément", value: "Ajouter sous élément", icon: "add", color: "#64748B"}, {label: "Déplacer", value: "Déplacer", icon: "games", color: "#64748B"}, {label: "Supprimer", value: "Supprimer", icon: "delete", color: "#EF4444", labelColor: "#DC2626"}])
    const [moreOptionsDisplacer, setmoreOptionsDisplacer] = useState([{label: "Déplacer vers le haut", icon: "move_up", color: "#64748B"}, {label: "Déplacer vers le bas", icon: "move_down", color: "#64748B"}, {label: "Choisir parent", icon: "mode_edit", color: "#64748B"}])
    const [moreOptionsFilter, setmoreOptionsFilter] = useState('')
    const [schemaToggler, setschemaToggler] = useState({parent: false, child1: false, child2: false})
    const [createSchemaData, setcreateSchemaData] = useState({identifier: '', name: '', parent: '', toggle: '', type: '', default: '', protection: ''})
    const [errorMessage, seterrorMessage] = useState({identifier: '', name: ''})
    const [hasChild, sethasChild] = useState(false)
    const handleToggle = (value) => {
        if(createSchemaData.toggle === '') {
            setcreateSchemaData({...createSchemaData, toggle: value})
        }
        else {
            setcreateSchemaData({...createSchemaData, toggle: ''})
        }
    }
    const handleSubmit = () => {
        if(createSchemaData.identifier === '') {
            seterrorMessage({...errorMessage, identifier: 'Ce champ est requis'})
        }
        else {
            seterrorMessage({...errorMessage, identifier: ''})
        }
    }
    const handleClose = () => {
        if(createSchema) {
            setcreateSchema(false); 
            seterrorMessage({identifier: '', name: ''})
            setcreateSchemaData({identifier: '', name: '', parent: '', toggle: '', type: '', default: '', protection: ''})
        }
        else if(editSchema) {
            seteditSchema(false)
        }
    }

    const handleDisplacerClick = (e) => {
        if(e === 'mode_edit') {
            setisChooseParent(true)
        }
    }

    const handleContextMenu = (event) => {
        event.preventDefault();
        setmoreOptions(true)
      };

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            const d = document.getElementById('moreoptions');
            const f = document.getElementById('displacermoreoptions');
            if ((d && !d.contains(e.target as HTMLElement)) && (f && !f.contains(e.target as HTMLElement))) {
                setmoreOptions(false);
                setdisplacerMoreOptions(false)
            }
            else if ((d && !d.contains(e.target as HTMLElement)) && (f && f.contains(e.target as HTMLElement))) {
                setmoreOptions(true);
            }
            else if(d && !d.contains(e.target as HTMLElement) && !displacerMoreOptions) {
                setmoreOptions(false);
            }
        };
        document.body.addEventListener('mousedown', handleClickOutside);
    }, [setmoreOptions, setdisplacerMoreOptions]);

    return(
        <div className="module-schema-main">
            <SettingsContainerHeader title="Schéma de données" subtitle="Gérez la structure de données de votre module" enableAction buttonName="Nouvel identifiant" onClick={() => setcreateSchema(true)} />
            <div className="module-schema-content">
                <div className="module-schema-search-filter">
                    <div className="module-schema-search"><SearchInput light value={filterValue} setValue={setfilterValue} size={3} placeholder="Rechercher" addClearbutton style={{height: '40px', borderRadius: '8px'}} /></div>
                    <div className="module-schema-filters" onClick={() => setisFilters(!isFilters)} style={{color: isFilters ? '#2563EB' : 'black'}}>
                        <span>Filtres</span>
                        <span className="icon">filter_list</span>
                    </div>
                </div>
                {isFilters&& 
                    <div className="module-schema-filter-dropdown">
                        <div className="module-schema-filter-bar"><FilterbarInput tagPlaceholder="type" label="Type" dropdownData={typeFilterDropdown} /></div>
                        <div className="module-schema-filter-value">
                            <h1 className="module-schema-filter-header">Multiple</h1>
                            <div className='module-schema-filter-container'>
                                <div className='module-schema-filter-content-primary-div' onClick={() => setisMultipleFilter(!isMultipleFilter)}>
                                {multipleFilter === '' ? <span style={{ color: '#94A3B8' }}>Multiple</span> : multipleFilter}
                                </div>
                                {isMultipleFilter &&
                                <AdvancedDropDown
                                    data={multipleFilterDropdown}
                                    light
                                    size={3}
                                    enableSearch={false}
                                    handleChange={(value) => { setmultipleFilter(value.label) }}
                                    selectedValue={multipleFilter}
                                    style={{ top: '50px', width: '100%', left: '0px', position: 'absolute' }}
                                    closeWhenSelect={() => { setisMultipleFilter(false) }}
                                    closeWhenClickOutside={() => { setisMultipleFilter(false) }}

                                />}
                                <div className='module-schema-input-filter-icons'>
                                <span className='icon' onClick={() => {setmultipleFilter('') }} style={{ cursor: 'pointer', color: '#94A3B8' }}>close</span>
                                <span className="icon" style={{ cursor: 'pointer', color: '#94A3B8' }} onClick={() => setisMultipleFilter(!isMultipleFilter)}>expand_more</span>
                                </div>
                            </div>
                        </div>
                        <div className="module-schema-filter-value">
                            <h1 className="module-schema-filter-header">Indexé</h1>
                            <div className='module-schema-filter-container'>
                                <div className='module-schema-filter-content-primary-div' onClick={() => setisIndexFilter(!isIndexFilter)}>
                                {IndexFilter === '' ? <span style={{ color: '#94A3B8' }}>Indexé</span> : IndexFilter}
                                </div>
                                {isIndexFilter &&
                                <AdvancedDropDown
                                    data={indexFilterDropdown}
                                    light
                                    size={3}
                                    enableSearch={false}
                                    handleChange={(value) => { setIndexFilter(value.label) }}
                                    selectedValue={IndexFilter}
                                    style={{ top: '50px', width: '100%', left: '0px', position: 'absolute' }}
                                    closeWhenSelect={() => { setisIndexFilter(false) }}
                                    closeWhenClickOutside={() => { setisIndexFilter(false) }}

                                />}
                                <div className='module-schema-input-filter-icons'>
                                <span className='icon' onClick={() => {setIndexFilter('') }} style={{ cursor: 'pointer', color: '#94A3B8' }}>close</span>
                                <span className="icon" style={{ cursor: 'pointer', color: '#94A3B8' }} onClick={() => setisIndexFilter(!isIndexFilter)}>expand_more</span>
                                </div>
                            </div>
                        </div>
                        <div className="module-schema-filter-toggle">
                            <ToggleButton ischecked={filterToggle} label="Seuls avec Valeur par défaut" size="md" handleToggle={() => setfilterToggle(!filterToggle)} />
                        </div>
                    </div>
                }
            </div>
            <div className="module-schema-data">
                {schemaData.length === 0 ? <div style={{color: '#94A3B8', fontSize: '13px', textAlign: 'center'}}>Aucun résultat</div> : 
                    <div className="organisation">
                        <div className="parent-toggler toggle" onClick={() => setschemaToggler({...schemaToggler, parent: !schemaToggler.parent})}>
                            <span className="icon">expand_more</span>
                            <span>Organisation</span>
                        </div>
                        <div className={!schemaToggler.parent ? 'hidden' : ''}>
                            <ModalIdentifier title="id" type="string" text="Chaîne" hash="Indexé" />
                            <div className="module-schema-icons" onContextMenu={handleContextMenu}>
                                <ModalIdentifier title="identifiantRcIce" type="string" text="Chaîne" hash="Indexé" security="Personnelle" />
                                <div className="module-schema-listed" onClick={() => seteditSchema(true)}>
                                    <span className="icon" style={{cursor: 'pointer'}}>format_list_bulleted</span>
                                </div>
                                {moreOptions&& 
                                    <div className="module-schema-more-options" id="moreoptions">
                                        {moreOptionsDropdown.map((c) => {
                                            return(
                                                <div className="module-schema-dropdowns-main" key={c.icon} onClick={() => c.icon === 'games' && setdisplacerMoreOptions(true)}>
                                                    <div className="module-schema-dropdowns">
                                                        <span className="icon" style={{color: c.color}}>{c.icon}</span>
                                                        <span>{c.label}</span>
                                                    </div>
                                                    {c.icon === 'games' && <span className="icon" style={{cursor: 'pointer'}}>chevron_right</span>}
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                                {displacerMoreOptions&& 
                                    <div className="module-schema-more-options1" id="displacermoreoptions" style={{left: '228px', top: '65px'}}>
                                        {moreOptionsDisplacer.map((c) => {
                                            return(
                                                <div className="module-schema-dropdowns-main" key={c.icon} onClick={() => handleDisplacerClick(c.icon)}>
                                                    <div className="module-schema-dropdowns">
                                                        <span className="icon" style={{color: c.color}}>{c.icon}</span>
                                                        <span>{c.label}</span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                            <div>
                                <div className="parent-toggler toggle" onClick={() => setschemaToggler({...schemaToggler, child1: !schemaToggler.child1})} style={{paddingLeft: '24px'}}>
                                    <span className="icon">expand_more</span>
                                    <span>localite</span>
                                    <div className="field" style={{gap: '5px'}}>
                                        <img src="/icons/rows.svg" alt="rows" />
                                        <span className="value">Multiple</span>
                                    </div>
                                </div>
                                <div className={!schemaToggler.child1 ? 'hidden' : ''}>
                                    <ModalIdentifier title="id" type="string" text="Chaîne" paddingLeft="48px" />
                                    <ModalIdentifier title="adresse" type="string" text="Chaîne" paddingLeft="48px" />
                                    <ModalIdentifier title="ville" type="string" text="Chaîne" paddingLeft="48px" />
                                    <ModalIdentifier title="pays" type="string" text="Chaîne" equals="Maroc" paddingLeft="48px" />
                                </div>
                            </div>
                            <div>
                                <div className="parent-toggler toggle" onClick={() => setschemaToggler({...schemaToggler, child2: !schemaToggler.child2})} style={{paddingLeft: '24px'}}>
                                    <span className="icon">expand_more</span>
                                    <span>representant</span>
                                </div>
                                <div className={!schemaToggler.child2 ? 'hidden' : ''}>
                                    <ModalIdentifier title="fullname" type="string" text="Chaîne" hash="Indexé" paddingLeft="48px" />
                                    <ModalIdentifier title="nom" type="string" text="Chaîne" paddingLeft="48px" />
                                    <ModalIdentifier title="prenom" type="string" text="Chaîne" paddingLeft="48px" />
                                    <ModalIdentifier title="typeIdentifiant" type="string" text="Chaîne" equals="cin" paddingLeft="48px" />
                                    <ModalIdentifier title="identifiant" type="string" text="Chaîne" paddingLeft="48px" />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {(createSchema || editSchema) && <Modal
                icon=""
                leftBtnArgs={{
                  args: { onClick: handleClose },
                  color: 'neutral',
                  label: 'Annuler',
                  size: 'sm',
                  styling: 'outline'
                }}
                rightBtnArgs={{
                  args: { onClick: handleSubmit },
                  color: 'primary',
                  label: createSchema ? 'Ajouter' : editSchema ? 'Enregistrer' : '',
                  size: 'sm',
                  styling: 'solid',
      
                }}
                title= {createSchema ? "Nouvel identifiant" : editSchema ? 'Paramètres d’identifiant' : ''}
                onClose={handleClose}
                bodyStyle={{ padding: '24px 16px' }} >
                    <div className="module-schema-create-content">
                        <Input size="md" label="Identifiant" errorMessage={errorMessage.identifier} args={{placeholder: "Entrez l’identifiant", value: createSchemaData.identifier, onChange: (e) => setcreateSchemaData({...createSchemaData, identifier: e.target.value})}} containerStyle={{borderRadius: '8px'}} />
                        <Input size="md" label="Nom" errorMessage={errorMessage.name} args={{placeholder: "Entrez le nom", value: createSchemaData.name, onChange: (e) => setcreateSchemaData({...createSchemaData, name: e.target.value})}} containerStyle={{borderRadius: '8px'}} />
                        {editSchema && 
                            <div className="edit-schema-parent">
                                <div className="edit-schema-title">Parent</div>
                                <div className="edit-schema-subtitle">{createSchemaData.parent === '' ? '-' : createSchemaData.parent}</div>
                            </div>
                        }
                        <div className="module-schema-create-toggle">
                            <div style={{display: createSchemaData.toggle === 'multiple' || createSchemaData.toggle === '' ? 'block' : 'none', width: '50%'}}><ToggleButton label="Multiple" ischecked={createSchemaData.toggle === 'multiple' ? true : false} size="md" handleToggle={() => handleToggle('multiple')} /></div>
                            <div style={{display: createSchemaData.toggle === 'index'  || createSchemaData.toggle === '' ? 'block' : 'none'}}><ToggleButton label="Indexé" ischecked={createSchemaData.toggle === 'index' ? true : false} size="md" handleToggle={() => handleToggle('index')} /></div>
                        </div>
                        {((editSchema && !hasChild) || createSchema) && 
                            <>
                                <div className="module-schema-filter-value-type">
                                    <h1 className="module-schema-filter-header">Type</h1>
                                    <div className='module-schema-filter-container'>
                                        <div className='module-schema-filter-content-primary-div' onClick={() => setisTypeFilter(!isTypeFilter)}>
                                        {createSchemaData.type === '' ? <span style={{ color: '#94A3B8' }}>Type</span> : <span style={{ color: 'black' }}>{createSchemaData.type}</span>}
                                        </div>
                                        {isTypeFilter &&
                                        <AdvancedDropDown
                                            data={typeFilterDropdown}
                                            light
                                            size={3}
                                            enableSearch={false}
                                            handleChange={(value) => { setcreateSchemaData({...createSchemaData, type: value.label}) }}
                                            selectedValue={createSchemaData.type}
                                            style={{ top: '50px', width: '100%', left: '0px', position: 'absolute' }}
                                            closeWhenSelect={() => { setisTypeFilter(false) }}
                                            closeWhenClickOutside={() => { setisTypeFilter(false) }}

                                        />}
                                        <div className='module-schema-input-filter-icons'>
                                        <span className='icon' onClick={() => {setcreateSchemaData({...createSchemaData, type: ''}) }} style={{ cursor: 'pointer', color: '#94A3B8' }}>close</span>
                                        <span className="icon" style={{ cursor: 'pointer', color: '#94A3B8' }} onClick={() => setisTypeFilter(!isTypeFilter)}>expand_more</span>
                                        </div>
                                    </div>
                                </div>
                                <Input size="md" label="Valeur par défaut" errorMessage="" args={{placeholder: "Valeur par défaut", value: createSchemaData.default, onChange: (e) => setcreateSchemaData({...createSchemaData, default: e.target.value})}} containerStyle={{borderRadius: '8px'}} />
                                {editSchema && 
                                    <div className="module-schema-filter-value-type">
                                        <h1 className="module-schema-filter-header">Protection de donnée</h1>
                                        <div className='module-schema-filter-container'>
                                            <div className='module-schema-filter-content-primary-div' onClick={() => setisProtectionFilter(!isProtectionFilter)}>
                                            {createSchemaData.protection === '' ? <span style={{ color: '#94A3B8' }}>Protection</span> : <span style={{ color: 'black' }}>{createSchemaData.protection}</span>}
                                            </div>
                                            {isProtectionFilter &&
                                            <AdvancedDropDown
                                                data={protectionFilterDropdown}
                                                light
                                                size={3}
                                                enableSearch={false}
                                                handleChange={(value) => { setcreateSchemaData({...createSchemaData, protection: value.label}) }}
                                                selectedValue={createSchemaData.protection}
                                                style={{ top: '50px', width: '100%', left: '0px', position: 'absolute' }}
                                                closeWhenSelect={() => { setisProtectionFilter(false) }}
                                                closeWhenClickOutside={() => { setisProtectionFilter(false) }}

                                            />}
                                            <div className='module-schema-input-filter-icons'>
                                            <span className='icon' onClick={() => {setcreateSchemaData({...createSchemaData, protection: ''}) }} style={{ cursor: 'pointer', color: '#94A3B8' }}>close</span>
                                            <span className="icon" style={{ cursor: 'pointer', color: '#94A3B8' }} onClick={() => setisProtectionFilter(!isProtectionFilter)}>expand_more</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
            </Modal>}
            {isChooseParent && 
                <Modal 
                    icon=""
                    leftBtnArgs={{
                    args: { onClick: () => setisChooseParent(false) },
                    color: 'neutral',
                    label: 'Annuler',
                    size: 'sm',
                    styling: 'outline'
                    }}
                    rightBtnArgs={{
                    args: { onClick: () => console.log("clicked") },
                    color: 'primary',
                    label: 'Valider',
                    size: 'sm',
                    styling: 'solid',
        
                    }}
                    title= 'Changer parent identifiantRcIce'
                    onClose={() => setisChooseParent(false)}
                    bodyStyle={{ padding: '24px 16px' }}
                >
                    <div className="module-schema-filter-value-type">
                        <h1 className="module-schema-filter-header">Parent</h1>
                        <div className='module-schema-filter-container'>
                            <div className='module-schema-filter-content-primary-div' onClick={() => setisChooseParentFilter(!isChooseParentFilter)}>
                            {chooseParentFilter === '' ? <span style={{ color: '#94A3B8' }}>Chooser Parent</span> : <span style={{ color: 'black' }}>{chooseParentFilter}</span>}
                            </div>
                            {isChooseParentFilter &&
                            <AdvancedDropDown
                                data={chooseParentDropdown}
                                light
                                size={3}
                                enableSearch={true}
                                handleChange={(value) => { setchooseParentFilter(value.label) }}
                                selectedValue={chooseParentFilter}
                                style={{ top: '50px', width: '100%', left: '0px', position: 'absolute' }}
                                closeWhenSelect={() => { setisChooseParentFilter(false) }}
                                closeWhenClickOutside={() => { setisChooseParentFilter(false) }}
                                maxItemsPerPage={3}
                                maxPageToDisplay={Math.ceil(chooseParentDropdown.length / 3)}
                            />}
                            <div className='module-schema-input-filter-icons'>
                            <span className='icon' onClick={() => {setchooseParentFilter('')}} style={{ cursor: 'pointer', color: '#94A3B8' }}>close</span>
                            <span className="icon" style={{ cursor: 'pointer', color: '#94A3B8' }} onClick={() => setisChooseParentFilter(!isChooseParentFilter)}>expand_more</span>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}

const ModalIdentifier = ({title, type, text, hash, security, equals, paddingLeft='24px'}: {title?: string, type?: string, text?: string, hash?: string, security?: string, equals?: string, paddingLeft?: string}) => {
    const Type = type === 'string' ? 'Aa' : ''
    return(
        <div className="parent-toggler toggle" style={{paddingLeft: paddingLeft}}>
            <span>{title}</span>
            {type && text && <div className="field">
                <span className="fonts">{Type}</span>
                <span className="value">{text}</span>
            </div>}
            {hash && <div className="field">
                <span className="icon" style={{color: '#CA8A04'}}>tag</span>
                <span className="value">{hash}</span>
            </div>}
            {security && <div className="field">
                <img src="/icons/shield-checkered.svg" alt="shield" />
                <span className="value">{security}</span>
            </div>}
            {equals && <div className="field">
                <img src="/icons/Equals.svg" alt="equals" />
                <span className="value">{equals}</span>
            </div>}
        </div>
    )
}