import React from 'react'
import { postMap } from 'kls-commons/service/karaz-remote-action'

export async function OrganizationSettingsService(userID: any, organisationID: any) {

    let actionUrl = '/karazal/ow-kls-extrat/appmanager/organismedetails';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("organismId", organisationID);
    urlencoded.append("userId", userID);


    let data = await postMap(urlencoded, actionUrl, 'json');
    let result = JSON.parse(data);
    return result;
}



export async function OrganizationSettingsUpdate(userID: any, organisationID: any, organizationname: any, acronyme: any, industry: any, selectedColor: any, description: any, tagvalue: any, organizationMail: any, telephoneValue: any, identifiant: any, webSite: any, selectedImage: any, hashTag: any, domainName: any) {

    console.log('`${hashTag}`', ("[" + hashTag + "]"))

    let actionUrl = '/karazal/ow-kls-extrat/appmanager/updateOrganisme';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("organisationId", organisationID);
    urlencoded.append("userId", userID);
    urlencoded.append("name", organizationname);
    urlencoded.append("industrie", industry);
    urlencoded.append("domain", domainName);
    urlencoded.append("email", organizationMail);
    urlencoded.append("phoneNumber", telephoneValue);
    urlencoded.append("couleur", selectedColor);
    urlencoded.append("tags", ("[" + hashTag + "]"));
    urlencoded.append("adresses", "");
    urlencoded.append("acronyme", acronyme);
    urlencoded.append("description", description);
    urlencoded.append("identifiant", identifiant);
    urlencoded.append("site", webSite);
    urlencoded.append("logoGedId", selectedImage);

    let data = await postMap(urlencoded, actionUrl, 'json');
    let result = JSON.parse(data);
    return result;
}

// TEsting

export async function OrganizationAddressCreate(userID: any, organisationID: any, addressline: any, addressLocalite: any, addressPays: any, locationValue: any) {
    let actionUrl = '/karazal/ow-kls-extrat/appmanager/addAdresse';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("organisationId", organisationID);
    urlencoded.append("userId", userID);
    urlencoded.append("addressType", locationValue);
    urlencoded.append("address", addressline);
    urlencoded.append("pays", addressPays);
    urlencoded.append("localite", addressLocalite);

    let data = await postMap(urlencoded, actionUrl);
    return data;
}

export async function OrganizationAddressUpdate(userID: any, organisationID: any, addressline: any, addressPays: any, addressLocalite: any, typeLocal: any, addressId: any) {
    let actionUrl = '/karazal/ow-kls-extrat/appmanager/updateAdresse';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("userId", userID);
    urlencoded.append("organisationId", organisationID);
    urlencoded.append("addressType", typeLocal);
    urlencoded.append("address", addressline);
    urlencoded.append("pays", addressPays);
    urlencoded.append("localite", addressLocalite);
    urlencoded.append("adressId", addressId);

    let data = await postMap(urlencoded, actionUrl);
    return data;
}

export async function OrganizationAddressDelete(userID: any, organisationID: any,currentId:any) {
    console.log('currentId',currentId)
    let actionUrl = '/karazal/ow-kls-extrat/appmanager/deletAdresse';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("userId", userID);
    urlencoded.append("organisationId", organisationID);
    urlencoded.append("adressId", currentId);

    let data = await postMap(urlencoded, actionUrl);
    return data;
}