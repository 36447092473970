import { useEffect, useState } from "react";
import "../app-params/param-app.css"
import { SettingsContainerHeader } from "../settings/settings";
import { ModuleCard, MoreActions, NoResultPage, OraganizationTable, SearchInput, VerificationCode, addToast, ModuleCardTable } from "kls-ui";
import './module-component.css'
import { Pagination } from "kls-ui/paginationKls/pagination";
import { useHistory } from "react-router-dom";


export function ModuleComponent(props: any) {

    let [pageView, setpageView] = useState('grid');
    let [noResult, setNoResult] = useState(false);
    let [moduleAll, setModuleAll] = useState<any>([]);
    const history = useHistory();
    const [ filter, setFilter ] = useState('');
    const [pagination, setPagination] = useState(true);
    const [currentPageIndex, setcurrentPageIndex] = useState(0);
    const [paginationList, setPaginationList] = useState(true);
    const [currentPageIndexList, setcurrentPageIndexList] = useState(0);
    const [verificationPop, setVerificationPop] = useState(false);

    let filteredModuleData = filter !== '' ? moduleAll.filter(o => 
        o&&o.module.toLowerCase().includes(filter.toLowerCase())
    ) : moduleAll;

    const [isDisplayModeList, setDisplayModeList] = useState<boolean>(false);
    function DisplayModeApp({ icon, isSelected, onClick }: { icon: string, isSelected: boolean, onClick: () => void }) {

        return <button className={'display-mode-myOrg ' + (isSelected ? "selected" : "")} onClick={onClick} disabled={isSelected}>
            <span className='material-icon'>{icon}</span>
        </button>
    }
    const dataModule = filteredModuleData.map((d)=>{
        return{
            image: d?.image,
            module: d?.module,
            modify: d?.modify,
            create: d?.create,
            actions: <MoreActions style={{right: '0'}} actions={[
                {
                  label: 'Editer',
                  value: 'editer',
                  icon: 'edit',
                  color: '#64748B',
                  handleClick: () => {
                    console.log('Editer')
                  }
                },
                {
                  label: 'Paramétres',
                  value: 'parametre',
                  icon: 'settings',
                  color: '#64748B',
                  handleClick: () => {
                    history.push("/module-settings/" + d?.id + '/');
                    console.log('param1')
                  }
                },
                {
                  label: 'Supprimer',
                  value: 'supprimer',
                  icon: 'delete',
                  color: '#EF4444',
                  labelColor: '#EF4444',
                  handleClick: () => {
                    setVerificationPop(true);
                  }
                }
              ]} />
        }
    })

    console.log(dataModule.length, "length")

    const itemsPerPage = 8;
    const totalPages = Math.ceil(moduleAll.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, moduleAll.length);
    let dataRow = filteredModuleData && filteredModuleData.slice(startIndex, endIndex);

    const itemsPerPageList = 5;
    const totalPagesList = Math.ceil(dataModule.length / itemsPerPageList);
    const [currentPageList, setCurrentPageList] = useState<number>(1);
    const startIndexList = (currentPageList - 1) * itemsPerPageList;
    const endIndexList = Math.min(startIndexList + itemsPerPageList, dataModule.length);
    let dataRowList = dataModule && dataModule.slice(startIndexList, endIndexList);

    const handlePageChange = (start: number, end: number) => {
        const newCurrentPage = Math.floor(start / itemsPerPage) + 1;
        setCurrentPage(newCurrentPage)
        setcurrentPageIndex(0)
    };

    console.log(totalPages, currentPage, startIndex, endIndex, dataRow, "dataRow")


    const handlePageChanger = (start: number, end: number) => {
        const newCurrentPageList = Math.floor(start / itemsPerPageList) + 1;
        setCurrentPageList(newCurrentPageList) 
    };
    console.log(totalPagesList, currentPageList, startIndexList, endIndexList, dataRowList, "dataRowList")

    useEffect(() => {
        let data = [{
            id: 1,
            image: 'photos/module-card1.svg',
            color: "#BFDBFE",
            module: "Gestion des projets",
            modify: "Modifié il y a 2 jours",
            create: "il y a 7 jours"
        },
        {
            id: 2,
            image: 'photos/module-card2.svg',
            color: '#FEF08A',
            module: 'Création des investisseurs',
            modify: 'Modifié il y a 2 jours',
            create: "il y a 26 jours"
        },
        {
            id: 3,
            image: 'photos/module-card3.svg',
            color: '#DDD6FE',
            module: 'Création des projets',
            modify: 'Modifié il y a 2 jours',
            create: "il y a 3 mois"
        },
        {
            id: 4,
            image: 'photos/module-card4.svg',
            color: '#BFDBFE',
            module: 'Création des investisseurs',
            modify: 'Modifié il y a 2 jours',
            create: "il y a 7 jours"
        },
        {
            id: 5,
            image: 'photos/module-card1.svg',
            color: "#BFDBFE",
            module: "Gestion des projets",
            modify: "Modifié il y a 2 jours",
            create: "il y a 7 jours"
        },
        {
            id: 6,
            image: 'photos/module-card2.svg',
            color: '#FEF08A',
            module: 'Création des investisseurs',
            modify: 'Modifié il y a 2 jours',
            create: "il y a 7 jours"
        },
        {
            id: 7,
            image: 'photos/module-card3.svg',
            color: '#DDD6FE',
            module: 'Création des projets',
            modify: 'Modifié il y a 2 jours',
            create: "il y a 7 jours"
        },
        {
            id: 8,
            image: 'photos/module-card4.svg',
            color: '#BFDBFE',
            module: 'Création des investisseurs',
            modify: 'Modifié il y a 2 jours',
            create: "il y a 7 jours"
        },
        {
            id: 9,
            image: 'photos/module-card1.svg',
            color: "#BFDBFE",
            module: "Gestion des projets",
            modify: "Modifié il y a 2 jours",
            create: "il y a 7 jours"
        }]
        setModuleAll(data)
        setCurrentPage(1)
        setCurrentPageList(1)
        if(data.length === 0) {
            setNoResult(true);
        }
    }, [filter])

    

    const handleSearchInputChange = (value: string) => {
        setFilter(value);
        setCurrentPage(1)
        setcurrentPageIndex(1)
        filteredModuleData = value !== '' ? moduleAll.filter(o => 
            o&&o.module.toLowerCase().includes(value.toLowerCase())
        ) : moduleAll
        if(filteredModuleData.length <= 8){
            setPagination(false)
        } else {
            setPagination(true)
        }
      };

      const handleSearchInputChanger = (value: string) => {
        setFilter(value);
        setCurrentPageList(1)
        setcurrentPageIndexList(1)
        filteredModuleData = value !== '' ? dataModule.filter(o => 
            o&&o.module.toLowerCase().includes(value.toLowerCase())
        ) : dataModule
        if(filteredModuleData.length <= 5){
            setPaginationList(false)
        } else {
            setPaginationList(true)
        }
        console.log(filteredModuleData, "filteredModuleData")
      };

      const handleVerificationPopUpClose = () => {
        setVerificationPop(false);
      }

      const handleVerificationDelete = ()=>{
        setVerificationPop(false);
        addToast(" Le module a été supprimé avec succès", "success")
      }
    

    return (
        <div className="app-modules-container">
            <SettingsContainerHeader title="Modules" subtitle="Les modules de votre application" enableAction={true} buttonName="Nouvel module" icon="add" />
            <div className="app-module-topContent">
                <span style={{ width: '90%', display: 'flex' }}>
                    <SearchInput light={true} size={3} value={filter} setValue={(e)=>{handleSearchInputChange(e); handleSearchInputChanger(e)}} addClearbutton={true} />
                </span>
                <img className="app-module-verticalline" src="/icons/verticalline.svg" alt="verticalline" ></img>
                <div style={{ display: "flex", gap: '10px' }}>
                    <DisplayModeApp icon="grid_view" onClick={() => { setpageView('grid'); setDisplayModeList(false) }} isSelected={!isDisplayModeList} />
                    <DisplayModeApp icon="format_list_bulleted" onClick={() => { setpageView('list'); setDisplayModeList(true) }} isSelected={isDisplayModeList} />
                </div>
            </div>
            {noResult && <div>
                <NoResultPage title="Aucun module pour l'instant" subtitle="La liste des modules créés sera affichée ici" img="photos/module-noResult-icon.svg" />
            </div>}
            {pageView === 'grid' && <div className="app-module-cardContent">
                {
                    dataRow.length > 0 ? dataRow.map((value, key) => {
                        return <ModuleCard
                            key={key + 1}
                            image={value?.image}
                            color={value?.color}
                            module={value?.module}
                            modify={value?.modify}
                            actions={<MoreActions actions={[
                                {
                                    label: 'Editer',
                                    value: 'editer',
                                    icon: 'edit',
                                    color: '#64748B',
                                    handleClick: () => {
                                        //   history.push('/application')
                                        console.log('application')
                                    }
                                },
                                {
                                    label: 'Paramétres',
                                    value: 'parametre',
                                    icon: 'settings',
                                    color: '#64748B',
                                    handleClick: () => {
                                        history.push("/module-settings/" + value?.id + '/');
                                        console.log('param')
                                    }
                                },
                                {
                                    label: 'Supprimer',
                                    value: 'supprimer',
                                    icon: 'delete',
                                    color: '#EF4444',
                                    labelColor: '#EF4444',
                                    handleClick: () => {setVerificationPop(true);}
                                }
                            ]} />}

                        />
                    }): moduleAll.length > 0 && filter !== '' && <div style={{ display: 'flex', justifyContent: 'center', color: '#94A3B8', width: '100%' }}>Aucun résultat</div>
                }</div>}
            {pageView === 'list' && <div className="app-module-listCardContent">
                {dataModule.length > 0 ? <ModuleCardTable data={dataRowList} />: moduleAll.length > 0 && filter !== '' && <div style={{ display: 'flex', justifyContent: 'center', color: '#94A3B8', width: '100%' }}>Aucun résultat</div>}
                </div>}
            {pagination && dataRow.length > 0 && pageView === 'grid' && <Pagination light={true} totalItems={moduleAll.length} itemsPerPage={itemsPerPage} maxPage={totalPages} handlePageChange={handlePageChange} currentIndex={currentPageIndex} />}
            {paginationList && dataRowList.length > 0 && pageView === 'list' && <Pagination light={true} totalItems={dataModule.length} itemsPerPage={itemsPerPageList} maxPage={totalPagesList} handlePageChange={handlePageChanger} currentIndex={currentPageIndexList}/>}
            {verificationPop && <VerificationCode handleVerificationClose={handleVerificationPopUpClose} label="Nous avons envoyé un code à 6 caractères à votre adresse email.Veuillez le saisir ci-dessous :" title="Suppression du module" description="Vous ne trouvez pas votre code ? Vérifiez votre dossier Spam" buttonLabel="Supprimer module" handleSubmit={handleVerificationDelete} />}
        </div>
    );
}