import { Dropdown, GenericCard, GenericTable, Input, Modal, ModuleListData, MoreActions, SearchInput, TextArea, ToggleButton, ModuleEditDataTable, Button } from "kls-ui";
import { SettingsContainerHeader } from "../../../settings/settings";
import './module-list.css';
import { useEffect, useState } from "react";
import { Pagination } from "kls-ui/paginationKls/pagination";

export function ModuleList() {
    const [selectFilter, setSelectFilter] = useState(false);
    const [statusValue, setStatusValue] = useState('');
    const [filterColor, setfilterColor] = useState(false);
    const [noData, setNoData] = useState(false);
    const [isSelectedModel, setisSelectedModel] = useState(false);
    const btnName = "Nouvelle liste";
    const [isToggle, setisToggle] = useState(false);
    const [moduleListData, setModuleListData] = useState<any>([]);
    const [dataName, setDataName] = useState<any[]>([]);
    let [db, setDb] = useState<any>([]);
    const [enableToggle, setenableToggle] = useState(false)
    const [currentPageIndex, setcurrentPageIndex] = useState(0);
    const [filter, setFilter] = useState('');
    const [pagination, setPagination] = useState(true);
    const [selecteEdit, setSelectEdit] = useState(false);
    const [editListData, seteditListData] = useState<any>([]);
    const [editID, seteditID] = useState<any>([]);
    const [currentPageIndexEdit, setcurrentPageIndexEdit] = useState(0);
    const [filterEdit, setFilterEdit] = useState('');
    const [paginationEdit, setPaginationEdit] = useState(true);
    const [edit, setEdit] = useState('');
    const [editInputName, setEditInputName] = useState('');
    const [editTogglebtn, seteditTogglebtn] = useState(false);

    let filteredModuleData = filter !== '' ? moduleListData.filter(o =>
        o && o.nom.toLowerCase().includes(filter.toLowerCase())
    ) : moduleListData;

    let filteredModuleDataEdit = filterEdit !== '' ? editListData.filter(o =>
        o && o.value.toLowerCase().includes(filterEdit.toLowerCase())
    ) : editListData;

    console.log('filterEdit', editListData)

    const itemsPerPage = 5;
    const totalPages = Math.ceil(moduleListData.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, moduleListData.length);
    let dataRow = filteredModuleData && filteredModuleData.slice(startIndex, endIndex);

    const itemsPerPageEdit = 5;
    const totalPagesEdit = Math.ceil(editListData.length / itemsPerPageEdit);
    const [currentPageEdit, setCurrentPageEdit] = useState<number>(1);
    const startIndexEdit = (currentPageEdit - 1) * itemsPerPageEdit;
    const endIndexEdit = Math.min(startIndexEdit + itemsPerPageEdit, editListData.length);
    let dataRowEdit = filteredModuleDataEdit && filteredModuleDataEdit.slice(startIndexEdit, endIndexEdit);

    console.log(totalPagesEdit, currentPageEdit, startIndexEdit, endIndexEdit, dataRowEdit, "dataRowEdit")

    const handlePageChange = (start: number, end: number) => {
        const newCurrentPage = Math.floor(start / itemsPerPage) + 1;
        setCurrentPage(newCurrentPage)
        setcurrentPageIndex(0)
        // console.log(currentPage, currentPageIndex, "current")
        console.log(newCurrentPage, "currentPage")
    };

    const handlePageChanger = (start: number, end: number) => {
        const newCurrentPage = Math.floor(start / itemsPerPageEdit) + 1;
        setCurrentPageEdit(newCurrentPage)
        setcurrentPageIndexEdit(0)
        console.log('test2024')
    };


    useEffect(() => {
        const data = [
            {
                id: 'List-234',
                nom: 'Civilité',
                derniereModification: 'Il y a 54 min',
                activation: 'true',
            },
            {
                id: 'List-283',
                nom: 'Jours fériés',
                derniereModification: 'Il y a 3 h',
                activation: 'true',
            },
            {
                id: 'List-134',
                nom: 'Type utilisateur',
                derniereModification: 'Il y a 1 année',
                activation: 'true',
            },
            {
                id: 'List-98',
                nom: 'Couleur thème',
                derniereModification: 'Il y a 2 a 34 j',
                activation: 'false',
            },
            {
                id: 'List-5',
                nom: 'Nationalité',
                derniereModification: 'Il y a 167 j',
                activation: 'false',
            },
            {
                id: 'List-2',
                nom: 'Civilité',
                derniereModification: 'Il y a 54 min',
                activation: 'true',
            },
            {
                id: 'List-202',
                nom: 'Civilité',
                derniereModification: 'Il y a 54 min',
                activation: 'false',
            },
        ];

        const editData = [
            { id: '2', value: 'Afghan', work: 'Afghan', activation: 'true' },
            { id: '3', value: 'Albanais', work: 'Albanais', activation: 'true' },
            { id: '4', value: 'Algérien', work: 'Algérien', activation: 'true' },
            { id: '5', value: 'Allemand', work: 'Allemand', activation: 'true' },
            { id: '6', value: 'Américain', work: 'Américain', activation: 'false' },
            { id: '7', value: 'Américains', work: 'Américains', activation: 'false' },
        ];

        setenableToggle(db.includes(edit) ? true : false)

        seteditListData(editData)
        setModuleListData(data)

        // for module list- toggle

        let dn: any = []
        data.map((c) => c.activation === 'true' && dn.push(c.id))
        setDb(dn)
        setenableToggle(dn)

        // for module edit list - toggle

        let storeId: any = []
        editData.map((c) => c.activation === 'true' && storeId.push(c.id))
        seteditID(storeId)

        setCurrentPage(1)
        setcurrentPageIndex(1)

        setCurrentPageEdit(1)
        setcurrentPageIndexEdit(1)
        if (data.length === 0) {
            setNoData(true);
        }
        console.log(dataName, "name");
    }, [filter, filterEdit])

    const handleSearchInputChange = (value: string) => {
        setFilter(value);
        setCurrentPage(1)
        setcurrentPageIndex(1)
        filteredModuleData = value !== '' ? moduleListData.filter(o =>
            o && o.nom.toLowerCase().includes(value.toLowerCase())
        ) : moduleListData
        if (filteredModuleData.length <= 5) {
            setPagination(false)
        } else {
            setPagination(true)
        }
    };


    const handleSearchInputChangeEdit = (value: string) => {
        setFilterEdit(value);
        setCurrentPageEdit(1)
        setcurrentPageIndexEdit(1)
        filteredModuleDataEdit = value !== '' ? editListData.filter(o =>
            o && o.value.toLowerCase().includes(value.toLowerCase())
        ) : editListData
        if (filteredModuleDataEdit.length <= 5) {
            setPaginationEdit(false)
        } else {
            setPaginationEdit(true)
        }
    };

    console.log(db, "datn")


    const Listdata = dataRow.map((d) => {
        return {

            id: d?.id,
            nom: d?.nom,
            derniereModification: d?.derniereModification,
            activations: (
                <div>
                    <ToggleButton ischecked={db.includes(d?.id) ? true : false} handleToggle={() => { }} disabled={true} />
                    {/* db.includes(d?.nom) ? setDb(db.filter((n)=> n !== d?.nom)) : setDb([...db, d?.nom]) */}
                </div>
            ),
            actions: <MoreActions actions={[
                {
                    label: 'Editer',
                    value: 'editer',
                    icon: 'edit',
                    color: '#64748B',
                    handleClick: () => {
                        console.log('Editer')
                        setSelectEdit(true)
                        setEdit(d?.id)
                        setenableToggle(db.includes(d?.id) ? true : false)
                    }
                },
                {
                    label: 'Supprimer',
                    value: 'supprimer',
                    icon: 'delete',
                    color: '#EF4444',
                    labelColor: '#EF4444',
                    handleClick: () => {
                        // setVerificationPop(true);
                    }
                }
            ]} />
        }
    })

    const editDataTable = dataRowEdit.map((c) => {
        return {
            value: c.value,
            work: c.work,
            activation: <div>
                <ToggleButton ischecked={editID.includes(c?.id) ? true : false} handleToggle={() => { editID.includes(c?.id) ? seteditID(editID.filter((n) => n !== c?.id)) : seteditID([...editID, c?.id]) }} />
                {/* db.includes(d?.nom) ? setDb(db.filter((n)=> n !== d?.nom)) : setDb([...db, d?.nom]) */}
            </div>,
            actions: <MoreActions actions={[
                {
                    label: 'Déplacer vers le haut',
                    value: 'move up',
                    icon: 'move_up',
                    color: '#64748B',
                    labelColor: '#1E293B',
                    handleClick: () => {
                        console.log('Editer')
                    }
                },
                {
                    label: 'Déplacer vers le bas',
                    value: 'move down',
                    icon: 'move_down',
                    color: '#64748B',
                    labelColor: '#1E293B',
                    handleClick: () => {
                        // setVerificationPop(true);
                        console.log("delete")
                    }
                },
                {
                    label: 'Supprimer',
                    value: 'supprimer',
                    icon: 'delete',
                    color: '#EF4444',
                    labelColor: '#EF4444',
                    handleClick: () => {
                        // setVerificationPop(true);
                        console.log("delete")
                    }
                }
            ]} />
        }
    })

    // console.log('dataRowEdit', dataRowEdit)

    function submitEditBtn() {
        if(editTogglebtn === true){
            db.includes(edit) ? setDb(db.filter((c) => c !== edit)) : setDb([...db, edit])
            seteditTogglebtn(false)
        }
    }

    return (
        <div>
            {selecteEdit ? <div className="app-moduleEdit-container">
                <div className="app-moduleEdit-headerContainer">
                    <p className="app-moduleEdit-headerTitle">Liste dynamique<span className="app-moduleEdit-headerSpan"> {edit}</span></p>
                </div>
                <div className="app-moduleEdit-editContainer">
                    <ToggleButton ischecked={ enableToggle } handleToggle={() => { seteditTogglebtn(true); setenableToggle(!enableToggle) }} label=" Activation" className="app-moduleEdit-togglebtn" />
                    <div className="app-moduleEdit-inputContainer">
                        <Input label="Nom" args={{ placeholder: 'Entrez le nom',value: editInputName, onChange: (e)=>{setEditInputName(e.target.value)} }} size="md" containerStyle={{ width: '100%' }} />
                        <TextArea label="Description" size='md' args={{ placeholder: 'Entrez la description' }} />
                    </div>
                </div>
                <div className="app-moduleEdit-editDataContainer">
                    <SearchInput light value={filterEdit} setValue={(e) => { handleSearchInputChangeEdit(e) }} size={3} placeholder="Rechercher" addClearbutton style={{ width: '100%', borderRadius: '8px' }} />
                    <div className="app-moduleEdit-iconContainer">
                        <p className="app-moduleEdit-icon download-icon"><span className="icon">download</span> Exporter</p>
                        <p className="app-moduleEdit-icon upload-icon"><span className="icon">upload</span> Importer</p>
                    </div>
                    <div className="app-moduleEdit-editData"><ModuleEditDataTable data={editDataTable} /></div>
                    {paginationEdit && dataRowEdit.length > 0 && <Pagination light={true} totalItems={filteredModuleDataEdit.length} itemsPerPage={itemsPerPageEdit} maxPage={totalPagesEdit} handlePageChange={handlePageChanger} currentIndex={currentPageIndexEdit} />}
                </div>
                <div className="app-moduleEdit-btnContainer">
                    <Button label="Annuler" size="md" color="primary" styling='outline' args={{onClick: ()=>{setSelectEdit(false); setEditInputName(''); setFilterEdit(''); }}} />
                    <Button label="Enregistrer" size="md" color="primary" styling="solid" args={{onClick: ()=>{setSelectEdit(false); setEditInputName(''); setFilterEdit(''); submitEditBtn()}}} />
                </div>
            </div> :
                <div className="app-moduleList-container">
                    <SettingsContainerHeader title="Listes dynamiques" subtitle="Gérez les listes dynamiques de votre module" enableAction buttonName={btnName} onClick={() => { if (btnName) { setisSelectedModel(true) } }} />
                    <div className="app-moduleList-topContent">
                        <SearchInput light value={filter} setValue={(e) => { handleSearchInputChange(e) }} size={3} placeholder="Rechercher" addClearbutton style={{ width: '90%', borderRadius: '8px' }} />
                        <div className={`app-moduleList-filtersection ${filterColor ? "selected" : ''}`} onClick={() => { setSelectFilter(!selectFilter); setfilterColor(!filterColor) }}>
                            <Input
                                args={{
                                    value: 'Filtres',
                                    id: "filter",
                                    readOnly: true,
                                    type: "text", placeholder: 'Filtres',
                                    style: { cursor: 'pointer' }
                                }}

                                infoText=""
                                label=""
                                size="md"
                            />
                            {/* <img className="app-moduleList-clearSearch" src="/photos/filter_list.svg" alt="filter_list" ></img> */}
                            <span className="icon all-moduleList-filterIcon">filter_list</span>
                        </div>
                    </div>
                    {selectFilter && <div className="app-moduleList-selectedFilter">
                        <Dropdown size="md" elementSize={32} elements={[{ label: 'Tous', value: 'Tous' }]} label="Statut" value={statusValue} onSelect={(e) => { setStatusValue(e.label) }} />
                    </div>}
                    {noData && <div className="app-moduleList-noDataContent">
                        Aucun résultat
                    </div>}
                    <div className="app-moduleList-dataContainer"><ModuleListData data={Listdata} /></div>
                    {isSelectedModel && btnName && <Modal title="Nouvelle liste" leftBtnArgs={{ label: 'Annuler', color: 'neutral', size: 'md', styling: 'outline', args: { className: 'app-moduleList-leftBtn', onClick: ()=>{setisSelectedModel(false)} } }} rightBtnArgs={{ label: 'Ajouter', color: 'primary', size: 'md', styling: 'solid', args: {} }} icon="" onClose={() => { setisSelectedModel(false) }}><div style={{ padding: '24px 16px' }} className="app-moduleList-moduleContainer">
                        <ToggleButton label="Activation" className="all-moduleList-toggleContainer" ischecked={isToggle} handleToggle={() => { setisToggle(!isToggle) }} />
                        <Input label="Nom" args={{ placeholder: 'Entrez le nom' }} size="md" />
                    </div></Modal>}
                    {pagination && dataRow.length > 0 && <Pagination light={true} totalItems={moduleListData.length} itemsPerPage={itemsPerPage} maxPage={totalPages} handlePageChange={handlePageChange} currentIndex={currentPageIndex} />}
                </div>}
        </div>
    )
}