import { useState, useEffect } from 'react';
import './settingsProfile.css';
import { GetInitials, AdvancedDropDown, SearchInput } from 'kls-ui';
import { currentUser, fetchLocalUserProfile } from "kls-commons/service/userService";
import { myorganisme } from "kls-auth/service/auth-service";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import style from '../../menu/style';

export const SettingsProfile = ({ image, username, label, type }: { type: string, image: string, username: string, label: string }) => {
    let [avatarType, setavatarType] = useState('image');
    let [displayName, setdisplayName] = useState(username);
    let [inital, setInital] = useState('');
    let [imageUser, setImage] = useState(image);
    let [toggle, settoggle] = useState(false);
    let [finalresult, setfinalresult] = useState<any[]>([]);
    let { organisationID } = useParams() as any;
    let [filter, setfilter] = useState('')
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [PagedData, setPagedData] = useState<any>(finalresult.slice(0, 5) ?? [])
    const [filteredData, setfilteredData] = useState<any>([])
    const itemsPerPage = 5;
    let totalItems, startIndex, endIndex, totalPages;
    let history = useHistory();
    const userProfile = fetchLocalUserProfile();
    const handleChange= (value) => {
        if (value?.image != '') {
            setavatarType('image');
            setImage(value?.image)
        } else {
            setavatarType('inital');
            setInital(GetInitials(value?.label));
        }
        if(userProfile?.fullName === value?.label){
            history.push('/settings');
        } else {
            setdisplayName(value?.label);
            settoggle(!toggle);
            history.push("/organisation-settings/" + value?.id + '/');
            handlePageChange(currentPage)
        }
        console.log(displayName, "displayname")
    }

    useEffect(() => {
        if (avatarType == 'inital') {
            setInital(GetInitials(username));
        }
        async function fetchMyOrganization() {
            
            await myorganisme('', '', currentUser().userId, '')
                .then(
                    (result) => {
                        console.log(result, 'resultresult')
                        if (result?.error) {
                        } else {
                            let tempResult: any[] = [];
                            let response = {
                                label: userProfile?.fullName, 
                                value: userProfile?.fullName, 
                                image: userProfile?.avatarUrl
                            }
                            tempResult.push(response)
                            result?.data.forEach((val) => {
                                    if (organisationID == val?.organisationId) {
                                        setdisplayName(val?.name);
                                        setInital(GetInitials(val?.name));
                                        setImage(val?.logoGedId != '' ? "/karazal/DownloadFile?gedId=" + val?.logoGedId : "");
                                        setavatarType(val?.logoGedId != '' ? "image" : "inital");
                                    }
                                let resp = {
                                    label: val?.name,
                                    value: val?.name,
                                    id: val?.organisationId,
                                    image: val?.logoGedId != '' ? "/karazal/DownloadFile?gedId=" + val?.logoGedId : "",

                                };
                                tempResult.push(resp);
                                
                            });
                            console.log(tempResult, "tempResult")
                            setfinalresult(tempResult);
                            setfilteredData(tempResult)
                            totalItems = tempResult.length;
                            startIndex = (currentPage - 1) * itemsPerPage;
                            endIndex = Math.min(startIndex + itemsPerPage, tempResult.length);
                            totalPages = Math.ceil(tempResult.length / itemsPerPage);
                            setPagedData(tempResult.slice(startIndex, endIndex))
                        }
                    },
                    (error => {
                        setdisplayName(username);
                        setInital(GetInitials(username));
                        console.log("LOGIN_ERROR", error);
                    })
                );
        }
        setdisplayName(username);
        setInital(GetInitials(username));
        fetchMyOrganization();
    }, []);

    const handleFilterValue = (value) => {
        setfilter(value)
        console.log(finalresult, "final")
        setfilteredData(value !== '' ? finalresult.filter((c) => c&& c.label.toString().toLowerCase().includes(value.toLowerCase())) : finalresult)
        totalItems = finalresult.filter((c) => c.label&& c.label.toString().toLowerCase().includes(value.toLowerCase())).length;
        startIndex = (currentPage - 1) * itemsPerPage;
        endIndex = Math.min(startIndex + itemsPerPage, finalresult.filter((c) => c.label&& c.label.toString().toLowerCase().includes(value.toLowerCase())).length);
        totalPages = Math.ceil(finalresult.filter((c) => c.label&& c.label.toString().toLowerCase().includes(value.toLowerCase())).length / itemsPerPage);
        setPagedData(finalresult.filter((c) => c.label&& c.label.toString().toLowerCase().includes(value.toLowerCase())).slice(startIndex, endIndex))
        value === '' && setPagedData(finalresult.slice(startIndex, endIndex))
    }
    const handlePageChange = (value) => {
        setCurrentPage(value)
        totalItems = filteredData.length;
        startIndex = (value - 1) * itemsPerPage;
        endIndex = Math.min(startIndex + itemsPerPage, filteredData.length);
        totalPages = Math.ceil(filteredData.length / itemsPerPage);
        setPagedData(filteredData.slice(startIndex, endIndex))
        console.log(value, startIndex, endIndex, totalItems, totalPages, filteredData, filteredData.slice(startIndex, endIndex), "pages settings profile")
    }
    const mode = window.localStorage.getItem('mode')
    return (
        <div style={{position: 'relative', color: '#1E293B'}}>
            <div className="setting-profile" onClick={() => {settoggle(!toggle); setfilter(''); setfilteredData(finalresult)}}>
                <div>
                    {avatarType == 'image' ? <img onError={(e) => setavatarType('inital')} src={imageUser} alt={imageUser} className="set-pro-img" /> : <div data-settings-inital={inital}></div>}
                </div>
                <p className={`setting-label ${mode === 'dark' ? 'text-dark' : ''}`}>{label}</p>
                <div className="user-drop">
                    <h4 className={`setting-user ${mode === 'dark' ? 'text-dark' : ''}`}>{type == 'organisation' ? displayName : username}</h4>
                    <span className={`more-icon-set icon ${mode === 'dark' ? 'text-dark' : ''}`}>expand_more</span>
                </div>
            </div>
            {toggle &&
                <div className={`organisation-header-dropdown ${mode === 'dark' ? 'bg-light-dark' : ''}`}>
                    <SearchInput light={true} size={3} value={filter} setValue={(value) => handleFilterValue(value)} addClearbutton={true} />
                    {PagedData&& PagedData.length > 0 ? PagedData.map((c) => {
                        return(
                            <div className={mode === 'dark' ? 'bg-light-dark text-dark dropdown-hover' : ''} style={{padding: '5px 12px', fontFamily: 'Inter', fontSize: '13px',cursor: 'pointer', backgroundColor: displayName === c?.label ? 'aliceblue' : 'white'}} onClick={() =>{ handleChange(c); console.log(c?.label, 'label of c')}}>{c?.label}</div>
                        )
                    }) : <div style={{textAlign: 'center', color: '#94A3B8', fontSize: '13px'}}>Aucun Resultat</div>}
                    {filteredData.length > 3 && <div style={{width: '95%', border: '1px solid #E2E8F0'}}></div>} 
                    {filteredData.length > 3 && 
                        <div style={{display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center', cursor: 'default', fontSize: '13px'}}>
                            <span className={`icon ${mode === 'dark' ? currentPage > 1 ? 'text-dark' : 'pagination-direction-disabled-dark' : ''}`} style={{color: currentPage > 1 ? '#64748B' : '#E2E8F0'}} onClick={() => handlePageChange(1)}>keyboard_double_arrow_left</span>
                            <span className={`icon ${mode === 'dark' ? currentPage > 1 ? 'text-dark' : 'pagination-direction-disabled-dark' : ''}`} style={{color: currentPage > 1 ? '#64748B' : '#E2E8F0'}} onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}>chevron_left</span>
                            <span className='current-page'>{currentPage}</span>
                            <span className={`icon ${mode === 'dark' ? currentPage != Math.ceil(filteredData.length / itemsPerPage) ? 'text-dark' : 'pagination-direction-disabled-dark' : ''}`} style={{color: currentPage != Math.ceil(filteredData.length / itemsPerPage) ? '#64748B' : '#E2E8F0'}} onClick={() => handlePageChange(currentPage < Math.ceil(filteredData.length / itemsPerPage) ? currentPage + 1 : Math.ceil(filteredData.length / itemsPerPage))}>chevron_right</span>
                            <span className={`icon ${mode === 'dark' ? currentPage != Math.ceil(filteredData.length / itemsPerPage) ? 'text-dark' : 'pagination-direction-disabled-dark' : ''}`} style={{color: currentPage != Math.ceil(filteredData.length / itemsPerPage) ? '#64748B' : '#E2E8F0'}} onClick={() => handlePageChange(Math.ceil(filteredData.length / itemsPerPage))}>keyboard_double_arrow_right</span>
                        </div>
                    }
                </div>
            }
            {/* {type === 'user' && toggle &&
                <div className='organisation-header-dropdown'>
                <SearchInput light={true} size={3} value={filter} setValue={(value) => handleFilterValue(value)} addClearbutton={true} />
                {PagedData&& PagedData.length > 0 ? PagedData.map((c) => {
                    return(
                        <div style={{padding: '5px 12px', fontFamily: 'Inter', fontSize: '13px',cursor: 'pointer', backgroundColor: displayName === c?.label ? 'aliceblue' : 'white'}} onClick={() =>{ handleChange(c); console.log(c?.label, 'label of c')}}>{c?.label}</div>
                    )
                }) : <div style={{textAlign: 'center', color: '#94A3B8', fontSize: '13px'}}>Aucun Resultat</div>}
                {filteredData.length > 3 && <div style={{width: '95%', border: '1px solid #E2E8F0'}}></div>} 
                {filteredData.length > 3 && 
                    <div style={{display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center', cursor: 'default', fontSize: '13px'}}>
                        <span className='icon' style={{color: currentPage > 1 ? '#64748B' : '#E2E8F0'}} onClick={() => handlePageChange(1)}>keyboard_double_arrow_left</span>
                        <span className='icon' style={{color: currentPage > 1 ? '#64748B' : '#E2E8F0'}} onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}>chevron_left</span>
                        <span className='current-page'>{currentPage}</span>
                        <span className='icon' style={{color: currentPage != Math.ceil(filteredData.length / itemsPerPage) ? '#64748B' : '#E2E8F0'}} onClick={() => handlePageChange(currentPage < Math.ceil(filteredData.length / itemsPerPage) ? currentPage + 1 : Math.ceil(filteredData.length / itemsPerPage))}>chevron_right</span>
                        <span className='icon' style={{color: currentPage != Math.ceil(filteredData.length / itemsPerPage) ? '#64748B' : '#E2E8F0'}} onClick={() => handlePageChange(Math.ceil(filteredData.length / itemsPerPage))}>keyboard_double_arrow_right</span>
                    </div>
                }
            </div>
            } */}
        </div>
    )
}