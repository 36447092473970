import "./verification-settings.css";
import { SettingsContainerHeader } from "../../settings/settings";
import { Button, Modal } from "kls-ui";
import { useEffect, useState } from "react";
import { Pagination } from "kls-ui/paginationKls/pagination";
import { fetchLocalUserProfile } from "kls-commons/service/userService";
import { allorganisme, myorganisme } from "kls-auth/service/auth-service";
import { useParams } from "react-router-dom";

export function VerificationSettings(props: any) {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadErr, setUploadErr] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const [currentDocumentContent, setCurrentDocumentContent] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [fileName, setFileName] = useState('');
  const [globalIndexes, setGlobalIndexes] = useState<number[]>([]);
  const [modifiedPage, setmodifiedPage] = useState(0)
  const [isRemoved, setisRemoved] = useState(false)
  const [dragover, setDragover] = useState(false);
  const [allorganisation, setAllorganisation] = useState([])
  const [roleValue, setRoleValue] = useState()
  let { organisationID } = useParams() as any

  // console.log('currentPage', currentPage)

  let TestData = (uploadedFiles.length / itemsPerPage)
  const lastPage = Math.ceil(TestData) + 1

  const onChange = (file: File) => {
    console.log(file, "file")
    const reader = new FileReader();
    console.log(reader, "reader")
    reader.onload = () => {
      const content = reader.result;
      console.log(content, "content")
      if (file.type === 'application/pdf') {
        const b64 = content as string;
        const byteString = atob(b64.split(",")[1]);

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ab], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url);
      } else {
        if (content) {
          if (file.type.startsWith('image/')) {
            setCurrentDocumentContent({ type: 'image', data: content });
          } else {
            setCurrentDocumentContent({ type: 'other', data: content });
          }
          setIsModalOpen(true);
        }
      }
    };
    reader.readAsDataURL(file);
    setFileName(file.name)
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePageChange = (start: number, end: number) => {
    // console.log('first',start)
    const newCurrentPage = Math.floor(start / itemsPerPage) + 1;
    let updatedCurrentPage = newCurrentPage
    if (isRemoved) {
      if (uploadedFiles.length - startIndex < 3 && uploadedFiles.length - startIndex > 0) {
        updatedCurrentPage = currentPage;
        return
      }
      else if (uploadedFiles.length - itemsPerPage === startIndex) {
        updatedCurrentPage = currentPage - 1;
        return
      }
      setisRemoved(false)
    }
    setCurrentPage(updatedCurrentPage);
    setmodifiedPage(updatedCurrentPage);
  };

  function handleFileUploadClick() {
    const fileInput = document.querySelector('.file-upload-verification-settings') as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  }

  function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (files) {
      const validFiles = Array.from(files).filter(file => {
        const allowedFormats = ['application/pdf', 'image/png', 'image/jpeg'];
        return allowedFormats.includes(file.type);
      });
      if (validFiles.length === files.length) {
        setUploadedFiles(prevFiles => [...prevFiles, ...validFiles]);
        setUploadErr('')
      } else {
        setUploadErr('Invalid file formats. Please upload files in PDF, PNG, or JPEG format.');
      }
    } else {
      setUploadErr('No files selected.');
    }
  }

  function handleDragLeave(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    setDragover(false);
  }

  function handleDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    setDragover(false);
    const files = event.dataTransfer.files;
    if (files) {
      const validFiles = Array.from(files).filter(file => {
        const allowedFormats = ['application/pdf', 'image/png', 'image/jpeg'];
        return allowedFormats.includes(file.type);
      });
      setUploadedFiles(prevFiles => [...prevFiles, ...validFiles]);
    }
  }

  function handleDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    setDragover(true);
  }

  function formatFileSize(bytes: number) {
    if (bytes === 0) return '0 KB';
    const k = 1024;
    const sizeInKB = bytes / k;
    return parseFloat(sizeInKB.toFixed(2)) + ' KB';
  }

  const removeData = (index: number) => {
    const newUploadedFiles = [...uploadedFiles];
    newUploadedFiles.splice(index, 1);
    setUploadedFiles(newUploadedFiles);
    if (currentPage > 1 && (((currentPage - 1) * itemsPerPage) === startIndex) && newUploadedFiles.length === startIndex) {
      console.log("updated")
      setCurrentPage(currentPage - 1);
      setmodifiedPage(currentPage - 1);
      setisRemoved(true)
    }
  };


  const totalPages = Math.ceil(uploadedFiles.length / itemsPerPage);

  let startIndex = (currentPage - 1) * itemsPerPage;
  let endIndex = Math.min(startIndex + itemsPerPage, uploadedFiles.length);

  const currentFiles = uploadedFiles.slice(startIndex, endIndex);

  useEffect(() => {
    const indexes = currentFiles.map((_, index) => index);
    setGlobalIndexes(indexes);
  }, [uploadedFiles, currentPage]);
  const mode = window.localStorage.getItem('mode')

  useEffect(() => {
    const fetchData = async () => {
      try {
        let loggedinUser = fetchLocalUserProfile();
        const fetchOrganisation = await myorganisme('', '', loggedinUser?.userId, '')
        // console.log('fetchOrganisation,',fetchOrganisation)
        setAllorganisation(fetchOrganisation?.data)
      } catch (error) {
        console.error("Error fetching organization settings:", error);
      }
    }
    fetchData()

  }, [props])

  useEffect(() => {
    if (allorganisation.length != 0) {
      let targetOrganisationId = organisationID;
      const result: any = allorganisation.find((item: any) => item?.organisationId === targetOrganisationId);

      setRoleValue(result?.role)

      console.log(result);
    }
  }, [allorganisation])

  console.log('roleValue', roleValue);
  console.log('organisationID', organisationID);

  return (
    <div className="verification-settings-container">
      <SettingsContainerHeader
        title="Vérification"
        subtitle="Veuillez fournir des justificatifs permettant de vérifier l'authenticité de votre organisation et la fonction que vous y occupez."
      />
      {uploadErr && (
        <div className="error-message">
          <span className="icon error-logo-org">error_outline</span>
          <div className="orgination-error-message">{uploadErr}</div>
        </div>
      )}
      <div className="verification-settings-content-container">
        <div className="verification-setting-content-status">
          <h1 className={mode === 'dark' ? 'text-dark' : ''}>Statut vérification :</h1>
          <div className="verification-setting-content-status-subDiv">
            <p className={mode === 'dark' ? 'text-dark' : ''}>Non vérifiée</p>
            <div className="verification-setting-content-status-color"></div>
          </div>
        </div>
        <div className="verification-settings-upload-container">
          <div className="verification-settings-upload-header">
            <h1>Dépôt des pièces justificatives</h1>
          </div>
          <div className={`verification-settings-upload-box ${dragover ? 'dragover' : ''}`} onDragLeave={handleDragLeave} onClick={handleFileUploadClick} onDrop={handleDrop} onDragOver={handleDragOver}>
            <div className="verification-settings-upload-icon">
              <span className="icon upload-icon-verify-settings">file_upload</span>
              <input type="file" className="file-upload-verification-settings" onChange={handleFileUpload} />
            </div>
            <div className="verification-settings-upload-description">
              <h1 className={mode === 'dark' ? 'text-dark' : ''}>Faites glisser ou déposez vos pièces justificatives ici</h1>
              <h2 className={mode === 'dark' ? 'text-dark' : ''}>Formats pris en charge : PDF, PNG, JPEG</h2>
            </div>
          </div>
        </div>
        <div className="verification-setting-uploaded-data">
          {currentFiles.length > 0 && (
            <div>
              {currentFiles.map((file, index) => (
                <ul key={globalIndexes[index]} className="verification-setting-uploaded-data-ul">
                  <li className="verification-setting-uploaded-data-li">
                    <div className="verification-setting-uploaded-data-li-detials">
                      <span className="icon icon-doc-verify">text_snippet</span>
                      <span className="verification-setting-uploaded-data-name">{file.name}</span>
                      <span className="verification-setting-uploaded-data-size">{formatFileSize(file.size)}</span>
                    </div>
                    <div className="verification-setting-uploaded-data-li-options">
                      <span className="icon icon-eye-verify" onClick={() => onChange(file)}>visibility</span>
                      <span className="icon icon-delete-verify" onClick={() => removeData(index)}>delete_outline</span>
                    </div>
                  </li>
                </ul>
              ))}
              {uploadedFiles.length > 3 && <Pagination
                light={true}
                totalItems={uploadedFiles.length}
                itemsPerPage={itemsPerPage}
                maxPage={totalPages}
                handlePageChange={handlePageChange}
                currentIndex={modifiedPage}
              />}
              {isModalOpen && <Modal onClose={closeModal} title={fileName} icon={""}  >
                <div className="verification-setting-model-popup">
                  {currentDocumentContent && currentDocumentContent.type === 'image' && (
                    <img src={currentDocumentContent?.data} alt="Uploaded Image" height='100%' width='100%' />
                  )}
                </div>
              </Modal>
              }
            </div>
          )}

        </div>
        {(roleValue == "Administrateur" || roleValue == 'user' || roleValue == 'Utilisateur') && (
          <div className="verification-settings-upload-submit">
            <Button size={"md"} label="Procéder à la vérification" color={"primary"} styling={"solid"} args={{}} />
          </div>
        )}

      </div>
    </div>
  );
}
