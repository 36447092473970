import { PaymentsTable } from "kls-ui"
import { SettingsContainerHeader } from "../../settings/settings"
import './payments.css'
import { relative } from "path"
import { useEffect, useState } from "react"
export function PaymentSettings(props: any) {
  const [paymentsData, setpaymentsData] = useState<any>([])
  useEffect(() => {
    const data = [
      {
        Facture: "FCTR45893",
        Date: "10/09/2023",
        Utilisateurs: "10",
        Montant: "2,0000",
        Statut: "Non payé",
        action: "Facture"
      }, {
        Facture: "FCTR45893",
        Date: "10/09/2023",
        Utilisateurs: "10",
        Montant: "2,0000",
        Statut: "Payé",
        action: "Facture"
      }, {
        Facture: "FCTR45893",
        Date: "10/09/2023",
        Utilisateurs: "10",
        Montant: "2,0000",
        Statut: "Payé",
        action: "Facture"
      }, {
        Facture: "FCTR45893",
        Date: "10/09/2023",
        Utilisateurs: "10",
        Montant: "2,0000",
        Statut: "Payé",
        action: "Facture"
      }, {
        Facture: "FCTR45893",
        Date: "10/09/2023",
        Utilisateurs: "4",
        Montant: "800",
        Statut: "Payé",
        action: "Facture"
      }
    ]
    setpaymentsData(data)
  },[])
  const mode = window.localStorage.getItem('mode')
  const paymentsList = paymentsData.map((c) => ({
    Facture: <span className={mode === 'dark' ? 'text-dark' : ''}>{c?.Facture}</span>, 
    Date: <span className={mode === 'dark' ? 'text-dark' : ''}>{c?.Date}</span>,
    Utilisateurs: <span className={mode === 'dark' ? 'text-dark' : ''}>{c?.Utilisateurs}</span>,
    Montant: <span className={mode === 'dark' ? 'text-dark' : ''}>{c?.Montant}</span>,
    Statut: c?.Statut,
    action: <span className={mode === 'dark' ? c.action !== "Facture" ? 'text-dark' : '' : ''}>{c?.action}</span>
  }))
  return (
    <div className="app-payment-container">
      <SettingsContainerHeader title="Paiements" subtitle="Gérez vos paiements et factures" />
      <div className="app-payment-dataContainer">
        <PaymentsTable data={paymentsList} />
      </div>
    </div>
  )
}

