import { useState, useEffect } from "react";
import { Tabs, Input, Button, Modal, Dropdown, Hint, addToast } from "kls-ui";
import { SettingsContainerHeader } from "../../settings/settings";
import { countries } from "../../common/countries";
import { typeOfLocal } from "../../common/local";
import { Data } from "victory";
import { useParams } from "react-router-dom";
import { fetchLocalUserDetails } from "kls-commons/service/userService";
import { OrganizationAddressUpdate } from "../organization-settings-service";

function EditAddress(props: any) {
    let [industries, setindustries] = useState<any[]>([]);
    let [location, setLocation] = useState<any[]>(typeOfLocal);
    let [addresslineErr, setaddresslineErr] = useState('');
    let [addressLocaliteErr, setaddressLocaliteErr] = useState('');
    let [addressPaysErr, setaddressPaysErr] = useState('');
    const [activeTab, setActiveTab] = useState('Général');
    let [localite, setlocalite] = useState<any>([])
    const [addressData, setaddressData] = useState(props.addressData)
    let { organisationID } = useParams() as any
    let [addressline, setaddressline] = useState(addressData?.address);
    const [typeLocal, setTypeLocal] = useState(addressData?.addressType)
    let [addressLocalite, setaddressLocalite] = useState(addressData?.localite);
    const [dateCreation, setDateCreation] = useState(addressData?.creationDate)
    const [dateModify, setDateModify] = useState<any>(addressData?.updateDate || dateCreation)
    let [addressId, setAddressId] = useState(addressData?.adresseId)


    const handleTabClick = (label) => {
        setActiveTab(label);
    };

    const submitAddress = async () => {
        let isValid = true;

        if (addressline === '') {
            setaddresslineErr("Adresse champ est requis");
            isValid = false;
        } else {
            setaddresslineErr("");
        }

        if (addressLocalite === '') {
            setaddressLocaliteErr("Localite champ est requis");
            isValid = false;
        } else {
            setaddressLocaliteErr("");
        }

        if (addressPays === '') {
            setaddressPaysErr("Pays champ est requis");
            isValid = false;
        } else {
            setaddressPaysErr("");
        }
        if (isValid) {
            let result = fetchLocalUserDetails();

            await OrganizationAddressUpdate(result?.userID, organisationID, addressline, addressPays, addressLocalite, typeLocal,addressId)
                .then(
                    (result: any) => {
                        if (result?.error) {
                            console.log(result);
                            addToast(result?.error, 'error');
                        } else if (result?.result == 'Done') {
                            addToast('Organisation modifiée avec succès', 'success');
                            localStorage.setItem('organizationData', JSON.stringify(result));
                            console.log('OrganizationAddressUpdate')

                            setTimeout(() => {
                                props?.popupClose()
                            }, 1500)
                        }
                    },
                    (error => {
                        console.log("API_ERROR", error);
                    })
                );    

        }

        return isValid;

    }
    console.log(addressData, "add")
    const countryArray = Object.entries(countries).map(([country]) => ({
        'label': country,
        'value': country,
    }));

    let [addressPays, setaddressPays] = useState(addressData?.pays);

    useEffect(() => {
        const local = addressPays && countries[addressPays] && countries[addressPays]?.localite.map((c) => { return { "label": c, "value": c } })
        setlocalite(local)
    }, [addressPays])

    const mode = window.localStorage.getItem('mode')
    return (<div className="organisation-table-Modal-Editform">
        <Modal onClose={props?.popupClose} title={"Détails de l’adresse"} icon={""} >
            <div className="organisation-table-Modal-primary">
                <div className="organisation-table-Modal">
                    <div className="organisation-table-Modal-navbar">
                        <Tabs
                            align="horizontal"
                            current={activeTab}
                            defSelected={activeTab}

                            onSelect={handleTabClick}
                            size="small"
                            tabs={[
                                {
                                    label: 'Général',
                                    name: 'Général',
                                    type: 'tab'
                                },
                                {
                                    label: 'À propos',
                                    name: 'À propos',
                                    type: 'tab'
                                }]}
                        />
                    </div>
                    <div className="tab-content">
                        {activeTab === 'Général' && <div className="Organisation-editor-TabContent1">
                            <div className="Organisation-editor-TabContent1-header">
                                <Dropdown
                                    value={typeLocal}
                                    size="md"
                                    elementSize={36}
                                    elements={location}
                                    setValue={(e) => setTypeLocal(e)}
                                    label="Type du local"
                                    listTopPos="105%"
                                    listBottomPos=""
                                    listLeftPos=""
                                    listRightPos=""
                                    errorMessage={''}
                                    args={{ value: typeLocal, defaultValue: typeLocal }} />
                            </div>
                            <div className="Organisation-editor-Tabcontent1-address">
                                <Input size='md' label="Adresse" args={{
                                    placeholder: 'Entrez l’adresse de votre local', required: true,
                                    value: addressline,
                                    onChange: (e) => {
                                        setaddressline(e.target.value);
                                        setaddresslineErr('');
                                        if (e.target.value == '') {
                                            setaddresslineErr("Ce champ est requis")
                                        }
                                    }
                                }} errorMessage={addresslineErr} />
                            </div>
                            <div className="Organisation-editor-Tabcontent1-dropdown">
                                <div className="Organisation-editor-Tabcontent1-Paysdropdown">
                                    <Dropdown value={''}
                                        size="md"
                                        elementSize={36}
                                        elements={countryArray}
                                        setValue={(e) => { setaddressPays(e); setaddressPaysErr(''); setaddressLocalite('') }}
                                        label="Pays"
                                        listTopPos="105%"
                                        listBottomPos=""
                                        listLeftPos=""
                                        listRightPos=""
                                        errorMessage={addressPaysErr}
                                        args={{
                                            placeholder: 'Choisissez un pays', required: true, value: addressPays, defaultValue: addressPays
                                        }}
                                    />
                                </div>
                                <div className={addressPays !== "" ? "Organisation-editor-Tabcontent1-Localitydropdown" : "Organisation-editor-Tabcontent1-Localitydropdown-empty"}>
                                    <Dropdown value={addressLocalite}
                                        size="md"
                                        elementSize={36}
                                        elements={localite ? localite : []}
                                        setValue={(e) => { setaddressLocalite(e); setaddressLocaliteErr('') }}
                                        label="Localité"
                                        listTopPos="105%"
                                        listBottomPos=""
                                        listLeftPos=""
                                        listRightPos=""
                                        errorMessage={addressLocaliteErr}
                                        args={{
                                            placeholder: 'Choisissez une localité', required: true, value: addressLocalite, defaultValue: addressLocalite, disabled: countries[addressPays] ? false : true
                                        }}
                                    />
                                </div>
                            </div>
                        </div>}
                        {activeTab === 'À propos' && <div className="Organisation-editor-TabContent2">
                            <div className="Organisation-editor-TabContent2-CreationDate">
                                <h1 className={mode === 'dark' ? 'text-dark' : ''}>Date de création</h1>
                                <p>{dateCreation}</p>
                            </div>
                            <div className="Organisation-editor-TabContent2-ModificationDate">
                                <h1 className={mode === 'dark' ? 'text-dark' : ''}>Dernière modification</h1>
                                <p>{dateModify}</p>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <div className={`organisation-table-Modal-Button ${mode === 'dark' ? 'bg-light-dark' : ''}`}>
                    <div className="organisation-table-Modal-Button-cancel">
                        <Button size="md" label="Annuler" styling="solid" color="primary" args={{ onClick: props?.popupClose, className: 'organistaion-cancel-button-address-model' }} />
                    </div>
                    <div className="organisation-table-Modal-Button-Success">
                        <Button size="md" label="Enregistrer" styling="solid" color="primary" args={{ onClick: submitAddress }} />
                    </div>
                </div>
            </div>
        </Modal>
    </div>)
}
const EditAddressMobile = (props: any) => {
    let [industries, setindustries] = useState<any[]>([]);
    let [addresslineErr, setaddresslineErr] = useState('');

    let [addressLocaliteErr, setaddressLocaliteErr] = useState('');

    let [addressPaysErr, setaddressPaysErr] = useState('');
    let [close, setClose] = useState('')
    let [infoDetails, setInfoDetails] = useState(false);
    const [addressData, setaddressData] = useState(props.addressData)
    const [typeLocal, setTypeLocal] = useState(addressData?.addressType)
    let [addressPays, setaddressPays] = useState(addressData.pays);
    let [addressLocalite, setaddressLocalite] = useState(addressData.localite);
    const [dateCreation, setDateCreation] = useState(addressData?.creationDate)
    const [dateModify, setDateModify] = useState(addressData?.modifiedAt)
    let [location, setLocation] = useState<any[]>(typeOfLocal);
    let [localite, setlocalite] = useState<any>([])
    let [addressId, setAddressId] = useState(addressData?.adresseId)
    let { organisationID } = useParams() as any

    const submitAddress = async() => {
        let isValid = true;
        if (addressline === '') {
            setaddresslineErr("Adresse champ est requis");
            isValid = false;
        } else {
            setaddresslineErr("");
        }

        if (addressLocalite === '') {
            setaddressLocaliteErr("Localite champ est requis");
            isValid = false;
        } else {
            setaddressLocaliteErr("");
        }

        if (addressPays === '') {
            setaddressPaysErr("Pays champ est requis");
            isValid = false;
        } else {
            setaddressPaysErr("");
        }
        if (isValid) {
            let result = fetchLocalUserDetails();

            await OrganizationAddressUpdate(result?.userID, organisationID, addressline, addressPays, addressLocalite, typeLocal,addressId)
                .then(
                    (result: any) => {
                        if (result?.error) {
                            console.log(result);
                            addToast(result?.error, 'error');
                        } else if (result?.result == 'Done') {
                            addToast('Organisation modifiée avec succès', 'success');
                            localStorage.setItem('organizationData', JSON.stringify(result));
                            console.log('OrganizationAddressUpdate')

                            setTimeout(() => {
                                props?.popupClose()
                            }, 1500)
                        }
                    },
                    (error => {
                        console.log("API_ERROR", error);
                    })
                );
        }
        return isValid;
    }

    const handleBackArrow = () => {
        setClose(props.popupClose)
    }

    useEffect(() => {
        if (infoDetails) {
            document.addEventListener("click", (e) => {
                setInfoDetails(false);
            }, { once: true });
        }
    }, [infoDetails]);

    useEffect(() => {
        const local = addressPays && countries[addressPays] && countries[addressPays]?.localite.map((c) => { return { "label": c, "value": c } })
        setlocalite(local)
    }, [addressPays])

    const countryArray = Object.entries(countries).map(([country]) => ({
        'label': country,
        'value': country,
    }));

    let [addressline, setaddressline] = useState(addressData?.address);
    console.log("Mob", addressData.address)

    return (
        <>
            <div className='mobile-org-table-Modal-container' >
                <div className='mobile-edit-table-Modal-view'>
                    <span className="icon edit-arrow-back-settings" onClick={handleBackArrow}>arrow_back</span>
                    <div className='mobile-edit-containerHeader'>
                        <SettingsContainerHeader title='Organisation' subtitle='Gérez votre organisation' />
                    </div>
                    <div className="mobile-edit-table-Modal-primary" >
                        <div className="mobile-edit-table-Modal">
                            <div className="mobile-edit-table-popup-tab-content">
                                <div className="mobile-edit-editor-TabContent1">
                                    <div className='mobile-edit-detailsContainer'>
                                        <p style={{ margin: '0', fontSize: '15px', fontFamily: 'Inter', fontWeight: '600' }}>Détails de l’adresse</p>
                                        <span className='icon mobile-create-info-icon' onClick={() => { setInfoDetails(!infoDetails) }}>info</span>
                                        {infoDetails &&
                                            <div className='mobile-create-hint-info'>
                                                <Hint title="" content="" button="" currentCount={0} totalCount={0} position='top' close={true}>
                                                    <div className='mobile-create-hint-infoContainer'>
                                                        <div className='mobile-create-hint hint-creation-date'>
                                                            <p style={{ fontWeight: '500' }}>Date de création</p>
                                                            <p style={{ fontWeight: '400', color: "#64748B" }}>{dateCreation}</p>
                                                        </div>
                                                        <div className='mobile-create-hint hint-modification-date'>
                                                            <p style={{ fontWeight: '500' }}>Dernière modification</p>
                                                            <p style={{ fontWeight: '400', color: "#64748B" }}>{dateModify}</p>
                                                        </div>
                                                    </div>
                                                </Hint>
                                            </div>
                                        }
                                    </div>
                                    <div className="mobile-edit-editor-TabContent1-header">
                                        <Dropdown
                                            value={typeLocal}
                                            size="md"
                                            elementSize={36}
                                            elements={location}
                                            setValue={(e) => { setTypeLocal(e) }}
                                            label="Type du local"
                                            listTopPos="105%"
                                            listBottomPos=""
                                            listLeftPos=""
                                            listRightPos=""
                                            errorMessage={''}
                                            args={{ value: typeLocal, defaultValue: typeLocal }} />
                                    </div>
                                    <div className="mobile-edit-editor-Tabcontent1-address">
                                        <Input size='md' label="Adresse" args={{
                                            placeholder: 'Entrez l’adresse de votre local', required: true, value: addressline,
                                            onChange: (e) => {
                                                setaddressline(e.target.value);
                                                setaddresslineErr('');
                                                if (e.target.value == '') {
                                                    setaddresslineErr("Ce champ est requis")
                                                }
                                            }
                                        }} errorMessage={addresslineErr} />
                                    </div>
                                    <div className="Organisation-editor-Tabcontent1-dropdown Mobile-editor-loclite-payEdit">
                                        <div className="Organisation-editor-Tabcontent1-Paysdropdown Mobile-org-editor-paysEdit">
                                            <Dropdown
                                                size="md"
                                                elementSize={36}
                                                elements={countryArray}
                                                setValue={(e) => { setaddressPays(e); setaddressPaysErr('') }}
                                                label="Pays"
                                                listTopPos="105%"
                                                listBottomPos=""
                                                listLeftPos=""
                                                listRightPos=""
                                                errorMessage={addressPaysErr}
                                                args={{
                                                    placeholder: 'Choisissez un pays', required: true, value: addressPays, defaultValue: addressPays
                                                }}
                                            />
                                        </div>
                                        <div className={addressPays !== "" ? "Organisation-editor-Tabcontent1-Localitydropdown Mobile-Org-editor-TabContentEdit" : "Organisation-editor-Tabcontent1-Localitydropdown-empty"}>
                                            <Dropdown value={addressLocalite}
                                                size="md"
                                                elementSize={36}
                                                elements={localite ? localite : []}
                                                setValue={(e) => { setaddressLocalite(e); setaddressLocaliteErr('') }}
                                                label="Localité"
                                                listTopPos="105%"
                                                listBottomPos=""
                                                listLeftPos=""
                                                listRightPos=""
                                                errorMessage={addressLocaliteErr}
                                                args={{
                                                    placeholder: 'Choisissez une industrie', required: true, value: addressLocalite, defaultValue: addressLocalite, disabled: countries[addressPays] ? false : true
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="mobile-org-submit-button">
                            <div className="mobile-org-all-submit-buttons">
                                <Button size="md" label="Annuler" styling="outline" color="neutral"  args={{ onClick: (e) => handleBackArrow(), className: 'mobile-org-cancel' }} />
                                <Button size="md" label="Enregistrer" styling="solid" color="primary" args={{ onClick: (e) => { submitAddress() } }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )
}

export { EditAddress, EditAddressMobile }