import { useEffect, useState } from "react";
import { OraganizationAddressTable, VerificationCode, Input, addToast, MobileViewVerification, MoreActions } from "kls-ui";
import { EditAddress, EditAddressMobile } from "./edit";
import { CreateAddress, CreateAddressMobile } from "./create";
import { SettingsContainerHeader } from "../../settings/settings";
import { fetchLocalUserDetails, fetchLocalUserProfile } from "kls-commons/service/userService";
import { OrganizationAddressDelete } from "../organization-settings-service";
import { useParams } from "react-router-dom";
export function AddressList(props: any) {
    let [verificationcodeErr, setverificationcodeErr] = useState("");
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [rowData, setRowData] = useState<any>();
    const [verification, setVerification] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [toaster, setToaster] = useState(false)
    let [viewDelete, setViewDelete] = useState<any>()
    const [dataValue, setdataValue] = useState<any>()
    const [currentId, setCurrentId] = useState()
    let { organisationID } = useParams() as any
    let [dataCheck,setDataCheck]=useState<any>('')

    const handleTableEditClick = (row) => {
        setRowData(row);
        window.scrollTo({ top: 0 })
        document.body.classList.add("mobile-edit-tab-view")
        setIsEditFormOpen(true);
    };

    const handleTableDeleteClick = async (row) => {
        console.log(row, 'deleterow')
        setCurrentId(row?.adresseId)
        setRowData(row);
        console.log('rowData', rowData)
        setVerification(true);
        window.scrollTo({ top: 0 })
        document.body.classList.add("mobile-verify-tab-view")
        setToaster(false)
    }

    useEffect(() => {
        if (dataCheck != '') {
            let result = fetchLocalUserDetails();

            OrganizationAddressDelete(result?.userID, organisationID, currentId)
                .then(
                    (result: any) => {
                        if (result?.error) {
                            console.log(result);
                            addToast(result?.error, 'error');
                        } else if (result?.result == 'Done') {
                            addToast('Organisation supprimée avec succès', 'success');
                            localStorage.setItem('organizationData', JSON.stringify(result));
                            console.log('OrganizationAddressDelete')

                            setTimeout(() => {
                                setDataCheck('')
                            }, 1000)
                        }
                    },
                    (error => {
                        console.log("API_ERROR", error);
                    })
                );
        }
    }, [dataCheck])

    console.log('rowData', rowData)

    const verifiactionToaster = () => {
        addToast(" L’adresse a été supprimé avec succès", 'success', "check")
        setToaster(false)
    }
    
    const handleVerificationClose = () => {
        setVerification(false)
    }

    const handleSubmit = (activationCode) => {
        if (activationCode === '986357') {
            setVerification(false);
            setDataCheck('Done')
            setVerification(false)
          } else {
            setverificationcodeErr('Invalid code. Please try again.');
          }
        // setToaster(true)
    }
    console.log('dataCheck....',dataCheck)

    let savedAddress = props?.address || [];

    if (Array.isArray(savedAddress)) {
        let filtered = savedAddress.filter(function (el) {
            return el != null && el != '';
        });
        savedAddress = filtered;
    } else if (typeof savedAddress === 'object' && !Array.isArray(savedAddress) && savedAddress !== null) {
        savedAddress = [];
        savedAddress.push(props?.address)
    }

    let data: any = savedAddress;

    useEffect(() => {
        if (dataValue != undefined) {
            data.push(dataValue)
        }
    }, [dataValue])

    if (data?.length > 0) {
        console.log(data[0], 'firstrecord');
        console.log(data[0]?.address, 'firstrecord');
    }

    const filteredData = data?.length > 0 ? data?.filter(item =>
        item?.address != null && item?.address != undefined && item?.address?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    ) : [];

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchQuery('');
    };
    console.log('dataValue', dataValue)
    console.log(filteredData, "filteredaddressdata")
    console.log('data', data)
    const datas = filteredData?.length > 0 ? filteredData : [{ id: '', address: 'Aucun résultat' }];
    const handleEditFormClose = () => {
        setIsEditFormOpen(false);
        setIsAddFormOpen(false);
    };

    const mobileAdd = () => {
        setIsAddFormOpen(!isAddFormOpen)
        window.scrollTo({ top: 0 })
        document.body.classList.add("mobile-org-tab-view")
    }

    useEffect(() => {
        if (!isAddFormOpen) {
            document.body.classList.remove("mobile-org-tab-view")
        }
        if (!verification) {
            document.body.classList.remove("mobile-verify-tab-view")
        }
        if (!isEditFormOpen) {
            document.body.classList.remove("mobile-edit-tab-view")
        }
    }, [isAddFormOpen, verification, isEditFormOpen])

    useEffect(() => {
        const updatedVerification = window.innerWidth >= 600 ? <VerificationCode handleVerificationClose={handleVerificationClose} verificationcodeErr={verificationcodeErr} handleSubmit={handleSubmit} label='Veuillez saisir le code 986357 dans les cases ci-dessous :' description='' title='Suppression d’adresse' buttonLabel='Supprimer adresse' propsType ='address' /> : <MobileViewVerification title="Organisation" subtitle="supprimer l'adresse de l'organisation" label="Supprimer l'adresse" labelContent="Veuillez saisir le code 986357 dans les cases ci-dessous :" handleSubmit={handleSubmit} handleVerificationClose={handleVerificationClose} labelbtn="Supprimer adresse" propsType ='address' findTitle="" findSubtitle="" />
        setViewDelete(updatedVerification)
    }, [])

    useEffect(() => {
        if (isEditFormOpen == false || isAddFormOpen == false || verification == false ) {
            props.setAPInewData && props?.setAPInewData('Done')
        }
        setTimeout(() => {
            props.setAPInewData && props?.setAPInewData('')
        }, 3000);
    }, [isEditFormOpen, isAddFormOpen,verification])

    return (
        isAddFormOpen && window.innerWidth <= 600 ? <>
            <CreateAddressMobile address={props.address} popupClose={props?.popupClose} setdataValue={setdataValue} />
        </> :
            <div className="organisation-address">
                <SettingsContainerHeader title="Adresses" subtitle="Les adresses des locaux de votre organisation" enableAction={true} buttonName="Nouvelle adresse" icon="add" onClick={() => { mobileAdd() }} />
                {
                    isAddFormOpen && <CreateAddress organisationData={props?.organisationData} address={props.address} popupClose={handleEditFormClose} />
                }
                {/* <div className="organisation-search">
                    <div className="organisation-search-bar" style={{ width: "100%" }}>
                        <Input
                            args={{
                                value: searchQuery,
                                id: "search",
                                type: "text",
                                placeholder: 'Rechercher',
                                onChange: handleSearchChange,
                            }}
                            infoText=""
                            label=""
                            size="md"
                        />
                    </div>
                    <div className="iconsSection-org">
                        <img className="clearSearch" src="/photos/close.svg" alt="clear" onClick={handleClearSearch}></img>
                        <img className="searchIcon" src="/icons/search.svg" alt="search" onClick={() => { console.log(handleClearSearch) }} ></img>
                    </div>
                </div> */}
                <div className="organisation-table">
                    <OraganizationAddressTable data={datas?.map((item: any) => ({
                        ...item,
                        action: <MoreActions actions={[
                            {
                                label: 'Editer',
                                value: 'edit',
                                handleClick: () => handleTableEditClick(item),
                                icon: 'edit',
                                color: 'black'
                            },
                            {
                                label: 'Supprimer',
                                value: 'Supprimer',
                                handleClick: () => handleTableDeleteClick(item),
                                icon: 'delete',
                                labelColor: '#DC2626',
                                color: '#EF4444'
                            }
                        ]} />
                    }))} />

                    {isEditFormOpen && <div>
                        <div className="address-edit-lap"><EditAddress popupClose={handleEditFormClose} addressData={rowData} /></div>
                        <div className="address-edit-mobile"><EditAddressMobile popupClose={props?.popupClose} addressData={rowData} /></div>
                    </div>}
                    {verification && viewDelete}
                    {toaster && verifiactionToaster()}
                </div>
            </div>
    );
}
