import { SetStateAction } from "react";
import { SettingsContainerHeader } from "../../settings/settings";
import './data.css'

export function ModuleData({handleSelect}) {
    const childTabs = [{icon: 'data_object', content: 'Schéma de données', name: 'schema'}, {icon: 'list', content: 'Listes dynamiques', name: 'list dynamics'}, {icon: 'import_export', content: 'Importation/Exportation', name: 'import export'}, {icon: 'security', content: 'Protection de données', name: 'protection'}]
    return(
        <div className="module-data-main">
            <SettingsContainerHeader title="Données (DATA)" subtitle="Gérez les données de votre module" />
            <div className="module-cards">
                {childTabs&& childTabs.map((c) => {
                    return <div onClick={() => handleSelect(c.name)}><ModuleDataCard icon={c.icon} content={c.content} /></div>
                })}
            </div>
        </div>
    )
}

export function ModuleDataCard({icon, content}: {icon: string, content: string}) {
    return(
        <div className="module-card-data">
            <div className="module-card-icon"><span className="icon">{icon}</span></div>
            <div>{content}</div>
        </div>
    )
}