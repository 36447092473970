import { Button, Tabs, TextArea } from "kls-ui";
import { useEffect, useState } from "react";
import { SettingsContainerHeader } from "../settings/settings";
import './create-apps-course.css';
import { Accordion } from "./accordion/accordion";
import { Pagination } from "kls-ui/paginationKls/pagination";

export function CreateAppCourse(props: any) {
    const [activeTab, setActiveTab] = useState('A propos');
    const [subTitle, setsubTitle] = useState('Utilisez le bouton "Nouvelle note" pour enregistrer des notes pendant que vous regardez la vidéo. Ces notes seront sauvegardées pour une référence future.');
    const [Title, setTitle] = useState('');
    const [notesArray, setNotesArray] = useState<any>([
        {
            notes: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores omnis ab nam repellendus unde, excepturi illum aut perspiciatis labore, eligendi debitis hic officia quisquam consequatur minus dolorem voluptatum impedit necessitatibus!',
            time: 'Il ya 2 jours'
        }
    ]);
    const [description, setDescription] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [courseNotes, setCourseNotes] = useState(true);
    const [openIndex, setOpenIndex] = useState<number | null>(null);
    
    useEffect(()=>{
        setOpenIndex(null)
    },[currentPage])

    console.log('openIndex',openIndex)

    const [accordionArray, setAccordionArray] = useState<any>([
        {
            title: 'Qu’est ce que ça veut dire une Tasklist ?',
            description: "Tasklist est une recherche spécifique pour chaque activité du processus. Dans cette recherche, on peut accéder aux objets ouverts dans l'activité en question et les traiter."
        },
        {
            title: 'Comment on peut changer la manière d’affichage d’un élément ?',
            description: "Tasklist est une recherche spécifique pour chaque activité du processus. Dans cette recherche, on peut accéder aux objets ouverts dans l'activité en question et les traiter."
        },
        {
            title: 'Est-ce que on peut créer plusieurs processus dans une seule application ?',
            description: "Tasklist est une recherche spécifique pour chaque activité du processus. Dans cette recherche, on peut accéder aux objets ouverts dans l'activité en question et les traiter."
        },
        {
            title: 'Qu’est ce que ça veut dire une Tasklist ?',
            description: "Tasklist est une recherche spécifique pour chaque activité du processus. Dans cette recherche, on peut accéder aux objets ouverts dans l'activité en question et les traiter."
        },
        {
            title: 'Est-ce que on peut créer plusieurs processus dans une seule application ?',
            description: "Tasklist est une recherche spécifique pour chaque activité du processus. Dans cette recherche, on peut accéder aux objets ouverts dans l'activité en question et les traiter."
        },
        {
            title: 'Qu’est ce que ça veut dire une Tasklist ?',
            description: "Tasklist est une recherche spécifique pour chaque activité du processus. Dans cette recherche, on peut accéder aux objets ouverts dans l'activité en question et les traiter."
        },
    ]);

    const newDataforArray = {
        notes: description,
        time: "tout à l'heure"
    };

    const handleTabClick = (label) => {
        setActiveTab(label);
    };

    useEffect(() => {
        if (notesArray.length !== 0) {
            setsubTitle('');
            setTitle('');
        } else {
            setTitle('');
            setsubTitle('Utilisez le bouton "Nouvelle note" pour enregistrer des notes pendant que vous regardez la vidéo. Ces notes seront sauvegardées pour une référence future.');
        }
    }, [notesArray]);

    const submit = () => {
        if (description !== '') {
            setNotesArray([...notesArray, newDataforArray]);
            setDescription('');
        } else {
            console.log("error");
        }
    };

    const handlePageChange = (startIndex, endIndex) => {
        setCurrentPage((startIndex / 4) + 1);
    };

    const startIndex = (currentPage - 1) * 3;
    const endIndex = Math.min(startIndex + 3, accordionArray.length);
    const paginatedData = accordionArray.slice(startIndex, endIndex);
    const totalPages = Math.ceil(accordionArray.length / 3);
    // console.log('paginatedData', paginatedData)
    // console.log('accordionArray', accordionArray)

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: '30px' }}>
            <div style={{ display: 'flex', backgroundColor: '#EFF6FF', padding: '20px 16px' }}>
                <div style={{ width: '100%', display: "flex", justifyContent: 'flex-start' }}>
                    <Button label="Précédent" size={"md"} color={"primary"} styling={"solid"} args={{}} />
                </div>
                <div style={{ width: '100%', display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
                    <Button label="Suivant" size={"md"} color={"primary"} styling={"solid"} args={{}} rightIcon="chevron_right" />
                    <Button label="Marquer comme terminé" size={"md"} color={"success"} styling={"solid"} args={{}} />
                </div>
            </div>
            <div style={{ position: 'relative' }}>
                <img src="/photos/videoPlayer.svg" alt="video" width={'100%'} />
            </div>
            <div className="course-tab-slide">
                <Tabs
                    align="horizontal"
                    current={activeTab}
                    defSelected={activeTab}
                    onSelect={handleTabClick}
                    size="small"
                    tabs={[
                        {
                            label: 'A propos',
                            name: 'A propos',
                            type: 'tab'
                        },
                        {
                            label: 'Note',
                            name: 'Note',
                            type: 'tab'
                        },
                        {
                            label: 'Questions/Réponses',
                            name: 'Questions/Réponses',
                            type: 'tab'
                        },
                    ]}
                />
                {activeTab === 'A propos' && (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px 8px 20px 24px' }}>
                        <div style={{ fontSize: '24px', color: '#3D3D3D', margin: '0', fontWeight: '700', fontFamily: 'Inter' }}>Description</div>
                        <div style={{ color: '#64748B', textAlign: 'justify', fontFamily: 'Inter', fontSize: '13px' }}>
                            <div>
                                Lorem ipsum dolor sit amet consectetur. Sit neque in nisl dolor aliquam. Eget mattis scelerisque ut morbi ipsum euismod aliquam nisl. Ornare nullam senectus sem bibendum egestas lectus massa sodales orci. Odio sit bibendum aliquam elit ut pellentesque. Id pulvinar quam aliquet tristique senectus egestas nisi. Facilisis dui at cras leo id praesent tempus at leo. Amet fermentum sit turpis sem lectus. Egestas ultrices at diam et gravida ipsum ullamcorper odio congue. Enim maecenas justo orci sed tortor cursus hac.
                            </div>
                            <div>
                                In pretium aliquam mauris sed volutpat nascetur consequat amet. Ut suspendisse diam pulvinar arcu lectus blandit. Rhoncus tincidunt placerat massa nunc quis tellus aliquam. Pretium amet neque velit sed viverra scelerisque. Nec pharetra viverra at cras fermentum lectus ac diam neque. In iaculis tortor mauris vulputate ultrices integer felis. Amet eget fermentum malesuada ultrices. Nec et justo amet donec cursus odio tellus. Risus sit amet ut viverra. Et amet neque proin eget adipiscing ut nisl.
                            </div>
                            <div>
                                Urna aenean nisi faucibus et posuere ipsum ut sit cras. At ante magna amet ut orci. Imperdiet odio sit tempor justo nunc etiam. Suscipit nulla ipsum vitae non semper. Senectus habitasse neque magna at in donec leo. Cursus ornare malesuada facilisis in tristique commodo et suscipit. Nullam turpis tincidunt metus in habitasse. Quam at eget nulla.
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === 'Note' && (
                    <div className="course-settings-header-content" style={{ padding: '20px 8px 20px 24px' }}>
                        {(notesArray.length === 0 || courseNotes) && (
                            <SettingsContainerHeader title={Title} subtitle={subTitle} enableAction={true} buttonName="Nouvelle note" icon="add" onClick={() => setCourseNotes(false)} />
                        )}
                        {notesArray.length !== 0 && (
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                <div>
                                    <TextArea size='md' label="Nouvelle note" infoText={description} args={{ onChange: (e) => setDescription(e.target.value), placeholder: 'Ecrire votre note ici', value: description }} />
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button label="Ajouter" size={"md"} color={"primary"} styling={"solid"} args={{ onClick: submit }} leftIcon="check" />
                                </div>
                                {notesArray.map((item, index) => (
                                    <div key={index} style={{ padding: '16px 18px 16px 18px', backgroundColor: '#F8FAFC', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ width: '100%', display: 'flex', gap: '8px', justifyContent: 'start', alignItems: 'center' }}>
                                                <span className="icon" style={{ color: '#2563EB', fontSize: '14px' }}>access_time</span>
                                                <p style={{ fontSize: '11px', fontWeight: '400', fontFamily: 'Inter', color: '#67727E', margin: '0' }}>{item.time}</p>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', gap: '6px', justifyContent: 'end', alignItems: 'center' }}>
                                                <span className="icon" style={{ color: '#94A3B8', fontSize: '14px', cursor: 'pointer' }} onClick={() => console.log('Edit course')}>mode_edit</span>
                                                <span className="icon" style={{ color: '#DC2626', fontSize: '14px', cursor: 'pointer' }} onClick={() => console.log('Delete course')}>delete</span>
                                            </div>
                                        </div>
                                        <div>
                                            <p style={{ color: '#334155', fontSize: '16px', fontFamily: 'Inter', fontWeight: '400', margin: '0' }}>{item.notes}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                {activeTab === 'Questions/Réponses' && (
                    <div style={{ padding: '20px 8px' }}>
                        {paginatedData.map((item, index) => (
                            <div style={{ padding: '20px 0px', borderBottom: '1px solid #D9DBE9' }} key={index}>
                                <Accordion title={item.title} description={item.description} isOpen={openIndex === index}
                                    onToggle={() => setOpenIndex(openIndex === index ? null : index)} />
                            </div>
                        ))}
                        <div style={{ padding: '20px 8px' }}>
                            <Pagination light={true} totalItems={accordionArray.length} itemsPerPage={3} maxPage={totalPages} handlePageChange={handlePageChange} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
