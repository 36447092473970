import { useEffect, useState } from "react";
import "./shema-component.css"
import { SettingsContainerHeader } from "../settings/settings";


export function ShemaComponent(props: any) {
    const [arrowOne, setArrowOne] = useState(false)
    const [schemaChart, setschemaChart] = useState(false)
    const [arrowTwo, setArrowTwo] = useState(false)
    const [containerGap, setContainerGap] = useState('')
    const [arrowOneGreen, setArrowOneGreen] = useState(false)
    const [arrowOneGreendouble, setArrowOneGreendouble] = useState(false)
    // const [arrowTwoGreen, setArrowTwoGreen] = useState(false)

    const handleSchemaChart = ()=>{
        setschemaChart(true)
    }

    const handleArrowOne = () => {
        setArrowOne(!arrowOne);
    }

    const handleArrowTwo = () => {
        setArrowTwo(!arrowTwo);
    }

    const handleClick = () => {
        if (arrowOne == true) {
            setArrowOneGreen(!arrowOneGreen);
        } else {
            console.log('test');
        }
    };

    useEffect(() => {
        if (arrowTwo == true) {
            setArrowOneGreendouble(true)
        }
        else {
            setArrowOneGreendouble(false)
        }
    }, [arrowTwo])

    useEffect(() => {
        if (arrowOne) {
            setContainerGap('')
        }
        else {
            setContainerGap('80px')
        }
    }, [arrowOne])

    return (<div className="shemaComponent-container">
        <SettingsContainerHeader title="Schéma relationnel" subtitle="Relations internes entre les modules de l’application" />
        {schemaChart == false &&
            <div className="shemaComponent-Content" onClick={handleSchemaChart}>
                <span className="icon shemaComponent-Content-icon">schema</span>
                <div className="shemaComponent-Content-header">
                    <h1>Aucun module pour l'instant</h1>
                    <p>Le schéma relationnel des modules créés sera affiché ici</p>
                </div>
            </div>}

        {/* shemaComponent-frame-one  */}

        {schemaChart == true &&
            <div className="shemaComponent-frame-one" style={{ backgroundColor: '#F8FAFC', padding: '50px', marginTop: '24px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: containerGap }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <div style={{ padding: '30px', borderRadius: '100px', backgroundColor: arrowOneGreen == true ? '#ECFDF5' : '#E2E8F0', border: ' 1px solid', borderColor: arrowOneGreen == true ? '#6EE7B7' : '#CBD5E1', width: 'max-content', color: '#1E293B' }} onClick={handleClick}>
                            <p style={{ margin: '0', fontFamily: 'Inter', fontWeight: '500', fontSize: '13px', textAlign: "center" }} >Gestion des demandes<br /> d’invetissement</p>
                        </div>
                    </div>
                    {
                        arrowOne == true &&
                        <div style={{ display: 'flex', gap: '50px' }} >
                            {
                                arrowOneGreen != true && arrowOneGreendouble != true &&
                                <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <img src="./photos/Arrow_one_schema.svg" alt="" style={{ cursor: 'pointer' }} />
                                </div>
                            }
                            {
                                arrowOneGreen == true && arrowOneGreendouble != true &&
                                <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <img src="./photos/Arrow_one_schema_green.svg" alt="" style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            }
                            {
                                arrowOneGreendouble == true &&
                                <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <img src="./photos/Arrow_one_double.svg" alt="" style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            }

                            <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-start' }}>
                                {
                                    arrowTwo == true &&
                                    <img src="./photos/Arrow_two_schema.svg" alt="" style={{ cursor: 'pointer' }} />
                                }
                            </div>


                        </div>
                    }

                    <div style={{ display: 'flex', justifyContent: 'center', gap: '200px' }} >
                        <div style={{ padding: '30px 44px', borderRadius: '100px', backgroundColor: arrowOneGreen == true && arrowOne == true ? '#ECFDF5' : '#E2E8F0', border: ' 1px solid', width: 'max-content', borderColor: arrowOneGreen == true && arrowOne == true ? '#6EE7B7' : '#CBD5E1' }} onClick={handleArrowOne}>
                            <p style={{ margin: '0', fontFamily: 'Inter', fontWeight: '500', fontSize: '13px', textAlign: "center", color: '#1E293B' }}>Création de projet</p>
                        </div>
                        <div style={{ padding: '30px', borderRadius: '100px', backgroundColor: '#E2E8F0', border: '#CBD5E1 1px solid', width: 'max-content', borderColor: '#CBD5E1' }} onClick={handleArrowTwo}>
                            <p style={{ margin: '0', fontFamily: 'Inter', fontWeight: '500', fontSize: '13px', textAlign: "center", color: '#1E293B' }}>Création d’invetisseurs</p>
                        </div>
                    </div>
                </div>
            </div>}
        {arrowOneGreen == true &&
            <div style={{ padding: '20px 0', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {
                    arrowOne == true &&
                    <div style={{ backgroundColor: '#F8FAFC', padding: '16px 50px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                        <h1 style={{ margin: '0', fontFamily: 'Inter', fontWeight: '700', fontSize: '15px', display: 'flex', alignItems: 'center', gap: '4px' }}>reference.projet  <span className="icon" style={{ rotate: '180deg', fontSize: '15px', fontWeight: '700' }}>trending_flat</span> id</h1>
                        <p style={{ margin: '0', fontFamily: 'Inter', fontWeight: '500', fontSize: '15px', color: '#1E293B' }}>La relation entre les deux modules est établie à l'aide d'une clé étrangère, où le champ <span style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '15px', color: '#EA580C' }}>reference.projet</span> du module source <span style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '15px', color: '#059669' }}>Gestion des demandes d’investissement</span> fait référence au champ <span style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '15px', color: '#EA580C' }}>id</span> du module cible <span style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '15px', color: '#059669' }}>Création de projet.</span></p>
                    </div>
                }
                {
                    arrowTwo == true &&
                    <div style={{ backgroundColor: '#F8FAFC', padding: '16px 50px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                        <h1 style={{ margin: '0', fontFamily: 'Inter', fontWeight: '700', fontSize: '15px', display: 'flex', alignItems: 'center', gap: '4px' }}>demande.invest.liees <span className="icon" style={{ rotate: '180deg', fontSize: '15px', fontWeight: '700' }}>trending_flat</span> id</h1>
                        <p style={{ margin: '0', fontFamily: 'Inter', fontWeight: '500', fontSize: '15px', color: '#1E293B' }}>La relation entre les deux modules est établie à l'aide d'une clé étrangère, où le champ <span style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '15px', color: '#EA580C' }}>reference.projet</span> du module source <span style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '15px', color: '#059669' }}>Gestion des demandes d’investissement</span> fait référence au champ <span style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '15px', color: '#EA580C' }}>id</span> du module cible <span style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '15px', color: '#059669' }}>Création de projet.</span></p>
                    </div>
                }
            </div>
        }

    </div>);
}