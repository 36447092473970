import { useHistory } from "react-router-dom";
import './individual-course.css';
import { Button, CourseCard } from "kls-ui";
import { useEffect, useState } from "react";
import { Accordion } from "./accordion/accordion";

interface IndividualCourseProps {
  id?: string;
  courseName?: string;
  courseCardColor?: string;
}

export function IndividualCourse(props: IndividualCourseProps) {
  const history = useHistory();
  const creditArray = [1, 2, 3, 4, 5];
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  useEffect(() => {
    window.scrollTo({top: 0})
  }, [])
  const accordionArray = [
    {
      title: 'Cours 1 - Création application à partir de zéro',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      time: '12.45 min',
      language: 'Français'
    },
    {
      title: 'Cours 2 - Création des processus',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      time: '12.45 min',
      language: 'Français'
    },
    {
      title: 'Cours 3 - Création des formulaires',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      time: '12.45 min',
      language: 'Français'
    },
    {
      title: 'Cours 4 - Création des recherches',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      time: '12.45 min',
      language: 'Français'
    },
    {
      title: 'Cours 5 - Création des tasklists',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      time: '12.45 min',
      language: 'Français'
    },
    {
      title: 'Cours 6 - Création des menus',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      time: '12.45 min',
      language: 'Français'
    },
  ];

  const individualCourseData = {
    credits: 5,
    views: `20,000`,
    time: '2',
    updated: '23 Mai, 2024'
  };

  const commenceCourse = () => {
    history.push(`/courses/${props?.id}`);
  };
  const mode = window.localStorage.getItem('mode')
  return (
    <div className={mode === 'dark' ? 'bg-light-dark' : ''} style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <div className={`individual-course-header ${mode === 'dark' ? 'filter-tags-dark' : ''}`}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '50%' }}>
          <div className={`individual-course-credits ${mode === 'dark' ? 'bg-light-dark' : ''}`}>
            {creditArray && creditArray.map((c) => {
              if (individualCourseData.credits >= c) {
                return <span className="icon" style={{ width: '16px', height: '16px', color: '#FFC107' }}>star</span>;
              } else {
                return <span className="icon" style={{ width: '16px', height: '16px' }}>star_border_outlined</span>;
              }
            })}
          </div>
          <h1 style={{ fontFamily: 'Inter', fontWeight: '700', margin: '0', color: '#2563EB', fontSize: '24px' }}>{props?.courseName}</h1>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ padding: '10px', display: 'flex', gap: '10px', alignItems: 'center' }}>
              <img className={`icon ${mode === 'dark' ? 'text-dark' : ''}`} style={{ width: '24px', height: '18px' }} src="photos/UsersThree.svg" />
              <p className={mode === 'dark' ? 'text-dark' : ''} style={{ fontFamily: 'Inter', fontWeight: '400', margin: '0', color: '#4B5563', fontSize: '16px' }}>{individualCourseData.views} Vue</p>
            </div>
            <div style={{ padding: '10px', display: 'flex', gap: '10px', alignItems: 'center' }}>
              <span className={`icon ${mode === 'dark' ? 'text-dark' : ''}`} style={{ color: '#4B5563', fontSize: '20px' }}>access_time</span>
              <p className={mode === 'dark' ? 'text-dark' : ''} style={{ fontFamily: 'Inter', fontWeight: '400', margin: '0', color: '#4B5563', fontSize: '16px' }}>{individualCourseData.time} H</p>
            </div>
            <div style={{ padding: '10px', display: 'flex', gap: '10px', alignItems: 'center' }}>
              <span className={`icon ${mode === 'dark' ? 'text-dark' : ''}`} style={{ color: '#4B5563', fontSize: '20px' }}>calendar_month</span>
              <p className={mode === 'dark' ? 'text-dark' : ''} style={{ fontFamily: 'Inter', fontWeight: '400', margin: '0', color: '#4B5563', fontSize: '16px' }}>Dernière mise à jour  {individualCourseData.updated}</p>
            </div>
          </div>
          <div>
            <Button label="Commencer" size="md" color={"primary"} styling={"solid"} args={{onClick: commenceCourse}} />
          </div>
        </div>
        <div style={{ width: '50%' }}>
          <div className="Individual-course-card-id" style={{ backgroundColor: props?.courseCardColor, background: `url("/icons/WaveBg.svg") `, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}>
            <div style={{ height: '50%' }}>
              <p style={{ fontFamily: 'Inter', fontWeight: '400', fontSize: '24px', color: '#FFFFFF', margin: '0', padding: '24px', width: '75%' }}>{props?.courseName}</p>
            </div>
            <div style={{ height: '50%', display: 'flex', alignItems: 'end', justifyContent: 'flex-end', paddingRight: '24px', paddingBottom: '20px' }}>
              <img className="Individual-course-icon" src="/icons/KarazWhite.svg" />
            </div>
          </div>
        </div>
      </div>
      <div className="individual-course-content">
        <div className="individual-course-content-description">
          <h1 className={mode === 'dark' ? 'text-dark' : ''} style={{ color: '#1E3A8A', fontFamily: 'Inter', fontWeight: '500', fontSize: '20px', margin: '0' }}>Description du cours</h1>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <p className={mode === 'dark' ? 'text-dark' : ''} style={{ color: '#5D5A6F', fontFamily: 'Inter', fontWeight: '400', fontSize: '15px', margin: '0' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.
            </p>
            <p className={mode === 'dark' ? 'text-dark' : ''} style={{ color: '#5D5A6F', fontFamily: 'Inter', fontWeight: '400', fontSize: '15px', margin: '0' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.
            </p>
          </div>
        </div>
        <div className="individual-course-content-certification">
          <h1 className={mode === 'dark' ? 'text-dark' : ''} style={{ color: '#1E3A8A', fontFamily: 'Inter', fontWeight: '500', fontSize: '20px', margin: '0' }}>Certification</h1>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <p className={mode === 'dark' ? 'text-dark' : ''} style={{ color: '#5D5A6F', fontFamily: 'Inter', fontWeight: '400', fontSize: '15px', margin: '0' }}>
              Certificat d'accomplissement
            </p>
          </div>
        </div>
        <div className="individual-course-content-dropdownList">
          <h1 className={mode === 'dark' ? 'text-dark' : ''} style={{ color: '#1E3A8A', fontFamily: 'Inter', fontWeight: '500', fontSize: '20px', margin: '0' }}>Contenu du cours</h1>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            {accordionArray.map((item, index) => (
              <div key={index}>
                <Accordion
                  type={'course-content'}
                  time={item.time}
                  language={item.language}
                  title={item.title}
                  description={item.description}
                  isOpen={openIndex === index}
                  onToggle={() => setOpenIndex(openIndex === index ? null : index)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
