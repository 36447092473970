import React from 'react';

interface AccordionProps {
  title?: any;
  description?: any;
  type?: any;
  time?: any;
  language?: any;
  isOpen?: boolean;
  onToggle?: () => void;
}

export const Accordion = (props: AccordionProps) => {
  const { isOpen, onToggle, title, description, type, time, language } = props;
  const mode = window.localStorage.getItem('mode')
  return (
    <div style={{ padding: type !== 'course-content' ? '20px' : '0px' }}>
      {title !== '' && type !== 'course-content' && (
        <div>
          {isOpen ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '80%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <h1 style={{ margin: '0', fontWeight: '500', fontSize: '20px', color: '#4A3AFF', fontFamily: 'Inter', width: '100%', textAlign: 'left' }}>{title}</h1>
                <p style={{ margin: '0', fontWeight: '400', fontSize: '16px', color: '#6F6C90', fontFamily: 'Inter', textAlign: 'left', width: '100%' }}>{description}</p>
              </div>
              <div style={{ width: '20%', display: 'flex', justifyContent: 'end' }}>
                <div style={{ backgroundColor: '#F1F5F9', width: '40px', height: '40px', padding: '12px', borderRadius: '8px', cursor: 'pointer' }}>
                  <span className='icon' style={{ color: '#1D4ED8', fontSize: '16px' }} onClick={onToggle}>horizontal_rule</span>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h1 style={{ margin: '0', fontWeight: '500', fontSize: '20px', color: '#170F49', fontFamily: 'Inter', width: '80%' }}>{title}</h1>
              <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                <span className='icon' style={{ color: '#2563EB', fontSize: '16px', width: '20%', textAlign: 'right', cursor: 'pointer', paddingRight: '18px' }} onClick={onToggle}>add</span>
              </div>
            </div>
          )}
        </div>
      )}
      {title !== '' && type === 'course-content' && (
        <div style={{ border: '#D9D9D9 solid 1px', borderRadius: '5px' }}>
          {isOpen ? (
            <div style={{ padding: '12px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h1 className={mode === 'dark' ? 'text-dark' : ''} style={{ margin: '0', fontWeight: '500', fontSize: '18px', color: '#475569', fontFamily: 'Inter', width: '100%', textAlign: 'left' }}>{title}</h1>
                <span className={`icon ${mode === 'dark' ? 'text-dark' : ''}`} style={{ fontSize: '22px', cursor: 'pointer' }} onClick={onToggle}>expand_less</span>
              </div>
              <div style={{ marginLeft: '2px', display: 'flex', flexDirection: 'column', gap: '13px' }}>
                <div style={{ width: '100%', display: 'flex', gap: '10px' }}>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <span className='icon' style={{ fontSize: '24px', color: '#1D4ED8' }}>access_time</span>
                    <p className={mode === 'dark' ? 'text-dark' : ''} style={{ margin: '0px', fontWeight: '400', fontSize: '16px', color: '#1E293B', fontFamily: 'Inter' }}>{time}</p>
                  </div>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <span className='icon' style={{ fontSize: '24px', color: '#1D4ED8' }}>language</span>
                    <p className={mode === 'dark' ? 'text-dark' : ''} style={{ margin: '0px', fontWeight: '400', fontSize: '16px', color: '#1E293B', fontFamily: 'Inter' }}>{language}</p>
                  </div>
                </div>
                <div>
                  <p className={mode === 'dark' ? 'text-dark' : ''} style={{ margin: '0px', fontWeight: '400', fontSize: '16px', color: '#1E293B', fontFamily: 'Inter' }}>{description}</p>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
              <h1 className={mode === 'dark' ? 'text-dark' : ''} style={{ margin: '0', fontWeight: '500', fontSize: '18px', color: '#475569', fontFamily: 'Inter', width: '100%', textAlign: 'left' }}>{title}</h1>
              <span className={`icon ${mode === 'dark' ? 'text-dark' : ''}`} style={{ fontSize: '22px', cursor: 'pointer' }} onClick={onToggle}>expand_more</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
