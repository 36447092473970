import React, { useState } from 'react'
import { SettingsContainerHeader } from '../../../settings/settings';
import { Button, ErrorMessage, Input, addToast } from 'kls-ui';
import { useHistory, useParams } from 'react-router-dom';
import './moduleDelection.css'


export const ModuleDeletion = () => {

  const [errorMessage, setErrorMessage] = useState(false);
  let [showPassword, setshowPassword] = useState(false);
  let [password, setpassword] = useState('');
  let [passwordErr, setpasswordErr] = useState('');
  let [password2, setpassword2] = useState(' ');
  let [password2Err, setpassword2Err] = useState('');
  let [showPasswordConfirm, setshowPasswordConfirm] = useState(false);
  let [userName, setuserName] = useState('');
  let { organisationID } = useParams() as any;
  let history = useHistory();


  const deleteApplication = () => {
    console.log('deleteApplication')
  }

  return (
    <div className="module-deletion-container">
    <SettingsContainerHeader title="Suppression de module" subtitle="Suppression de module avec confirmation." />
    {errorMessage &&
      <div className='mod-del-err-mes-btn'><ErrorMessage img='icons/error_outline.svg' label="Le nom d'utilisateur ou le mot de passe saisi est incorrect !" /></div>}
    <div className='module-deletion-authContainer'>
      <div className='module-deletion-auth'>
        <div className='module-deletion-usernameContent mod-deletion-auth'>
          <Input
            size="md"
            args={{
              placeholder: `Entrez votre nom d’utilisateur`, required: true, id: "userName", onChange: (e) => {
                setuserName(e.target.value);
              }
            }}
            label="Nom d’utilisateur"
          ></Input>
        </div>
        <div className='module-deletion-passwordContent mod-deletion-auth'>
          <Input
            errorMessage={passwordErr}
            args={{
              id: "password",
              type: showPassword ? "text" : "password", required: true, placeholder: 'Entrez votre mot de passe', onChange: (e) => {
                setpassword(e.target.value);
              }
            }}
            infoText=""
            label="Mot de passe"
            size="md"
          />
          {passwordErr == '' && <img className="eyeIcon mod-deletion-eyeIcon" src={showPassword ? "/icons/visibility_off.svg" : "/icons/remove_red_eye.svg"} alt="eye" onClick={() => setshowPassword(!showPassword)}></img>}
        </div>
        <div className='module-deletion-confirmPasswordContent mod-deletion-auth'>
          <Input
            errorMessage={password2Err}
            args={{
              type: showPasswordConfirm ? "text" : "password", required: true, placeholder: 'Confirmez votre mot de passe', onChange: (e) => {
                setpassword2(e.target.value);
                setpassword2Err('');
              }
            }}
            infoText=""
            label="Confirmer mot de passe"
            size="md"
          />
          {password2Err == '' && <img className="eyeIcon mod-deletion-eyeIcon" src={showPasswordConfirm ? "/icons/visibility_off.svg" : "/icons/remove_red_eye.svg"} alt="eye" onClick={() => setshowPasswordConfirm(!showPasswordConfirm)}></img>}
        </div>
      </div>
      <div className='module-deletion-btn'>
        <Button label='Supprimer  le module' leftIcon='delete' size='md' color='error' styling='outline' args={{ onClick: deleteApplication, disabled: userName.length != 0 && password === password2 && password.length != 0 && password2.length != 0 ? false : true, id: `${userName.length != 0 && password === password2 && password.length != 0 && password2.length != 0 ? `mod-delete-undisable` : ``}` }} />
      </div>
    </div>
  </div>
  )
}
