import { useEffect, useState } from "react"
import "../app-params/param-app.css"
import { SettingsContainerHeader } from "../settings/settings"
import { Input, Modal, NoResultPage } from "kls-ui"

 
export function MenusComponent(props: any) {
    // const [menuData, setmenuData] = useState([])
    // const [createMenu, setcreateMenu] = useState(false)
    // const onCreateClose = () => {
    //     setcreateMenu(false)
    // }
    // useEffect(() => {
    //     setmenuData([])
    // },[])
    // return(
    //     <div className="menu-main">
    //         {menuData.length === 0 ? <div className="menu-main">
    //             <SettingsContainerHeader title="Menus" subtitle="Gérez vos accès à l’aide des menus" buttonName="Nouveau menu" enableAction onClick={() => setcreateMenu(true)} />
    //             <NoResultPage title="Aucun menu pour l'instant" subtitle="La liste des menus créés sera affichée ici" img="photos/menu.svg" />
    //         </div> : <></>}
    //         {createMenu&& <CreateMenu handleClose={onCreateClose}  />}
    //     </div>
    // )
    return <div>Menu</div>
}

// export function CreateMenu({handleClose}: {handleClose: ()=>void}) {
//     const [modalName, setmodalName] = useState('')
//     return(
//         <div>
//             <Modal icon="" leftBtnArgs={ 
//                 {args: { onClick: handleClose },
//                 color: 'neutral',
//                 label: 'Annuler',
//                 size: 'sm',
//                 styling: 'outline'}} rightBtnArgs={{
//                     args: { onClick: () => console.log("clicked") },
//                     color: 'primary',
//                     label: 'Ajouter',
//                     size: 'sm',
//                     styling: 'solid'
//                 }} title='Nouveau menu'
//                 onClose={handleClose}
//                 bodyStyle={{ padding: '24px 16px' }}
//             >
//                 <div>
//                     <div>
//                         <Input size="md" label="Nom de menu" charCounter={40} 
//                             infoText="L'ordre d'affichage des menus est déterminé par un tri alpha-numérique basé sur les noms des menus." 
//                             helpText="Le nom du menu devient non éditable après la création." args={{placeholder: "Entrez le nom de votre menu", value: modalName, onChange: (e) => setmodalName(e.target.value)}} tooltipStyle={{whiteSpace: 'break-spaces', width: '275px'}} />
//                     </div>
//                 </div>
//             </Modal>
//         </div>
//     )
// }