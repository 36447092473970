import { Button, ColorInput, Dropdown, Input, RadioButtons, TextArea } from 'kls-ui'
import { SettingsContainerHeader } from '../settings/settings'
import './module-settings.css'
import { useEffect, useState } from 'react'
import { fetchLocalUserProfile } from 'kls-commons/service/userService'
import { useHistory } from 'react-router-dom'

export function ModuleSettings({id}: {id: string}) {
    const [moduleName, setmoduleName] = useState('')
    const userDetails = fetchLocalUserProfile()
    const [icon, seticon] = useState('')
    const [iconElements, seticonElements] = useState([{'label': 'icon', 'value': 'icon'}])
    const [selectedColor, setselectedColor] = useState('')
    const [formatSequence, setformatSequence] = useState([false, false])
    const [description, setdescription] = useState('')
    const [moduleData, setmoduleData] = useState<any>([])
    const [errorMessage, seterrorMessage] = useState({'nom': '', 'icon': ''})
    useEffect(() => {
        let moduleDatas:any = [{
            id: 1,
            image: 'photos/module-card1.svg',
            color: "#BFDBFE",
            title: "Gestion des projets",
            description: "Modifié il y a 2 jours",
            create: "il y a 7 jours"
        },
        {
            id: 2,
            image: 'photos/module-card2.svg',
            color: '#FEF08A',
            title: 'Création des investisseurs',
            description: 'Modifié il y a 2 jours',
            create: "il y a 26 jours"
        },
        {
            id: 3,
            image: 'photos/module-card3.svg',
            color: '#DDD6FE',
            title: 'Création des projets',
            description: 'Modifié il y a 2 jours',
            create: "il y a 3 mois"
        },
        {
            id: 4,
            image: 'photos/module-card4.svg',
            color: '#BFDBFE',
            title: 'Création des investisseurs',
            description: 'Modifié il y a 2 jours',
            create: "il y a 7 jours"
        },
        {
            id: 5,
            image: 'photos/module-card1.svg',
            color: "#BFDBFE",
            title: "Gestion des projets",
            description: "Modifié il y a 2 jours",
            create: "il y a 7 jours"
        },
        {
            id: 6,
            image: 'photos/module-card2.svg',
            color: '#FEF08A',
            title: 'Création des investisseurs',
            description: 'Modifié il y a 2 jours',
            create: "il y a 7 jours"
        },
        {
            id: 7,
            image: 'photos/module-card3.svg',
            color: '#DDD6FE',
            title: 'Création des projets',
            description: 'Modifié il y a 2 jours',
            create: "il y a 7 jours"
        },
        {
            id: 8,
            image: 'photos/module-card4.svg',
            color: '#BFDBFE',
            title: 'Création des investisseurs',
            description: 'Modifié il y a 2 jours',
            create: "il y a 7 jours"
        },
        {
            id: 9,
            image: 'photos/module-card1.svg',
            color: "#BFDBFE",
            title: "Gestion des projets",
            description: "Modifié il y a 2 jours",
            create: "il y a 7 jours"
        }]
        setmoduleData(moduleDatas.filter((c) => c.id.toString() === id))
    },[])
    useEffect(() => {
        if (selectedColor === '') {
            setselectedColor('#0000FF')
        }
    }, [selectedColor])
    const handleSubmit = () => {
        if(moduleName === '' && icon !== '') {
            seterrorMessage({'nom': 'Ce champ est requis', 'icon': ''})
        }
        else if(moduleName !== '' && icon === '') {
            seterrorMessage({'nom': 'Ce champ est requis', 'icon': 'Ce champ est requis'})
        }
        else if(moduleName === '' && icon === '') {
            seterrorMessage({'nom': 'Ce champ est requis', 'icon': 'Ce champ est requis'})
        }
        else if(moduleName !== '' && icon !== '') {
            seterrorMessage({'nom': '', 'icon': ''})
        }
    }
    const history = useHistory()
    return(
        <div className='module-settings-main'>
            <SettingsContainerHeader title='Module' subtitle='Gérez votre module' />
            <div className='module-settings-details'>
                <div className='module-settings-details-name'>
                    <div className='module-settings-details-module-name'>
                        <Input label='Nom' size='md' args={{placeholder: "Entrez le nom de votre module", value: moduleName, onChange: (e) => {setmoduleName(e.target.value); seterrorMessage({...errorMessage, 'nom': ''})}}} containerStyle={{borderRadius: '8px'}} errorMessage={errorMessage.nom} />
                        <div style={{color: '#64748B'}}>ID: {moduleData[0]?.title?.toLowerCase().replaceAll(' ', '_')}</div>
                    </div>
                    <div className='module-settings-details-module-name'>
                        <div style={{fontWeight: '500', fontSize: '14px'}}>Propriétaire</div>
                        <div className='module-settings-details-admin'>{userDetails?.fullName}</div>
                    </div>
                </div>
                <div className='module-settings-details-name'>
                    <div className='module-settings-details-module-name'>
                        <Dropdown
                            value={icon}
                            setValue={(e) => {seticon(e); seterrorMessage({...errorMessage, 'icon': ''})}}
                            size='md'
                            elementSize={36}
                            elements={iconElements}
                            label='Icône'
                            listTopPos='105%'
                            listBottomPos=''
                            listLeftPos=''
                            listRightPos=''
                            searchable="suggest"
                            args={{placeholder: 'Choisissez une icône'}}
                            errorMessage={errorMessage.icon}
                        />
                        {/* <Input label='Icône' size='md' args={{placeholder: "Entrez le nom de votre module", value: moduleName, onChange: (e) => setmoduleName(e.target.value)}} containerStyle={{borderRadius: '8px'}} />
                        <div style={{color: '#64748B'}}>ID: </div> */}
                    </div>
                    <div className='module-settings-details-module-name'>
                        <ColorInput color={selectedColor} onColorChange={(e) => setselectedColor(e)} />
                    </div>
                </div>
                <div className='module-settings-details-name'>
                    <div className='module-settings-details-module-radio'>
                        <div style={{fontWeight: '500', fontSize: '14px'}}>Format séquence</div>
                        <div className='module-settings-details-radio'>
                            <div className='module-settings-radio-button'>
                                <RadioButtons selectedRadio={formatSequence[0]} onclick={() => {setformatSequence([true, false])}} />
                                <label htmlFor="format">XXXX (4 chiffres s’incrémente)</label>
                            </div>
                            <div className='module-settings-radio-button'>
                                <RadioButtons selectedRadio={formatSequence[1]} onclick={() => {setformatSequence([false, true])}} />
                                <label htmlFor="format-sequence">XXXX/ANNEE (4 chiffres s’incrémente, réinitialisé chaque année)</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{fontWeight: '500', fontSize: '14px'}}>Description</div>
                    <TextArea size='md' infoText={description} args={{onChange: (e) => setdescription(e.target.value), placeholder: 'Décrivez brièvement votre module', value: description}} styleContainer={{borderRadius: '8px'}} />
                </div>
            </div>
            <div className='module-settings-button'>
                <div className="module-settings-all-submit-buttons">
                    <Button size="md" label="Annuler" styling="outline" color="neutral"  args={{ onClick: (e) => { history.push('/');   }, className: 'module-settings-cancel' }} />
                    <Button size="md" label="Enregistrer" styling="solid" color="primary" args={{ onClick: (e) => { handleSubmit() } }} />
                </div>
            </div>
        </div>
    )
}