import { useState, useEffect } from "react";
import { Button, CourseTabs, Tabs, ModuleTabs } from "kls-ui";
import './settings-sidebar.css'
import { AccessSecurity, Appearance, AccountDeletion, Performance, Roles, Workspace } from "../settings/settings";
import { OrganisationSettings, VerificationSettings, DomainSettings, Users, Groups, Strains, PaymentSettings, Support, OrganisationDeletion } from "./index";
import { AppComponent } from "../app-params/app-component";
import { ModuleComponent } from "../app-params/module-component";
import { ShemaComponent } from "../app-params/shema-component";
import { RolesComponent } from "../app-params/roles-component";
import { MenusComponent } from "../app-params/menus-component";
import { AccessControlComponent } from "../app-params/accessControl-component";
import { DeleteAppComponent } from "../app-params/deleteApp-component";
import { CreateAppCourse } from "../courses/create-apps-course";
import { ModuleData } from "../module-settings/data/data";
import { ModuleDataSchema } from "../module-settings/data/schema/schema";
import { ModuleList } from "../module-settings/data/list/module-list";
import { ImportExport } from "../module-settings/data/import-export/import-export";
import { ModuleDeletion } from "../module-settings/data/module-delete/moduleDeletion";
import { useParams } from "react-router-dom";
import { ModuleSettings } from "../module-settings/module-settings";
import { NotificationSettings } from "../settings/notifications/notifications";
import { ShareOnCatalog } from "../app-params/shareOnCatalog-component";
export function SettingsSideBar(props: any) {
  let [selectedTab, setSelectedTab] = useState(props?.tab);
  const [selectedChildTab, setselectedChildTab] = useState('')
  let [isSidebarOpen, setIsSidebarOpen] = useState(true);
  let [isSidebarContent, setIsSidebarcontent] = useState(false);
  let [settingsClassName,setSettingsClassName]=useState('settings-content')
  let [classNameSidebar,setClassNameSidebar]=useState('side-navbar')
  let [gapTabContainer,setgapTabContainer]=useState('')
  const { applicationID } = useParams() as any;
  const [dataFromChild, setDataFromChild] = useState<string>('');
  useEffect(() => {
    setSelectedTab(props?.tab);
  }, [props?.tab]);
  const handleTabSelect = (tabName: string) => {
    setSelectedTab(tabName);
    setIsSidebarOpen(false)
    setIsSidebarcontent(true)
  };
  const handleChildTabSelect = (tabName: string) => {
    setselectedChildTab(tabName);
    setIsSidebarOpen(false)
    setIsSidebarcontent(true)
  };
  const handleBackArrow = () => {
    setIsSidebarOpen(true)
    setIsSidebarcontent(false)
  }

  const handleDataFromChild = (data: string) => {
    setDataFromChild(data);
    console.log('test123',data);
    
  };
  console.log(selectedTab, props.tab, "selectedtab")
  const listParamOrganization = [
    { name: 'Organisation', label: <><span className="icon">corporate_fare</span>Organisation</>, component: <><OrganisationSettings popupClose={handleBackArrow} /></> },
    { name: 'Verification', label: <><span className="icon">verified</span>Vérification</>, component: <VerificationSettings /> },
    { name: 'Domain name', label: <><span className="icon">language</span>Nom de domaine</>, component: <DomainSettings /> },
    { name: 'Users', label: <><span className="icon">people_alt</span>Utilisateurs</>, component: <Users hintType={props.hintType ? props.hintType : ""} sethintType={props.sethintType ? props.sethintType : ""} /> },
    { name: 'Groups', label: <><span className="icon">workspaces</span>Groupes</>, component: <Groups /> },
    { name: 'Strains', label: <><span className="icon">rule</span>Souches</>, component: <Strains /> },
    { name: 'Payments', label: <><span className="icon">payment</span>Paiements</>, component: <PaymentSettings /> },
    { name: 'Support and help', label: <><span className="icon">help_outline</span>Support et aide</>, component: <Support /> },
    { name: 'Organization deletion', label: < ><span className="icon red-600">remove_circle_outline</span><span className="red-600">Suppression d’organisation</span> </>, component: <OrganisationDeletion /> },
  ]

  const listParamSettings = [
    { name: 'workspace', label: <><span className="icon">work_outline</span>Mon compte</>, component: <Workspace /> },
    { name: 'appearance', label: <><span className="icon">format_paint</span>Apparence</>, component: <Appearance /> },
    { name: 'accesssecurity', label: <><span className="icon">lock</span>Accès et sécurité</>, component: <AccessSecurity /> },
    { name: 'notifications', label: <><span className="icon">notifications_none</span>Notifications</>, component: <NotificationSettings /> },
    { name: 'roles', label: <><span className="icon">rule</span>Rôles et permissions</>, component: <Roles /> },
    { name: 'performance', label: <><span className="icon">multiline_chart</span>Performance</>, component: <Performance /> },
    { name: 'delete', label: <><span className="icon red-600">remove_circle_outline</span><span className="red-600">Suppression de compte</span></>, component: <AccountDeletion /> },
  ]

  const listParamApplications = [
    { name: 'application', label: <><span className="icon">bubble_chart</span>Application</>, component: <AppComponent moduleName={applicationID} sendDataToParent={handleDataFromChild}/> },
    { name: 'modules', label: <><span className="icon">apps</span>Modules</>, component: <ModuleComponent /> },
    { name: 'schema', label: <><span className="icon">schema</span>Schéma relationnel</>, component: <ShemaComponent/> },
    { name: 'app roles', label: <><span className="icon">workspaces</span>Rôles</>, component: <></> },
    { name: 'menus', label: <><span className="icon">menu</span>Menus</>, component: <></> },
    { name: 'access', label: <><span className="icon">login</span>Contrôles d’accès</>, component: <><AccessControlComponent /></> },
    { name: 'catalogue', label: <><span className="icon">share</span>Partage sur catalogue</>, component: <><ShareOnCatalog/></> },
    { name: 'app delete', label: <><span className="icon red-600">remove_circle_outline</span><span className="red-600">Suppression d’application</span></>, component: <DeleteAppComponent /> },
  ]

  const listParamCourses = [
    { name: 'create apps', label: <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between' }}><div style={{ display: 'flex', gap: '10px' }}><img src="/photos/check_circle.svg" /><span style={{ color: selectedTab === 'create apps' ? '#1F2937' : '#6B7280', fontWeight: selectedTab === 'create apps' ? '500' : '400' }}>Création application à partir de zéro</span></div><span className="icon" style={{ color: '#000000', fontSize: '12px', display: 'flex', justifyContent: 'flex-end' }}>chevron_right</span></div>, component: <CreateAppCourse /> },
    { name: 'explore apps', label: <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between' }}><div style={{ display: 'flex', gap: '10px' }}><span className="icon" style={{ color: '#D1D5DB' }}>brightness_1</span><span style={{ color: selectedTab === 'explore apps' ? '#1F2937' : '#6B7280', fontWeight: selectedTab === 'explore apps' ? '500' : '400' }}>Création des processus</span></div><span className="icon" style={{ color: '#000000', fontSize: '12px', display: 'flex', justifyContent: 'flex-end' }}>chevron_right</span></div>, component: <></> },
    { name: 'create module', label: <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between' }}><div style={{ display: 'flex', gap: '10px' }}><span className="icon" style={{ color: '#D1D5DB' }}>brightness_1</span><span style={{ color: selectedTab === 'create module' ? '#1F2937' : '#6B7280', fontWeight: selectedTab === 'create module' ? '500' : '400' }}>Création des formulaires</span></div><span className="icon" style={{ color: '#000000', fontSize: '12px', display: 'flex', justifyContent: 'flex-end' }}>chevron_right</span></div>, component: <></> },
    { name: 'design process', label: <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between' }}><div style={{ display: 'flex', gap: '10px' }}><span className="icon" style={{ color: '#D1D5DB' }}>brightness_1</span><span style={{ color: selectedTab === 'design process' ? '#1F2937' : '#6B7280', fontWeight: selectedTab === 'design process' ? '500' : '400' }}>Création des recherches</span></div><span className="icon" style={{ color: '#000000', fontSize: '12px', display: 'flex', justifyContent: 'flex-end' }}>chevron_right</span></div>, component: <></> },
    { name: 'design page', label: <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between' }}><div style={{ display: 'flex', gap: '10px' }}><span className="icon" style={{ color: '#D1D5DB' }}>brightness_1</span><span style={{ color: selectedTab === 'design page' ? '#1F2937' : '#6B7280', fontWeight: selectedTab === 'design page' ? '500' : '400' }}>Création des tasklists</span></div><span className="icon" style={{ color: '#000000', fontSize: '12px', display: 'flex', justifyContent: 'flex-end' }}>chevron_right</span></div>, component: <></> },
    { name: 'menu page', label: <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between' }}><div style={{ display: 'flex', gap: '10px' }}><span className="icon" style={{ color: '#D1D5DB' }}>brightness_1</span><span style={{ color: selectedTab === 'menu page' ? '#1F2937' : '#6B7280', fontWeight: selectedTab === 'design page' ? '500' : '400' }}>Création des menus</span></div><span className="icon" style={{ color: '#000000', fontSize: '12px', display: 'flex', justifyContent: 'flex-end' }}>chevron_right</span></div>, component: <></> },
  ]

  const listDependenciesModules = [
    { name: 'schema', label: <><span className="icon">data_object</span>Schéma de données</>, component: <ModuleDataSchema /> },
    { name: 'list dynamics', label: <><span className="icon">list</span>Listes dynamiques</>, component: <ModuleList /> },
    { name: 'import export', label: <><span className="icon">import_export</span>Import/Export</>, component: <ImportExport /> },
    { name: 'protection', label: <><span className="icon">security</span>Protection de données</>, component: <></> },
  ]

  const listParamModules = [
    { name: 'module', label: <><span className="icon">apps</span>Module</>, component: <ModuleSettings id={props?.id} /> },
    { name: 'data', label: <><span className="icon">data_object</span>Données (DATA)</>, component: <ModuleData handleSelect={handleChildTabSelect} />, child: listDependenciesModules },
    { name: 'translate', label: <><span className="icon">translate</span>Traduction</>, component: <></> },
    { name: 'notifications', label: <><span className="icon">notifications_none</span>Notifications</>, component: <></> },
    { name: 'dependencies', label: <><span className="icon">link</span>Dépendances</>, component: <></> },
    { name: 'module deletion', label: < ><span className="icon red-600">remove_circle_outline</span><span className="red-600">Suppression de module</span> </>, component: <><ModuleDeletion/></> },
  ]
  const width = window.innerWidth;

  useEffect(() => {
    if (selectedTab == 'access') {
      setSettingsClassName('settings-content-application')
    } else {
      setSettingsClassName('settings-content')
    }
  }, [selectedTab])

  useEffect(() => {
    if (props?.type == 'courses') {
      setClassNameSidebar('side-navbar-course')
      setSettingsClassName('settings-content-course')
      setgapTabContainer('10px')
    } else if (props?.type != 'courses') {
      setClassNameSidebar('side-navbar')
      setSettingsClassName('settings-content')
      setgapTabContainer('')
    }
  }, [props, listParamCourses])
  const mode = window.localStorage.getItem('mode')
  return (
    <>
      {width > 800 ?
        <div className={`tab-container Desktop-version ${mode === 'dark' ? 'bg-light-dark' : ''}`} style={{gap: gapTabContainer}}>
          <div className={classNameSidebar} style={{ width: props?.type !== 'courses' ? '240px' : '275px' }}>
            {props?.type === 'courses' ? <CourseTabs tabs={listParamCourses} size="medium" align={'vertical'} onSelect={handleTabSelect} current={selectedTab} defSelected={"app"} /> : props?.type === 'modules' && selectedTab !== 'data' ?
              <Tabs tabs={listParamModules} size="medium" align={'vertical'} onSelect={handleTabSelect} current={selectedTab} /> : props?.type === 'modules' && selectedTab === 'data' ?
                <ModuleTabs tabs={listParamModules} size="medium" align="vertical" onSelect={handleTabSelect} current={selectedTab} onChildSelect={handleChildTabSelect} currentChild={selectedChildTab} /> :
                <Tabs tabs={props?.type == 'settings' ? listParamSettings : props?.type === 'applications' ? listParamApplications : props?.type === 'organizationSettings' ? listParamOrganization : props.type === 'courses' ? listParamCourses : props.type === 'modules' ? listParamModules : []} size="medium" align={'vertical'} onSelect={handleTabSelect} current={selectedTab}
                  defSelected={"app"} />
            }
          </div>
          <div className={settingsClassName}>
            {props?.type == 'settings' ? listParamSettings.find(tab => tab.name === selectedTab)?.component : props?.type == 'applications' ? listParamApplications.find(tab => tab.name === selectedTab)?.component : props?.type === 'organizationSettings' ? listParamOrganization.find(tab => tab.name === selectedTab)?.component : props?.type === 'modules' && selectedTab !== 'data' ? listParamModules.find(tab => tab.name === selectedTab)?.component : props?.type === 'modules' && selectedTab === 'data' && selectedChildTab === '' ? listParamModules.find(tab => tab.name === selectedTab)?.component : props?.type === 'modules' && selectedTab === 'data' && selectedChildTab !== '' ? listDependenciesModules.find(tab => tab.name === selectedChildTab)?.component : listParamCourses.find(tab => tab.name === selectedTab)?.component}
          </div>
        </div> :
        <div className={`tab-container mobile-version ${mode === 'dark' ? 'bg-light-dark' : ''}`}>

          {isSidebarOpen &&
            <div className='side-navbar'>
              <div className="settings-sidebar-mobile-header">
                <a href="#"><span className="icon arrow-back-settings">arrow_back</span></a>
                <h1>{props?.type === 'applications' ? 'Paramètres de l’application' : props?.type === 'modules' ? 'Paramètres de module Gestion de projets' : props?.type !== 'courses' ? 'Paramètres du compte' : 'Créez votre première Application'}</h1>
              </div>
              {props?.type !== 'modules' || (props?.type === 'modules' && selectedTab !== 'data') ?
                <Tabs tabs={props?.type == 'settings' ? listParamSettings : props?.type == 'applications' ? listParamApplications : props?.type === 'organizationSettings' ? listParamOrganization : props?.type === 'modules' ? listParamModules : listParamCourses} size="medium" align={'vertical'} onSelect={handleTabSelect} current={selectedTab}
                  defSelected={"app"} /> : <ModuleTabs tabs={listParamModules} size="medium" align="vertical" onSelect={handleTabSelect} current={selectedTab} onChildSelect={handleChildTabSelect} currentChild={selectedChildTab} />
              }

            </div>}
          {isSidebarContent &&
            <div className="settings-content mobile-version-content">
              {props?.type !== 'courses' && <span className="icon arrow-back-settings" onClick={handleBackArrow}>arrow_back</span>}
              {props?.type == 'courses' && <div style={{ display: 'flex', gap: '12px', alignItems: 'center', marginBottom: '16px' }}>
                {/* <div> */}
                <span className="icon" style={{ color: '#000000', fontSize: '16px' }} onClick={handleBackArrow}>arrow_back</span>
                {/* </div> */}

                <div style={{ display: 'flex', width: '90%', justifyContent: 'space-between' }}>
                  <div className='applications-settings-header-mobile'>Cours et tutoriels </div>
                  <Button size='md' color='primary' styling='solid' label='Suivant' rightIcon='chevron_right' args={{ onClick: (e) => { console.log("courses") }, style: { height: '40px' } }} />
                </div>

              </div>
              }
              {props?.type == 'settings' ? listParamSettings.find(tab => tab.name === selectedTab)?.component : props?.type == 'applications' ? listParamApplications.find(tab => tab.name === selectedTab)?.component : props?.type === 'organizationSettings' ? listParamOrganization.find(tab => tab.name === selectedTab)?.component : props?.type === 'modules' && selectedTab !== 'data' ? listParamModules.find(tab => tab.name === selectedTab)?.component : props?.type === 'modules' && selectedTab === 'data' && selectedChildTab === '' ? listParamModules.find(tab => tab.name === selectedTab)?.component : props?.type === 'modules' && selectedTab === 'data' && selectedChildTab !== '' ? listDependenciesModules.find(tab => tab.name === selectedChildTab)?.component : listParamCourses.find(tab => tab.name === selectedTab)?.component}
            </div>}
        </div>}
    </>
  )
}



