import { useState, useEffect } from "react";
import "./domain.css";
import { Button, ErrorMessage, Input, addToast } from "kls-ui";
import { SettingsContainerHeader } from "../../settings/settings";
import { fetchLocalUserDetails, fetchLocalUserProfile } from "kls-commons/service/userService";
import { OrganizationSettingsService } from "../organization-settings-service";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DomainUpdateService } from "./domain-service";
export function DomainSettings(props: any) {
  const [errorMessage, setErrorMessage] = useState(false);
  const [organisationName, setOrganisationName] = useState(props.value);
  const [organisationNameErr, setOrganisationNameErr] = useState('');
  const location = useLocation();
  let { organisationID } = useParams() as any


  const createOraganisationSubmit = () => {
    if (organisationName === '') {
      setOrganisationNameErr('Le nom de domaine est requis');
    }
    if (organisationName != ''){
      let result = fetchLocalUserDetails();

      DomainUpdateService (result?.userID,organisationID,organisationName)
      .then(
        (result) => {
          if (result?.error) {
            console.log(result);
            addToast(result?.error, 'error');
          } else if (result?.result == 'Done') {
            addToast('Domaine mis à jour avec succès', 'success');
            localStorage.setItem('organizationData', JSON.stringify(result));
            console.log('Domain Update')
          }
        },
        (error => {
          console.log("API_ERROR", error);
        })
      );

    }
  }

  const [placeholder, setPlaceholder] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        let loggedinUser = fetchLocalUserProfile();
        const fetchDataResult = await OrganizationSettingsService(loggedinUser?.userId, location.pathname.split("/")?.[2]);
        setOrganisationName(fetchDataResult?.data?.domainName.split("@")[0])
        
      }
      catch (error) {
        console.error("Error fetching organization settings:", error);
      }
    }
    fetchData()
  }, [props])

  console.log('organisationName',organisationName)

  useEffect(() => {
    const updatePlaceholder = () => {
      setPlaceholder(window.innerWidth >= 500 ? 'Entrez un acronyme sans espaces ni caractères spécieux' : 'Domain');
    };
    updatePlaceholder();
    window.addEventListener('resize', updatePlaceholder);
    return () => {
      window.removeEventListener('resize', updatePlaceholder);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\W+/, '');
    setOrganisationNameErr('');
    if (inputValue == '') {
      setOrganisationNameErr('Le nom de domaine est requis');
    }

    if (numericValue === '') {
      setOrganisationName('');
    } else {
      setOrganisationName(numericValue);
    }
  }
  const history = useHistory()
  return (
    <div className="domain-container">
      <SettingsContainerHeader title="Nom de domaine" subtitle="Point d'accès public de votre organisation" />
      {errorMessage && <ErrorMessage img="icons/error_outline.svg" label="Le nom de domaine que vous avez saisi est déjà utilisé. Veuillez essayer un autre nom." />}
      <div className="bottom-domain">
        <div className="inputcontainer input-placeholder-domain">
          <Input
            size="md" label="Nom de domaine" errorMessage={organisationNameErr} domainText=".karaz.org"
            args={{
              placeholder: placeholder, required: true, id: "organizationdoamin", value: organisationName,
              onChange:
                handleInputChange
            }}></Input>
        </div>
        <div className="domain-button">
          <div className="domain-all-submit-buttons">
            <Button size="md" label="Fermer" styling="outline" color="neutral"  args={{ onClick: (e) => { history.push('/') }, className: 'domain-button-cancel' }} />
            <Button size="md" label="Enregistrer" styling="solid" color="primary" args={{ onClick: (e) => { createOraganisationSubmit() } }} />
          </div>
        </div>
      </div>
    </div>
  )
}

