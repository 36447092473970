import { useEffect, useState } from 'react'
import { currentUser } from "kls-commons/service/userService";
import { SettingsHeader } from '../settings/settings';
import { SettingsSideBar } from "./settings-sidebar";
import { GS } from "kls-commons/state/global-state";
import { Button } from 'kls-ui';
import style from '../menu/style';
import { IndividualCourse } from '../courses/individual-course';
import { useParams } from 'react-router-dom';

export function SettingsModule(props: any) {
  const params: any = useParams()
  let courseID = ''
  let moduleID = ''
  const [applicationName, setApplicationName] = useState<any>('')

  if (props?.type === 'individualCourse' || props?.type === 'courses') {
    courseID = params.courseID
  }
  if (props?.type === 'modules') {
    moduleID = params.moduleID
  }
  useEffect(() => {
    if (props?.type === 'applications') {
      setApplicationName(params.applicationID)
    }
  }, [params, props?.type])

  const [courseName, setcourseName] = useState<any>('')
  const [moduleName, setmoduleName] = useState<any>('')
  const [courseCardColor, setCourseCardColor] = useState('')
  useEffect(() => {
    let data = [{ content: "Créez votre première Application", color: '#3B82F6', id: "1" },
    { content: "Créez vos pages avec La bibliothèque de composants", color: '#10B981', id: "2" },
    { content: "Concevez vos processus selon Les standards BPMN", color: "#F97316", id: "3" },
    { content: "Créez et configurez des Groupes d’utilisateurs", color: '#8B5CF6', id: "4" },
    { content: "Créez votre première Application", color: '#3B82F6', id: "5" },
    { content: "Créez vos pages avec La bibliothèque de composants", color: '#10B981', id: "6" },
    { content: "Concevez vos processus selon Les standards BPMN", color: "#F97316", id: "7" },
    { content: "Créez et configurez des Groupes d’utilisateurs", color: '#8B5CF6', id: "8" },
    { content: "Créez votre première Application", color: '#3B82F6', id: "9" },
    { content: "Créez vos pages avec La bibliothèque de composants", color: '#10B981', id: "10" },
    { content: "Concevez vos processus selon Les standards BPMN", color: "#F97316", id: "11" },
    { content: "Créez et configurez des Groupes d’utilisateurs", color: '#8B5CF6', id: "12" },
    { content: "Créez votre première Application", color: '#3B82F6', id: "13" },
    { content: "Créez vos pages avec La bibliothèque de composants", color: '#10B981', id: "14" },
    { content: "Concevez vos processus selon Les standards BPMN", color: "#F97316", id: "15" },
    { content: "Créez et configurez des Groupes d’utilisateurs", color: '#8B5CF6', id: "16" },
    { content: "Créez votre première Application", color: '#3B82F6', id: "17" },
    { content: "Créez vos pages avec La bibliothèque de composants", color: '#10B981', id: "18" },
    { content: "Concevez vos processus selon Les standards BPMN", color: "#F97316", id: "19" },
    { content: "Créez et configurez des Groupes d’utilisateurs", color: '#8B5CF6', id: "20" },
    ];
    setcourseName(courseID !== "" ? data.filter((c) => c.id === courseID)[0].content : "")
    setCourseCardColor(courseID !== "" ? data.filter((c) => c.id === courseID)[0].color : "")
  }, [courseID])
  useEffect(() => {
    let moduleData = [{
      id: 1,
      image: 'photos/module-card1.svg',
      color: "#BFDBFE",
      title: "Gestion des projets",
      description: "Modifié il y a 2 jours",
      create: "il y a 7 jours"
    },
    {
      id: 2,
      image: 'photos/module-card2.svg',
      color: '#FEF08A',
      title: 'Création des investisseurs',
      description: 'Modifié il y a 2 jours',
      create: "il y a 26 jours"
    },
    {
      id: 3,
      image: 'photos/module-card3.svg',
      color: '#DDD6FE',
      title: 'Création des projets',
      description: 'Modifié il y a 2 jours',
      create: "il y a 3 mois"
    },
    {
      id: 4,
      image: 'photos/module-card4.svg',
      color: '#BFDBFE',
      title: 'Création des investisseurs',
      description: 'Modifié il y a 2 jours',
      create: "il y a 7 jours"
    },
    {
      id: 5,
      image: 'photos/module-card1.svg',
      color: "#BFDBFE",
      title: "Gestion des projets",
      description: "Modifié il y a 2 jours",
      create: "il y a 7 jours"
    },
    {
      id: 6,
      image: 'photos/module-card2.svg',
      color: '#FEF08A',
      title: 'Création des investisseurs',
      description: 'Modifié il y a 2 jours',
      create: "il y a 7 jours"
    },
    {
      id: 7,
      image: 'photos/module-card3.svg',
      color: '#DDD6FE',
      title: 'Création des projets',
      description: 'Modifié il y a 2 jours',
      create: "il y a 7 jours"
    },
    {
      id: 8,
      image: 'photos/module-card4.svg',
      color: '#BFDBFE',
      title: 'Création des investisseurs',
      description: 'Modifié il y a 2 jours',
      create: "il y a 7 jours"
    },
    {
      id: 9,
      image: 'photos/module-card1.svg',
      color: "#BFDBFE",
      title: "Gestion des projets",
      description: "Modifié il y a 2 jours",
      create: "il y a 7 jours"
    }]
    setmoduleName(moduleID !== "" ? moduleData.filter((c) => c.id.toString() === moduleID)[0].title : "")
  }, [moduleID])
  const mode = window.localStorage.getItem('mode')
  return <div>
    <div className="settings-modules">
      {props?.type !== 'applications' && props?.type !== 'individualCourse' && props?.type !== 'courses' && props?.type !== 'modules' ? <SettingsHeader type={props?.type} image={currentUser().avatarUrl ?? ""} username={currentUser().fullName} /> : props?.type === 'applications' ? <div className='applications-settings-header'>Paramètres de l’application <span style={{ color: '#2563EB' }}>{applicationName}</span></div> : props?.type === 'individualCourse' || props?.type === 'courses' ? <div className='course-details-header'><div className={`applications-settings-header course-header ${mode === 'dark' ? 'text-dark' : ''}`}><span>Cours et tutoriels | </span><span style={{ color: '#3B82F6' }}>{courseName}</span></div></div> : props?.type === 'modules' ? <div className={`applications-settings-header course-header ${mode === 'dark' ? 'text-dark' : ''}`}><span>Paramètres de module</span><span style={{ color: '#3B82F6' }}>{moduleName}</span></div> : <></>}
      {props?.type !== 'individualCourse' ? <SettingsSideBar id={props?.type === 'modules' ? moduleID : ''} tab={props?.tab} type={props?.type} gs={GS} connected={props.connected} hintType={props.hintType} sethintType={props.sethintType}></SettingsSideBar> : <IndividualCourse id={courseID} courseName={courseName} courseCardColor={courseCardColor} />}
    </div >
  </div >
}