import { useState, useEffect, useRef } from "react";
import { CourseCard } from 'kls-ui';
import { useHistory } from "react-router-dom";
import './course-details.css'

export function CourseDetails(props: any) {
  const history = useHistory();
  const allCourseDetails = () =>{
    history.push("/courses");
  }

  const individualCourse = (id) => {
    history.push(`/courses/${id}/individual`)
  }
  const mode = window.localStorage.getItem('mode')
  return (
    <div className={`course-details ${mode === 'dark' ? 'bg-light-dark' : ''}`}>
      <div className="course-cards">
        <h3 className={`title ${mode === 'dark' ? 'text-dark' : ''}`}>Cours et tutoriaux</h3>
        <span className="sub-head" onClick={allCourseDetails}>Afficher tous</span>
      </div>
      <div className="course-section">
        <div onClick={() => individualCourse(1)}><CourseCard content="Créez votre première Application" color="#3B82F6" /></div>
        <div onClick={() => individualCourse(2)}><CourseCard content="Créez vos pages avec La bibliothèque de composants" color="#10B981" /></div>
        <div onClick={() => individualCourse(3)}><CourseCard content="Concevez vos processus selon Les standards BPMN" color="#F97316" /></div>
        <div onClick={() => individualCourse(4)}><CourseCard content="Créez et configurez des Groupes d’utilisateurs" color="#8B5CF6" /></div>
      </div>
    </div>
  );
}
