import { AdvancedDropDown, CourseCard, Input, SearchInput } from "kls-ui";
import { useEffect, useRef, useState } from "react"
import './all-courses.css'
import { Pagination } from "kls-ui/paginationKls/pagination";
import { useHistory } from "react-router-dom";

export function AllCourses() {
    const [courseAll, setCourseAll] = useState<any>([]);
    let history = useHistory();

    const [showSort, setShowSort] = useState<boolean>(false);
    const [sort, setSort] = useState<any>('recent');
    const sortRef = useRef(null);
    let [pageView, setpageView] = useState('grid');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 16;
    const [ filter, setFilter ] = useState('');
    const [pagination, setPagination] = useState(true);
    const [currentPageIndex, setcurrentPageIndex] = useState(0)
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, courseAll.length);

    console.log(currentPage, startIndex, endIndex, courseAll.length, Math.ceil(courseAll.length / itemsPerPage), "pages")



    const listFilter: any = [
        {
            value: "recent",
            label: "Récent",
            labelColor: 'white'
        },
        {
            value: "popular",
            label: "Populaire",
            labelColor: 'white'
        }
    ]
    



    useEffect(() => {
        let data = [{ content: "Créez votre première Application", color: '#3B82F6', id: "1" },
        { content: "Créez vos pages avec La bibliothèque de composants", color: '#10B981', id: "2" },
        { content: "Concevez vos processus selon Les standards BPMN", color: "#F97316", id: "3" },
        { content: "Créez et configurez des Groupes d’utilisateurs", color: '#8B5CF6', id: "4" },
        { content: "Créez votre première Application", color: '#3B82F6', id: "5" },
        { content: "Créez vos pages avec La bibliothèque de composants", color: '#10B981', id: "6" },
        { content: "Concevez vos processus selon Les standards BPMN", color: "#F97316", id: "7" },
        { content: "Créez et configurez des Groupes d’utilisateurs", color: '#8B5CF6', id: "8" },
        { content: "Créez votre première Application", color: '#3B82F6', id: "9" },
        { content: "Créez vos pages avec La bibliothèque de composants", color: '#10B981', id: "10" },
        { content: "Concevez vos processus selon Les standards BPMN", color: "#F97316", id: "11" },
        { content: "Créez et configurez des Groupes d’utilisateurs", color: '#8B5CF6', id: "12" },
        { content: "Créez votre première Application", color: '#3B82F6', id: "13" },
        { content: "Créez vos pages avec La bibliothèque de composants", color: '#10B981', id: "14" },
        { content: "Concevez vos processus selon Les standards BPMN", color: "#F97316", id: "15" },
        { content: "Créez et configurez des Groupes d’utilisateurs", color: '#8B5CF6', id: "16" },
        { content: "Créez votre première Application", color: '#3B82F6', id: "17" },
        { content: "Créez vos pages avec La bibliothèque de composants", color: '#10B981', id: "18" },
        { content: "Concevez vos processus selon Les standards BPMN", color: "#F97316", id: "19" },
        { content: "Créez et configurez des Groupes d’utilisateurs", color: '#8B5CF6', id: "20" },

        ];
        setCourseAll(data);

    }, [])

    const [isDisplayModeList, setDisplayModeList] = useState<boolean>(false);
    function DisplayModeApp({ icon, isSelected, onClick }: { icon: string, isSelected: boolean, onClick: () => void }) {

        return <button className={`display-mode-myOrg ${isSelected ? "selected" : ""} ${mode === 'dark' ? `display-apps-dark` : ''}`} onClick={onClick} disabled={isSelected}>
            <span className={`material-icon ${mode === 'dark' ? 'display-apps selected' : ''}`}>{icon}</span>
        </button>
    }
    const handlePageChange = (start: number, end: number) => {
        // if(start < 0) return;
        const newCurrentPage = Math.floor(start / itemsPerPage) + 1;
        setCurrentPage(newCurrentPage) 
        setcurrentPageIndex(0)
    };

    const changeCourseId = (id) => {
        history.push(`/courses/${id}/individual`)
    }

    let filteredCourseData = filter !== '' ? courseAll.filter(o => 
        o&&o.content.toLowerCase().includes(filter.toLowerCase())
    ) : courseAll;

    let dataRow = filteredCourseData && filteredCourseData.slice(startIndex, endIndex);
    const totalPages = Math.ceil(courseAll.length / itemsPerPage);

    console.log(dataRow, filteredCourseData, courseAll, startIndex, endIndex, "datarow")
    useEffect(() => {
        setCurrentPage(1);
    }, [filter]);

    const handleSearchInputChange = (value: string) => {
        setFilter(value);
        setCurrentPage(1)
        setcurrentPageIndex(1)
        filteredCourseData = value !== '' ? courseAll.filter(o => 
            o&&o.content.toLowerCase().includes(value.toLowerCase())
        ) : courseAll
        if(filteredCourseData.length <= 16) {
            setPagination(false)
        }
        else {
            setPagination(true)
        }
    };

    console.log(filter, currentPage, "filter")

    const mode = window.localStorage.getItem('mode')
    return (
        <div className={`allCourseContainer ${mode === 'dark' ? 'bg-light-dark' : ''}`}>
            <div className="allCourse-title-container"><p className={`allCourse-title ${mode === 'dark' ? 'text-dark' : ''}`}>Cours et tutoriaux</p><span className={`nb-apps ${mode === 'dark' ? 'bg-dark' : ''}`}>{courseAll.length}</span></div>
            <div className="allCourse-search-container">
                <span style={{ width: '67%', display: 'flex' }}>
                    <SearchInput light={true} size={3} value={filter} setValue={handleSearchInputChange} addClearbutton={true} />
                </span>
                <div className='allCourse-sort-my-apps'>
                    <span className='allCourse-sort-label'>Trier par :</span>
                    <div className='allCourse-sort-name' onClick={() => { setShowSort(!showSort) }} >
                        <span className={mode === 'dark' ? 'text-dark' : ''}>{listFilter.find((f: any) => f?.value === sort).label}</span>
                        <div className='allCourse-sort-expand-more' ref={sortRef} >
                            <span className={`material-icon ${mode === 'dark' ? 'text-dark' : ''}`}>expand_more</span>
                            <div>
                                {
                                    showSort &&
                                    <AdvancedDropDown data={listFilter} enableSearch={false}
                                        light={false}
                                        handleChange={(item) => { setSort(item.value) }}
                                        size={1}
                                        selectedValue={sort}
                                        style={{ top: '25px', minWidth: '100px', right: '0', color: 'white' }}
                                        closeWhenSelect={() => { setShowSort(false) }}
                                        closeWhenClickOutside={() => { setShowSort(false) }} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="allCourse-filtersection">
                    <Input
                        args={{
                            value: 'Filters',
                            id: "filter",
                            readOnly: true,
                            type: "text", placeholder: 'Filters',
                            style: { cursor: 'pointer' }
                        }}

                        infoText=""
                        label=""
                        size="md"
                    />
                    <img className="allCourse-clearSearch" src="/photos/filter_list.svg" alt="filter_list" ></img>
                </div>
                <img className="allCourse-verticalline" src="/icons/verticalline.svg" alt="verticalline" ></img>
                <div style={{ display: "flex", gap: '10px' }}>
                    <DisplayModeApp icon="grid_view" onClick={() => { setpageView('grid'); setDisplayModeList(false) }} isSelected={!isDisplayModeList} />
                    <DisplayModeApp icon="format_list_bulleted" onClick={() => { setpageView('list'); setDisplayModeList(true) }} isSelected={isDisplayModeList} />
                </div>
            </div>
            <div className="allCourse-courseCard-container">
                {dataRow.length > 0 && dataRow.map((course) => {
                    return <div style={{cursor:'pointer'}} onClick={() => changeCourseId(course.id)}><CourseCard content={course.content} color={course.color}  /></div>
                })}
            </div>
            {pagination&& <div style={{marginBottom: '40px'}}>
                {dataRow.length > 0 && <Pagination light={true} totalItems={courseAll.length} itemsPerPage={itemsPerPage} maxPage={totalPages} handlePageChange={handlePageChange} currentIndex={currentPageIndex} />}
            </div>}
        </div>
    )
}