import { useEffect, useState } from "react";
import "../app-params/param-app.css"
import './accessControl-component.css'
import { SettingsContainerHeader } from "../settings/settings";
import { Button, GenericTable, Input, ToggleButton } from "kls-ui";


export function AccessControlComponent(props: any) {

    const [isactiveOne, setisactiveoOne] = useState(false);
    const [isactiveTwo, setisactiveoTwo] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const handleactiveone = () => {
        setisactiveoOne(!isactiveOne);
    }
    const handleactiveTwo = () => {
        setisactiveoTwo(!isactiveTwo);
    }
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const handleClearSearch = () => {
        setSearchQuery('');
    };

    const data = [
        {
            Demandeur: <div ><p style={{ color: '#334155', fontFamily: 'Inter', fontWeight: '500', fontSize: '13px' }}>bennani.ahmed@karaz.org</p></div>,
            Access: <div style={{ color: '#2563EB', fontFamily: 'Inter', fontWeight: '400', fontSize: '12px', cursor: 'pointer' }}>Détails</div>,
            Actions: <div style={{ display: 'flex', gap: '12px' }}><Button label="Approuver" size={"sm"} color={"success"} styling={"outline"} args={{}} /><Button label="Supprimer" size={"sm"} color={"error"} styling={"outline"} args={{}} /></div>
        },
        {
            Demandeur: <div ><p style={{ color: '#334155', fontFamily: 'Inter', fontWeight: '500', fontSize: '13px' }}>jalouli.ilham@karaz.org</p></div>,
            Access: <div style={{ color: '#2563EB', fontFamily: 'Inter', fontWeight: '400', fontSize: '12px', cursor: 'pointer' }}>Détails</div>, Actions: <div style={{ display: 'flex', gap: '12px' }}><Button label="Approuver" size={"sm"} color={"success"} styling={"outline"} args={{}} /><Button label="Supprimer" size={"sm"} color={"error"} styling={"outline"} args={{}} /></div>
        },
        { Demandeur: <div ><p style={{ color: '#334155', fontFamily: 'Inter', fontWeight: '500', fontSize: '13px' }}>bendoud.youness@karaz.org</p></div>, Access: <div style={{ color: '#2563EB', fontFamily: 'Inter', fontWeight: '400', fontSize: '12px', cursor: 'pointer' }}>Détails</div>, Actions: <div style={{ display: 'flex', gap: '12px' }}><Button label="Approuver" size={"sm"} color={"success"} styling={"outline"} args={{}} /><Button label="Supprimer" size={"sm"} color={"error"} styling={"outline"} args={{}} /></div> }
    ];
    const columns = [
        { key: 'Demandeur', header: 'Demandeur' },
        { key: 'Access', header: 'Détails des droits d’accès' },
        { key: 'Actions', header: 'Actions' },
    ];


    return (<div className="access-control">
        <div className="access-control-main">
            <div className="access-control-main-header">
                <SettingsContainerHeader title="Contrôles d’accès" subtitle="Gérez vos accès à l’application" />
            </div>
            <div className="access-control-main-content">
                <div style={{ display: 'flex', gap: '8px' }}>
                    <ToggleButton handleToggle={handleactiveone} ischecked={isactiveOne} />
                    <p style={{ margin: '0px', color: '#1E293B', fontFamily: 'Inter', fontWeight: '400', fontSize: '12px' }}>Autoriser les demandes d’accès à l’application par les utilisateurs de l’organisation.</p>

                </div>
                <div style={{ display: 'flex', gap: '8px' }}>
                    <ToggleButton handleToggle={handleactiveTwo} ischecked={isactiveTwo} />
                    <p style={{ margin: '0px', color: '#1E293B', fontFamily: 'Inter', fontWeight: '400', fontSize: '12px' }}>Notifier le Rootuser par une nouvelle demande d’autorisation d’accès.</p>

                </div>
            </div>

        </div>
        <div className="access-control-primary">
            <div className="access-control-primary-header">
                <SettingsContainerHeader title="Demandes d’accès" subtitle="Traitez les demandes d’accès à votre application" />
            </div>
            <div className="organisation-search">
                <div className="organisation-search-bar" style={{ width: "100%" }}>
                    <Input
                        args={{
                            value: searchQuery,
                            id: "search",
                            type: "text",
                            placeholder: 'Rechercher',
                            onChange: handleSearchChange,
                        }}
                        infoText=""
                        label=""
                        size="md"
                    />
                </div>
                <div className="iconsSection-org">
                    <img className="clearSearch" src="/photos/close.svg" alt="clear" onClick={handleClearSearch}></img>
                    <img className="searchIcon" src="/icons/search.svg" alt="search" onClick={() => { console.log(searchQuery) }} ></img>
                </div>
            </div>
            <div>
                <GenericTable data={data} columns={columns} />
            </div>

        </div>

    </div>);
}