import "./home-page.css";
import { NewModelWizard } from "../module/new-model-wizard"
import { CourseDetails } from "../courses/course-details"
import MyAppsPage from "./my-apps"
import { MyOrganization } from "kls-catalog";


export function Home(props: any) {
    const mode = window.localStorage.getItem('mode')
    return <div className={`home-page-container ${mode === 'dark' ? 'bg-light-dark text-dark' : ''}`}>

        <MyOrganization gs={props.gs} hintType={props.hintType} Type={'homePage'}></MyOrganization>
        <div className="organizationSec">
            <MyAppsPage gs={props.gs} />
        </div>
        <CourseDetails />
    </div>
}


function creationWizard() {
    return (onOk: Function, onClose: Function) => <NewModelWizard onClose={onClose} />
}