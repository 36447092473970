import { AdvancedDropDown, Button, Input, Modal, SearchInput, ToggleButton } from "kls-ui";
import { SettingsContainerHeader } from "../../../settings/settings";
import { useEffect, useState } from "react";
import './import-export.css'
import { GenericTable, ModuleImportsTable } from "kls-ui/table/table";
import { Pagination } from "kls-ui/paginationKls/pagination";

export function ImportExport() {
    const [newImportExport, setnewImportExport] = useState(false)
    const [newImportDropdown, setnewImportDropdown] = useState([{label: 'Lancer import', value: 'Lancer import', icon: 'upload', color: '#64748B'}, {label: 'Lancer export', value: 'Lancer export', icon: 'download', color: '#64748B'}])
    const [newImportValue, setnewImportValue] = useState('')
    const [filterValue, setfilterValue] = useState('')
    const [isFilter, setisFilter] = useState(false)
    const [isImportFilter, setisImportFilter] = useState(false)
    const [importFilter, setimportFilter] = useState('')
    const [importFilterDropdown, setimportFilterDropdown] = useState([{label: 'Tous', value: 'Tous'}, {label: 'Importation', value: 'Importation'}, {label: 'Exportation', value: 'Exportation'}])
    const [isTypeFilter, setisTypeFilter] = useState(false)
    const [typeFilter, settypeFilter] = useState('')
    const [typeFilterDropdown, settypeFilterDropdown] = useState([{label: 'Tous', value: 'Tous'}, {label: 'Rôles', value: 'Rôles'}, {label: 'Rôles - Ressources', value: 'Rôles - Ressources'}, {label: 'Rôles - Profils', value: 'Rôles - Profils'}, {label: 'Liste - Valeurs', value: 'Liste - Valeurs'}])
    const [isStatusFilter, setisStatusFilter] = useState(false)
    const [statusFilter, setstatusFilter] = useState('')
    const [statusFilterDropdown, setstatusFilterDropdown] = useState([{label: 'Tous', value: 'Tous'}, {label: 'Brouillon', value: 'Brouillon'}, {label: 'Terminé', value: 'Terminé'}])
    const [importExportData, setimportExportData] = useState<any>([])
    const [importExportDataDefault, setimportExportDataDefault] = useState<any>([])
    const [createImportExport, setcreateImportExport] = useState('')
    const [isCreateModal, setisCreateModal] = useState(false)
    const [creatType, setcreateType] = useState('')
    const [createMaxItems, setcreateMaxItems] = useState('0')
    const [viewImportData, setviewImportData] = useState<any>([])
    const [viewData, setveiwData] = useState('')
    const [currentPage, setcurrentPage] = useState(1)
    const [currentViewPage, setcurrentViewPage] = useState(1)
    const itemsPerPage = 5
    const startIndex = (currentPage - 1) * itemsPerPage
    const startViewIndex = (currentViewPage - 1) * itemsPerPage
    const endIndex = Math.min(startIndex + itemsPerPage, importExportData.length)
    const endViewIndex = Math.min(startViewIndex + itemsPerPage, viewImportData.length)
    const [viewActivation, setviewActivation] = useState(false)
    const [viewImports, setviewImports] = useState<any>()
    const handlePageChange = (start, end) => {
        const newCurrentPage = Math.floor(start / itemsPerPage) + 1
        setcurrentPage(newCurrentPage)
    }
    const handleViewPageChange = (start, end) => {
        const newCurrentPage = Math.floor(start / itemsPerPage) + 1
        setcurrentViewPage(newCurrentPage)
    }
    const handleCreateClose = () => {
        setcreateImportExport('')
        setcreateType('')
        setcreateMaxItems('')
        setnewImportValue('')
    }
    useEffect(() => {
        let data = [
            {'id': '0018', 'import': 'Importation', 'type': 'Rôles', 'message': '', 'date': '12/09/2024 15:48', 'status': 'Brouillon', 'maxItems': '100'},
            {'id': '0034', 'import': 'Exportation', 'type': 'Rôles - Ressources', 'message': 'Export bien exécuté', 'date': '12/09/2024 15:48', 'status': 'Terminé', 'maxItems': '90'},
            {'id': '0193', 'import': 'Importation', 'type': 'Rôles - Profils', 'message': 'Erreur d’importation', 'date': '12/09/2024 15:48', 'status': 'Terminé', 'maxItems': '80'},
            {'id': '0004', 'import': 'Importation', 'type': 'Listes - Valeurs', 'message': 'Export bien exécuté', 'date': '12/09/2024 15:48', 'status': 'Terminé', 'maxItems': '70'},
            {'id': '0001', 'import': 'Exportation', 'type': 'Rôles', 'message': '', 'date': '12/09/2024 15:48', 'status': 'Brouillon', 'maxItems': '60'},
            {'id': '0019', 'import': 'Importation', 'type': 'Rôles', 'message': '', 'date': '12/09/2024 15:48', 'status': 'Brouillon', 'maxItems': '50'},
            {'id': '0035', 'import': 'Exportation', 'type': 'Rôles - Ressources', 'message': 'Export bien exécuté', 'date': '12/09/2024 15:48', 'status': 'Terminé', 'maxItems': '40'},
            {'id': '0194', 'import': 'Importation', 'type': 'Rôles - Profils', 'message': 'Erreur d’importation', 'date': '12/09/2024 15:48', 'status': 'Terminé', 'maxItems': '30'},
            {'id': '0005', 'import': 'Importation', 'type': 'Listes - Valeurs', 'message': 'Export bien exécuté', 'date': '12/09/2024 15:48', 'status': 'Terminé', 'maxItems': '20'}
        ]
        let viewData = [
            {'order': '1', 'wording': 'Export_Roles_Ressources (1)', 'date': '14/09/2024 12:58'},
            {'order': '2', 'wording': 'Export_Roles_Ressources (2)', 'date': '14/09/2024 12:58'},
            {'order': '3', 'wording': 'Export_Roles_Ressources (3)', 'date': '14/09/2024 12:58'},
            {'order': '4', 'wording': 'Export_Roles_Ressources (4)', 'date': '14/09/2024 12:58'},
            {'order': '5', 'wording': 'Export_Roles_Ressources (5)', 'date': '14/09/2024 12:58'},
            {'order': '6', 'wording': 'Export_Roles_Ressources (6)', 'date': '14/09/2024 12:58'},
            {'order': '7', 'wording': 'Export_Roles_Ressources (7)', 'date': '14/09/2024 12:58'},
            {'order': '8', 'wording': 'Export_Roles_Ressources (8)', 'date': '14/09/2024 12:58'},
        ]
        setimportExportData(data)
        setimportExportDataDefault(data)
        setviewImportData(viewData)
    },[])
    const onFilter = async (value, typer) => {
        let searchData = importExportDataDefault
        let imp = importFilter;
        let type = typeFilter;
        let status = statusFilter;
        if(typer === 'import') {
            imp = value;
        }
        else if(typer === 'type') {
            type = value;
        }
        else if(typer === 'status') {
            status = value;
        }
        let filterResult;
        if((imp === '' &&  type === '' && status === '') || imp === 'Tous' &&  type === 'Tous' && status === 'Tous') {
            setimportExportData(importExportDataDefault)
        }
        else {
            filterResult = searchData.filter((c) => {
                console.log(c, imp, type, status)
                if(((c.import === imp || imp === 'Tous') && type === '' && status === '') || 
                    (imp === '' && (c.type === type || type === 'Tous') && status === '') ||
                    (imp === '' && type === '' && (c.status === status || status === 'Tous')) || 
                    ((c.import === imp || imp === 'Tous') && (c.type === type || type === 'Tous') && status === '') || 
                    ((c.import === imp || imp === 'Tous') && type === '' && (c.status === status || status === 'Tous')) || 
                    (imp === '' && (c.type === type || type === 'Tous') && (c.status === status || status === 'Tous')) || 
                    ((c.import === imp || imp === 'Tous') && (c.type === type || type === 'Tous') && (c.status === status || status === 'Tous'))
                ) {
                    return c
                }
            })
        }
        if(filterResult !== undefined) {
            setimportExportData(await Promise.all(filterResult))
        }
    }
    const TableData = importExportData.map((c) => {
        return {
            id: <span style={{fontSize: '12px'}}>{c.id}</span>,
            import: <span className="import-export-table-import" style={c.import === 'Importation' ? {color: '#6D28D9', borderColor: '#A78BFA', backgroundColor: '#F5F3FF', fontSize: '12px'} : c.import === 'Exportation' ? {color: '#A16207', borderColor: '#FACC15', backgroundColor: '#FEFCE8', fontSize: '12px'} : {color: 'black', borderColor: 'black', backgroundColor: 'white', fontSize: '12px'}}>{c.import}</span>, 
            type: <span style={{fontSize: '12px'}}>{c.type}</span>,
            message: c.message === ''  || c.message === 'N/A' ? <span style={{color: '#94A3B8', fontSize: '12px'}}>N/A</span> : <span>{c.message}</span>,
            date: <span style={{color: '#94A3B8', fontSize: '12px'}}>{c.date}</span>,
            status: <span style={{fontSize: '12px'}}>{c.status}</span>,
            icon: <span className="icon import-export-table-view" onClick={() => {setveiwData(c.id); setviewImports(importExportData.filter((d) => d.id === c.id )[0])}}>visibility</span>
        }
    })
    const viewTableData = viewImportData.map((c) => {
        return {
            order: <span style={{fontSize: '12px'}}>{c.order}</span>,
            wording: <span style={{fontSize: '12px'}}>{c.wording}</span>,
            date: <span style={{fontSize: '12px'}}>{c.date}</span>,
            icon: <span className="icon view-table-download">download</span>
        }
    })
    const viewHeader = [
        { key: 'order', header: 'Ordre' },
        { key: 'wording', header: 'libellé' },
        { key: 'date', header: 'Date' },
        { key: 'icon', header: '' },
    ]
    const pagedData = TableData.slice(startIndex, endIndex)
    const pagedViewData = viewTableData.slice(startViewIndex, endViewIndex)
    return(
        <div>
            {viewData === '' ? <div className="import-export-main">
                <div className="import-export-header">
                    <SettingsContainerHeader title="Importation/Exportation" subtitle="Importation/Exportation des éléments de votre module" enableAction buttonName="Nouvel import/export" rightIcon="expand_more" onClick={() => setnewImportExport(!newImportExport)} />
                    {newImportExport&& <AdvancedDropDown
                        data={newImportDropdown}
                        light
                        size={3}
                        enableSearch={false}
                        handleChange={(value) => { setnewImportValue(value.label); setcreateImportExport(value.icon) }}
                        selectedValue={newImportValue}
                        style={{ top: '40px', width: '197px', right: '0px', position: 'absolute' }}
                        closeWhenSelect={() => { setnewImportExport(false) }}
                        closeWhenClickOutside={() => { setnewImportExport(false) }}

                    />}
                </div>
                <div className="import-export-filter-content">
                    <div className="import-export-search-filter">
                        <div className="import-export-search"><SearchInput light value={filterValue} setValue={setfilterValue} size={3} placeholder="Rechercher" addClearbutton style={{height: '40px', borderRadius: '8px'}} /></div>
                        <div className="import-export-filters" onClick={() => setisFilter(!isFilter)} style={{color: isFilter ? '#2563EB' : 'black'}}>
                            <span>Filtres</span>
                            <span className="icon">filter_list</span>
                        </div>
                    </div>
                    {isFilter&& 
                        <div className="import-export-filter-dropdown">
                            <div className="import-export-filter-value">
                                <h1 className="import-export-filter-header">Import/Export</h1>
                                <div className='import-export-filter-container'>
                                    <div className='import-export-filter-content-primary-div' onClick={() => setisImportFilter(!isImportFilter)}>
                                    {importFilter === '' ? <span style={{ color: '#94A3B8' }}>Import/Export</span> : importFilter}
                                    </div>
                                    {isImportFilter &&
                                    <AdvancedDropDown
                                        data={importFilterDropdown}
                                        light
                                        size={3}
                                        enableSearch={false}
                                        handleChange={(value) => { setimportFilter(value.label); onFilter(value.label, 'import') }}
                                        selectedValue={importFilter}
                                        style={{ top: '50px', width: '100%', left: '0px', position: 'absolute' }}
                                        closeWhenSelect={() => { setisImportFilter(false) }}
                                        closeWhenClickOutside={() => { setisImportFilter(false) }}

                                    />}
                                    <div className='import-export-input-filter-icons'>
                                    <span className='icon' onClick={() => {setimportFilter(''); onFilter('', 'import') }} style={{ cursor: 'pointer', color: '#94A3B8' }}>close</span>
                                    <span className="icon" style={{ cursor: 'pointer', color: '#94A3B8' }} onClick={() => setisImportFilter(!isImportFilter)}>expand_more</span>
                                    </div>
                                </div>
                            </div>
                            <div className="import-export-filter-value">
                                <h1 className="import-export-filter-header">Type</h1>
                                <div className='import-export-filter-container'>
                                    <div className='import-export-filter-content-primary-div' onClick={() => setisTypeFilter(!isTypeFilter)}>
                                    {typeFilter === '' ? <span style={{ color: '#94A3B8' }}>Type</span> : typeFilter}
                                    </div>
                                    {isTypeFilter &&
                                    <AdvancedDropDown
                                        data={typeFilterDropdown}
                                        light
                                        size={3}
                                        enableSearch={false}
                                        handleChange={(value) => { settypeFilter(value.label); onFilter(value.label, 'type') }}
                                        selectedValue={typeFilter}
                                        style={{ top: '50px', width: '100%', left: '0px', position: 'absolute' }}
                                        closeWhenSelect={() => { setisTypeFilter(false) }}
                                        closeWhenClickOutside={() => { setisTypeFilter(false) }}

                                    />}
                                    <div className='import-export-input-filter-icons'>
                                        <span className='icon' onClick={() => {settypeFilter(''); onFilter('', 'type') }} style={{ cursor: 'pointer', color: '#94A3B8' }}>close</span>
                                        <span className="icon" style={{ cursor: 'pointer', color: '#94A3B8' }} onClick={() => setisTypeFilter(!isTypeFilter)}>expand_more</span>
                                    </div>
                                </div>
                            </div>
                            <div className="import-export-filter-value">
                                <h1 className="import-export-filter-header">Statut</h1>
                                <div className='import-export-filter-container'>
                                    <div className='import-export-filter-content-primary-div' onClick={() => setisStatusFilter(!isStatusFilter)}>
                                    {statusFilter === '' ? <span style={{ color: '#94A3B8' }}>Statut</span> : statusFilter}
                                    </div>
                                    {isStatusFilter &&
                                    <AdvancedDropDown
                                        data={statusFilterDropdown}
                                        light
                                        size={3}
                                        enableSearch={false}
                                        handleChange={(value) => { setstatusFilter(value.label); onFilter(value.label, 'status') }}
                                        selectedValue={statusFilter}
                                        style={{ top: '50px', width: '100%', left: '0px', position: 'absolute' }}
                                        closeWhenSelect={() => { setisStatusFilter(false) }}
                                        closeWhenClickOutside={() => { setisStatusFilter(false) }}

                                    />}
                                    <div className='import-export-input-filter-icons'>
                                    <span className='icon' onClick={() => {setstatusFilter(''); onFilter('', 'status') }} style={{ cursor: 'pointer', color: '#94A3B8' }}>close</span>
                                    <span className="icon" style={{ cursor: 'pointer', color: '#94A3B8' }} onClick={() => setisStatusFilter(!isStatusFilter)}>expand_more</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {importExportData.length === 0 ? <div className="import-export-data"><div style={{color: '#94A3B8', fontSize: '13px', textAlign: 'center'}}>Aucun résultat</div></div> : <ModuleImportsTable data={pagedData} />}
                {TableData.length > 5 && <Pagination itemsPerPage={5} totalItems={importExportData.length} light maxPage={Math.ceil(importExportData.length / 5)} handlePageChange={handlePageChange} />}
                {createImportExport !== '' && <Modal
                    icon=""
                    leftBtnArgs={{
                    args: { onClick: handleCreateClose },
                    color: 'neutral',
                    label: 'Annuler',
                    size: 'sm',
                    styling: 'outline'
                    }}
                    rightBtnArgs={{
                    args: { onClick: () => console.log("submit") },
                    color: 'primary',
                    label: 'Ajouter',
                    size: 'sm',
                    styling: 'solid',
        
                    }}
                    title={createImportExport === 'upload' ? "Nouvel import" : createImportExport === 'download' ? 'Nouvel export' : ''}
                    onClose={handleCreateClose}
                    bodyStyle={{ padding: '24px 16px' }} >
                        <div className="import-export-create-content">
                            <div className="import-export-filter-value-type">
                                <h1 className="import-export-filter-header">Type</h1>
                                <div className='import-export-filter-container'>
                                    <div className='import-export-filter-content-primary-div' onClick={() => setisCreateModal(!isCreateModal)}>
                                    {creatType === '' ? <span style={{ color: '#94A3B8' }}>Type</span> : <span style={{ color: 'black' }}>{creatType}</span>}
                                    </div>
                                    {
                                    <AdvancedDropDown
                                        data={typeFilterDropdown}
                                        light
                                        size={3}
                                        enableSearch={false}
                                        handleChange={(value) => { setcreateType(value.label) }}
                                        selectedValue={creatType}
                                        style={{ top: '50px', width: '100%', left: '0px', position: 'absolute' }}
                                        closeWhenSelect={() => { setisCreateModal(false) }}
                                        closeWhenClickOutside={() => { setisCreateModal(false) }}

                                    />}
                                    <div className='import-export-input-filter-icons'>
                                    <span className='icon' onClick={() => {setcreateType('') }} style={{ cursor: 'pointer', color: '#94A3B8' }}>close</span>
                                    <span className="icon" style={{ cursor: 'pointer', color: '#94A3B8' }} onClick={() => setisCreateModal(!isCreateModal)}>expand_more</span>
                                    </div>
                                </div>
                            </div>
                            {createImportExport === 'download' && <Input size="md" label="Nombre d’éléments maximal par feuille" errorMessage="" args={{placeholder: "Nombre d’éléments maximal par feuille", type: 'number', value: createMaxItems, onChange: (e) => setcreateMaxItems(e.target.value)}} containerStyle={{borderRadius: '8px'}} />}
                        </div>
                </Modal>}
            </div> : <div className="import-export-main">
                <div className="import-export-viewdata">
                    <span>{viewImports.import}</span><span className="import-export-viewdata-id">{viewData}</span>
                </div>
                <div className="import-export-view-inputs">
                    <div className="import-export-view-toggle"><ToggleButton label="Activation" ischecked={viewActivation} handleToggle={() => setviewActivation(!viewActivation)} /></div>
                    <div className="import-export-view-types">
                        <div className="import-export-view-values">
                            <div>Type</div>
                            <div className="import-export-view-input-values">{viewImports.type}</div>
                        </div>
                        <div className="import-export-view-values">
                            <div>Nombre d’éléments maximal par feuille</div>
                            <div className="import-export-view-input-values">{viewImports.maxItems}</div>
                        </div>
                    </div>
                    <div className="import-export-view-types">
                        <div className="import-export-view-values">
                            <div>Date lancement</div>
                            <div className="import-export-view-input-values">{viewImports.date}</div>
                        </div>
                        <div className="import-export-view-values">
                            <div>Message</div>
                            <div className="import-export-view-input-values">{viewImports.message === '' ? 'N/A' : viewImports.message}</div>
                        </div>
                    </div>
                </div>
                {importExportData.length === 0 ? <div className="import-export-data"><div style={{color: '#94A3B8', fontSize: '13px', textAlign: 'center'}}>Aucun résultat</div></div> : <div className="import-export-view-data-table"><GenericTable data={pagedViewData} columns={viewHeader} /></div>}
                {viewTableData.length > 5 && <Pagination itemsPerPage={5} totalItems={viewTableData.length} light maxPage={Math.ceil(viewTableData.length / 5)} handlePageChange={handleViewPageChange} />}
            </div>}
        </div>
    )
}