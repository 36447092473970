import { AdvancedDropDown, Button, CheckboxLabel, Input, Modal, TextArea, ToggleButton } from "kls-ui";
import { SettingsContainerHeader } from "../settings/settings";
import { useState } from "react";
import './shareOnCatalog.css';

export function ShareOnCatalog() {
    const [toggle, setToggle] = useState(false);
    const [toggleModelOne, setToggleModelOne] = useState(false);
    const [toggleModelTwo, setToggleModelTwo] = useState(false);
    const [toggleModelThree, setToggleModelThree] = useState(false);
    const [shareStatus, setShareStatus] = useState('Non partagée');
    const [checkOne, setCheckOne] = useState(false)
    const [checkTwo, setCheckTwo] = useState(false)
    const [checkThree, setCheckThree] = useState(false)

    return (
        <div className="app-shareOn-container">
            <SettingsContainerHeader title="Partage sur catalogue" subtitle="Partager votre application sur le catalogue public." />
            <div className="app-shareOn-toggleBtn">
                <div className="app-shareOn-notShareContainer">
                    <p className="app-shareOn-notShare">{shareStatus}</p>
                    <span className="app-shareOn-redCircle"></span>
                </div>
                <ToggleButton label="Afficher sur catalogue" ischecked={toggle} handleToggle={() => { setToggle(!toggle) }} className="app-share-toggleFont" />
                {
                    toggle == true &&
                    <Modal onClose={() => setToggle(false)} title="Partage d'application sur catalogue " icon={""}>
                        <div style={{ padding: '24px 16px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                           {
                            (toggleModelOne == false && toggleModelTwo == false && toggleModelThree == false) && <div style={{ padding: '8px 16px',display:'flex',alignItems:'center',gap:'8px',justifyContent:'center'}}>
                                <img src="./icons/warning_icon.svg" alt="no-img" />
                                <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '13px', color: '#1E293B', fontWeight: '500' }}>Veuillez <b>définir une version</b> par défaut.</p>
                            </div>}
                            <div className="actual-status-model">
                                <h1 className="">Statut actuel</h1>
                                <div>
                                    <div className="actual-status-model-div">
                                        <span className="app-shareOn-redCircle" style={{ display: 'block' }}></span>
                                        <span style={{ margin: '0', fontFamily: 'Inter', fontSize: '13px', color: '#1E293B', fontWeight: '500' }}>{shareStatus}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: '1px', backgroundColor: '#E2E8F0', borderRadius: '100px' }}></div>
                            <div className="actual-status-model-version-list">Choisissez la liste des versions à partager :</div>
                            <div style={{ display: 'flex' }} >
                                <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '13px', color: '#1E293B', fontWeight: '500', width: '50%' }}>Version</p>
                                <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '13px', color: '#1E293B', fontWeight: '500', width: '50%' }}>Par défaut</p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div style={{ display: 'flex', gap: '8px', alignItems: 'center', width: '50%' }}>
                                    <CheckboxLabel label={''} handleChangeValue={setCheckOne} Check={checkOne} />
                                    <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '13px', color: '#1E293B', fontWeight: '400' }}>V 0.1.2</p>
                                </div>
                                <div>
                                    {
                                        checkOne &&
                                        <ToggleButton ischecked={toggleModelOne} handleToggle={() => { setToggleModelOne(!toggleModelOne) }} className="app-share-toggleFont" />
                                    }
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div style={{ display: 'flex', gap: '8px', alignItems: 'center', width: '50%' }}>
                                    <CheckboxLabel label={''} handleChangeValue={setCheckTwo} Check={checkTwo} />
                                    <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '13px', color: '#1E293B', fontWeight: '400' }}>V 0.1.2</p>
                                </div>
                                <div>
                                    {
                                        checkTwo &&
                                        <ToggleButton ischecked={toggleModelTwo} handleToggle={() => { setToggleModelTwo(!toggleModelTwo) }} className="app-share-toggleFont" />
                                    }
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div style={{ display: 'flex', gap: '8px', alignItems: 'center', width: '50%' }}>
                                    <CheckboxLabel label={''} handleChangeValue={setCheckThree} Check={checkThree} />
                                    <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '13px', color: '#1E293B', fontWeight: '400' }}>V 0.1.2</p>
                                </div>
                                <div>
                                    {
                                        checkThree &&
                                        <ToggleButton ischecked={toggleModelThree} handleToggle={() => { setToggleModelThree(!toggleModelThree) }} className="app-share-toggleFont" />
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: '12px 16px', backgroundColor: '#F8FAFC', borderTop: '#E2E8F0 1px solid', display: 'flex', justifyContent: 'space-between',borderRadius:'0 0 20px 20px' }}>
                            <div>
                                <Button size={"md"} color={'neutral'} styling={"solid"} label="Annuler" args={{ className: 'shareOnCatalog-cancel-button-model',onClick:() => { setToggle(false) } }} />
                            </div>
                            <div>
                                <Button size={"md"} color={'primary'} styling={"solid"} label="Valider" args={{ onClick:()=>console.log('Model-Submit')}} />
                            </div>
                        </div>
                    </Modal>
                }
            </div>
            <div style={{ width: '100%', height: '18px' }}></div>
            <div className="app-shareOn-DocumentContainer">
                <div className="app-shareOn-ImageUploaderContainer">
                    <img src="./photos/upload-img.svg" alt="no-img" className="app-shareOn-uploadIcon" />
                    <Input label="Image afficher sur catalogue" size="md" args={{ placeholder: 'Attachez un document', type: 'file', style: { display: 'none' } }} />
                </div>
                <div>
                    <TextArea label="Description longue" size="md" args={{ placeholder: 'Entrez une description' }} />
                </div>
            </div>
            <div style={{ width: '100%', borderBottom: '1px solid #E2E8F0' }}></div>
            <div className="app-shareOn-functionalContainer shareOn-fun">
                <p style={{ margin: '0px', fontWeight: '500', fontSize: '15px' }}>Fonctionnalités</p>
                <p style={{ margin: '0px', fontWeight: '500', fontSize: '13px' }} className="app-shareOn-detailsContent">Voir détails<span className="icon">expand_more</span></p>
            </div>
            <div className="app-shareOn-functionalContainer shareOn-personal">
                <p style={{ margin: '0px', fontWeight: '500', fontSize: '15px' }}>Personas</p>
                <p style={{ margin: '0px', fontWeight: '500', fontSize: '13px' }} className="app-shareOn-detailsContent">Voir détails<span className="icon">expand_more</span></p>
            </div>
            <div className="app-shareOn-functionalContainer shareOn-capture">
                <p style={{ margin: '0px', fontWeight: '500', fontSize: '15px' }}>Captures d’écran</p>
                <p style={{ margin: '0px', fontWeight: '500', fontSize: '13px' }} className="app-shareOn-detailsContent">Voir détails<span className="icon">expand_more</span></p>
            </div>
            <div className="app-shareOn-buttonContainer">
                <Button size="md" label="Partager" leftIcon="share" color="primary" styling="outline" args={{}} />
            </div>
        </div>
    )
}