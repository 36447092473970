import { useEffect, useState } from "react";
import { SettingsContainerHeader } from "../settings";
import { NotificationsContent } from "../../App";
import './notifications.css'
import { Pagination } from "kls-ui/paginationKls/pagination";

export function NotificationSettings() {
    const [notificationsData, setnotificationsData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 6;
    const handlePageChange = (start: number, end: number) => {
        console.log(start, end, "roles")
        const newCurrentPage = Math.floor(start / itemsPerPage) + 1;
        setCurrentPage(newCurrentPage);
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, notificationsData.length);
    const totalPages = Math.ceil(notificationsData.length / itemsPerPage);

    const PagedNotifications = notificationsData && notificationsData.slice(startIndex, endIndex)
    console.log(currentPage, startIndex, endIndex, PagedNotifications, "notificationsData")
    useEffect(() => {
        const data = [
            {"image": "/photos/OrganizationLogo.png", "name": "Kamal Chaoui", "email": "@Imane.Lamani", "message": "Vous a ajouté à l’organisation", "represent": "Emdaa", "time": "1h"},
            {"image": "/photos/karaz.png", "name": "Hafsa Radi", "email": "@Imane.Lamani", "message": "a demandé de rejoindre l’organisation", "represent": "UM6", "time": "1h"},
            {"image": "/photos/ribatis_notifications_icon.svg", "name": "Ribatis", "email": "@Ribatis", "message": "Veuillez remplir le reste de vos informations.", "represent": "Mon compte", "time": "1h"},
            {"image": "/photos/ribatis_notifications_icon.svg", "name": "Ribatis", "email": "@Ribatis", "message": "Votre mot de passe à été modifié.", "represent": "Mon compte", "time": "1h"},
            {"image": "/photos/ribatis_notifications_icon.svg", "name": "Ribatis", "email": "@Ribatis", "message": "Votre mot de passe à été modifié.", "represent": "Mon compte", "time": "1h"},
            {"image": "/photos/ribatis_notifications_icon.svg", "name": "Ribatis", "email": "@Ribatis", "message": "Votre mot de passe à été modifié.", "represent": "Mon compte", "time": "1h"},
            {"image": "/photos/ribatis_notifications_icon.svg", "name": "Ribatis", "email": "@Ribatis", "message": "Veuillez remplir le reste de vos informations.", "represent": "Mon compte", "time": "1h"},
            {"image": "/photos/ribatis_notifications_icon.svg", "name": "Ribatis", "email": "@Ribatis", "message": "Votre mot de passe à été modifié.", "represent": "Mon compte", "time": "1h"},
            {"image": "/photos/ribatis_notifications_icon.svg", "name": "Ribatis", "email": "@Ribatis", "message": "Veuillez remplir le reste de vos informations.", "represent": "Mon compte", "time": "1h"},
            {"image": "/photos/ribatis_notifications_icon.svg", "name": "Ribatis", "email": "@Ribatis", "message": "Votre mot de passe à été modifié.", "represent": "Mon compte", "time": "1h"}
        ]
        setnotificationsData(data)
    },[])
    return(
        <div className="notifications-settings">
            <SettingsContainerHeader title="Notifications" subtitle="Consulter toutes les notifications de votre compte" />
            {PagedNotifications&& PagedNotifications.map((c) => {
                return <NotificationsContent image={c.image} name={c.name} email={c.email} message={c.message} represent={c.represent} time={c.time} style={{borderTop: '0px'}} />
            })}
            {PagedNotifications.length > 0 && <Pagination light={true} totalItems={notificationsData.length} itemsPerPage={itemsPerPage} maxPage={totalPages} handlePageChange={handlePageChange}/>}
        </div>
    )
}