import "./settingsHeader.css"
import { SettingsProfile } from "./settingsProfile";

interface SettingsProps {
    size?: 'md' | 'sm' | 'xs',
    label?: string;
    args?: React.InputHTMLAttributes<HTMLInputElement>;
    image: string;
    username: string;
    type?: string;
    data?: any;
}

export const SettingsHeader = (props: SettingsProps) => {
    const { size, label, args, image, username, type } = props;
    const mode = window.localStorage.getItem('mode')
    return (
        <div className="settings-header">
            <div className="settings-topic">
                <p className={`settings-title ${mode === 'dark' ? 'text-dark' : ''}`}>Paramètres</p>
            </div>
            <div>
                {type == 'settings' ? <SettingsProfile type="user" image={image} username={username} label="Compte personnel :" /> : <SettingsProfile  type="organisation" image={image} username={username} label="Organisation :" />}
            </div>
        </div>
    )
}