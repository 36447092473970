import { useEffect, useRef, useState } from "react";
import '../../app-params/param-app.css'
import React from 'react';
import { Input, MobileViewVerification, Modal, Tabs, addToast } from 'kls-ui';
import {ModalDelete } from 'kls-ui/modal/ModalDelete';
import { SelectTabs } from 'kls-ui/selectTabs/SelectTabs';
import TreeElementsModules from 'kls-ui/treeModules/TreeElementsModules';
import { cleanAndFormatNameResource } from "kls-commons/service/resource-service";
import { Model } from "kls-commons/types/application-model";
import { useParams } from "react-router-dom";
import { fetchKlsResource, getWorkingDirectorTree, listModules } from "kls-commons/service/repo-service";
import { WorkingDirTree } from "kls-commons/types/repo-model";
import { AdvancedDropDown } from "kls-ui/advancedDropDown/AdvancedDropDown";
import { GroupesTable } from 'kls-ui/table/table';
import {SearchInput}from "kls-ui/data entry/search-input";
import { Pagination } from "kls-ui/paginationKls/pagination";
import { currentUser} from "kls-commons/service/userService"; 
import localforage from "localforage";
import { SettingsContainerHeader } from "../../settings/settings";


interface TreeNode {
  id: number;
  name: string;
  isOpen: boolean;
  children: TreeNode[];
  checked?: boolean;
  display?: boolean;

}
export function Groups(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [displayNode, setDisplayNode] = useState(false);
  const [groupeName, setGroupeName] = useState('');
  const [helpText, setHelpText] = useState('');
  const [activeTab, setActiveTab] = useState<string>('tab1');
  const [activeTabEdit, setActiveTabEdit] = useState<string>('general');
  const { moduleName } = useParams() as any;
  const [appObj, setAppObj] = useState<WorkingDirTree>();
  const [direc, setDirec] = useState<string[]>([]);
  const [tr, setTr] = useState<TreeNode[]>([]);
  const [trRessource, setTrRessource] = useState<TreeNode[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 5;
  const [codeInputs, setCodeInputs] = useState<string[]>(["", "", "", "", "", ""]);
  const [allInputsFilled, setAllInputsFilled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorMessageValidation, setErrorMessageValidation] = useState<string>('');
  const [currentInputIndex, setCurrentInputIndex] = useState<number>(0);
  const [isInputModified, setIsInputModified] = useState(false);
  const [groupesData, setGroupesData] = useState<{ groupe: string; profils: number; ressources: number; utilisateurs: number; }[]>([]);
  const [groupeNameError, setGroupeNameError] = useState('');
  const [rolesData, setRolesData] = useState<TreeNode[]>([]);
  const [aboutData, setAboutData] = useState<any>({
    creationDate: '05/01/2024 22:15',
    lastModification: '10/01/2024 08:44',
    numberOfUsers: 15
  });

  
  const [treeData, setTreeData] = useState<TreeNode[]>([
    {
      id: 1,
      name: 'Module : Gestion des demandes de projets',
      isOpen: false,
      checked: false,
      children: [
        {
          id: 2,
          name: 'Processus : Demande de projet',
          isOpen: false,
          checked: false,
          children: [
            {
              id: 3,
              name: 'Initiation de la Demande',
              isOpen: false,
              checked: false,
              children: []
            },
            {
              id: 4,
              name: 'Évaluation Initiale',
              isOpen: false,
              checked: false,
              children: []
            },
            {
              id: 5,
              name: 'Vérification Budgétaire',
              isOpen: false,
              checked: false,
              children: []
            },
            {
              id: 6,
              name: 'Examen Juridique',
              isOpen: false,
              checked: false,
              children: []
            },
            {
              id: 7,
              name: 'Préparation de la Proposition',
              isOpen: false,
              checked: false,
              children: []
            }
          ]
        },
        {
          id: 8,
          name: 'Processus : Correction de projet',
          isOpen: false,
          checked: false,
          children: [
            {
              id: 14,
              name: 'Initiation de la Demande',
              isOpen: false,
              checked: false,
              children: []
            },
          ]
        },
        {
          id: 9,
          name: 'Processus : Annulation de projet',
          isOpen: false,
          checked: false,
          children: [
            {
              id: 10,
              name: 'Initiation de la Demande',
              isOpen: false,
              checked: false,
              children: []
            },
            {
              id: 11,
              name: 'Examen du comité',
              isOpen: false,
              checked: false,
              children: []
            }
          ]
        }
      ]
    },
    {
      id: 12,
      name: 'Gestion des ventes',
      isOpen: false,
      checked: false,
      children: [
        {
          id: 13,
          name: 'Processus : Demande de vente',
          isOpen: false,
          checked: false,
          children: []
        }
      ]
    }
  ]);
  const [treeDataResource, setTreeDataResource] = useState<TreeNode[]>([
    {
      id: 1,
      name: 'Module : Gestion des demandes de projets',
      isOpen: false,
      children: [
        {
          id: 2,
          name: 'Processus',
          isOpen: false,
          children: [
            {
              id: 3,
              name: 'Demande de projet',
              isOpen: false,
              children: []
            },
          ]
        },
        {
          id: 4,
          name: 'Tasklist',
          isOpen: false,
          children: [

            {
              id: 5,
              name: 'Initiation de la Demande',
              isOpen: false,
              children: []
            },
            {
              id: 6,
              name: 'Vérification Budgétaire',
              isOpen: false,
              children: []
            },

          ]
        },
        {
          id: 7,
          name: 'Recherches',
          isOpen: false,
          children: [
            {
              id: 8,
              name: 'Liste de toutes les demandes reçues',
              isOpen: false,
              children: []
            },
            {
              id: 9,
              name: 'Liste des demandes reçues - Statut = Urgent',
              isOpen: false,
              children: []
            }
          ]
        }
      ]
    },

  ]);
  const[rolesStatiqueData,setRolesStatiqueData]= useState<TreeNode[]>([
    {
      id: 1,
      name: 'Application : Gestion des demandes de projets',
      isOpen: false,
      children: [
        {
          id: 2,
          name: 'Demandeur de projet ',
          isOpen: false,
          children: []
        },
        {
          id: 3,
          name: 'Controleur de gestion',
          isOpen: false,
          children: [ ]
        },
        {
          id: 4,
          name: 'Gestionnaire des opérations',
          isOpen: false,
          children: [
          ]
        },
        {
          id: 5,
          name: 'Auditeur de conformité',
          isOpen: false,
          children: [
          ]
        }
      ]
    },
  ]);
 
  const [groupeDataStatique,setGroupeDataStatique]=useState<any[]>([
    {
      groupe: 'Group-1',
      profils: 1,
      ressources: 7,
      utilisateurs: 12
  },
  {
      groupe: 'Group-2',
      profils: 1,
      ressources: 7,
      utilisateurs: 12
  },
  
  {
      groupe: 'Group-3',
      profils: 1,
      ressources: 7,
      utilisateurs: 12
  }
  ]);
const filteredGroupeData = groupeDataStatique.filter(g =>
g&&g.groupe.toLowerCase().includes(filter.toLowerCase())
);

  useEffect(() => {
    const cleanedAndFormattedName = cleanAndFormatNameResource(groupeName);
    setHelpText(`ID : ${cleanedAndFormattedName}`);
  }, [groupeName]);

 
  useEffect(() => {
    getWorkingDirectorTree(moduleName).then(
      (tree: WorkingDirTree) => {
        setAppObj(tree);
      }
    )
    listModules().then(
      modules => {
        setDirec(modules);
      }
    )

  }, [moduleName])

  useEffect(() => {
    console.log("appObjet structure", appObj);

  }, [appObj])

  useEffect(() => {
    setAllInputsFilled(codeInputs.every(input => input !== ''));
  }, [codeInputs]);
  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);
  const handleSearchInputChange = (value: string) => {
    setFilter(value);
  };
  const handlePageChange = (start: number, end: number) => {
    setCurrentPage(start / itemsPerPage + 1);
  };

  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const fetchData = async () => {
      try {
    const processModulesChildren = (modulesChildren: any) => {
      const tree: TreeNode[] = [];
      let moduleIdCounter = 1;
      // console.log("modulesChildreLog", modulesChildren)
      for (const m in modulesChildren) {
        if (!m.endsWith(".json")) {
          const modeleName = m.split(".")[0];
          const moduleChildren = modulesChildren[m]?.children;
          const moduleNode: TreeNode = {
            id: moduleIdCounter++,
            name: `Module : ${modeleName}`,
            isOpen: false,
            children: [],
          };
          const processChildren = moduleChildren?.process?.children;
          // console.log("ProcessLOG", processChildren);
          for (const c in processChildren) {
            if (c.endsWith(".bpmn")) {
              const processName = c.split(".")[0];
              const processNode: TreeNode = {
                id: moduleIdCounter++,
                name: `Processus : ${processName}`,
                isOpen: false,
                children: [],
              };
              moduleNode.children.push(processNode);
              let m:any[]=[];

              // listAvailableResourceQN(moduleName, 'launchProcess').then(
              //   (resources) => {
              //      console.log("resource hahahhah", resources);
              //   }
              // )
             
              // console.log("processName heeeere", processName);

              let activities: any[] = [];
              const processFilePath = appObj?.children?.[modeleName].children?.process?.children?.[`${processName}.bpmn`]?.info?.filePath ?? "";
              // console.log("processFilePath ", modeleName, processFilePath);
              fetchKlsResource(moduleName, processFilePath)
                .then((resource) => {
                 // console.log("resource tttttt for", resource, "Process", processName);
                  activities = getActivitiesFromXml(resource.content ?? "");
                  // console.log("resresres",resource.content);
                  //console.log("activities ggg", activities);
                  // console.log(`Activities for process work ${processName}:`, activities);
                  for (const activity of Object.values(activities)) {
                    // console.log(`Activity afficher : ${activity.name}`);
                    processNode.children.push({
                      id: moduleIdCounter++,
                      name: `Activite : ${activity.name}`,
                      isOpen: false,
                      children: [],
                    });
                  }
                })
                .catch((error) => {
                  console.error(`Error fetching activities for process ${processName}:`, error);
                });
            }
          }

          if (moduleNode.children.length > 0) {
            tree.push(moduleNode);
          }
        }
      }
      return tree;
    };

    const treeDaaBack = processModulesChildren(appObj?.children);
    setTr(treeDaaBack);
    console.log("treeDaaBack", treeDaaBack);

    let moduleIdCounters = 1;

    const processModulesChildrenRessources = async (modulesChildrenRessources: any) => {
        const treeRessource: TreeNode[] = [];
    
        for (const moduleName in modulesChildrenRessources) {
            if (!moduleName.endsWith(".json")) {
                const moduleChildren = modulesChildrenRessources[moduleName]?.children;
                // console.log("moduleChildLoggaga", moduleChildren);
                const menuNodes: TreeNode[] = [];
                const resourcesNodes:Set <string> = new Set();
                await processMenus(moduleChildren, menuNodes,resourcesNodes, new Set<string>());
                treeRessource.push(...menuNodes);
            }
        }
    
        return treeRessource;
    };
    const processMenus = async (menus: any, menuNodes: TreeNode[], processedMenuIds: Set<string>, processResourcesIds: Set<string>, parentMenu?: TreeNode) => {
        if (!menus) return;
        for (const menuName in menus) {
            if (menuName.endsWith("-menu.xml")) {
                const menuFilePath = menus[menuName]?.info?.filePath;
                // console.log("menuFilePath ", menuFilePath);
                try {
                    const resource = await fetchKlsResource(moduleName, menuFilePath);
                    // console.log(`content for menu ${menuName}:`, resource);
                    const xmlContent = resource?.content ?? '';
                    // console.log(`xmlContent for menu ${menuName}:`, xmlContent);

                    const parsedMenus = parseXmlMenu(xmlContent);
                    // console.log(`Menus for menu ${menuName}:`, parsedMenus);
                    for (const menu of parsedMenus) {
                        const menuNode = await buildMenuTree(menu, processedMenuIds,processResourcesIds);
                        if (menuNode)
                        {
                          // console.log("menuNode", menuNode)
                          menuNodes.push(menuNode);
                          
                        }       
                    }
                } catch (error) {
                    console.error(`Error fetching resources for menu ${menuName}:`, error);
                }
            }
        }
    };
   
    const buildMenuTree = async (menu: any, processedMenuIds: Set<string>, processResourcesIds : Set<string>, parentMenu?: TreeNode): Promise<TreeNode | null> => {
    // if (processedMenuIds.has(menu.id)) {
    //     return null;
    // }

        if (processedMenuIds.has(menu.label)) {
          for (const resource of menu.resources) {
            //console.log("has resource", processResourcesIds.has(resource.content));
            if(processResourcesIds.has(resource.content)){
                 return null;
            }
          }  
         }

    const menuNode: TreeNode = {
        id: moduleIdCounters++, 
        // name: menu.label + " - " + moduleIdCounters,
        name: menu.label,
        isOpen: false,
        children: [],
    };

    processedMenuIds.add(menu.label);
    const menuChildren = menu.children || [];
    for (const childMenu of menuChildren) {
        const childNode = await buildMenuTree(childMenu, processedMenuIds,processResourcesIds, menuNode);
        if (childNode) {
           // console.log("childNode", childNode);
            menuNode.children.push(childNode);
        }
    }

    if (parentMenu) {
        const existingChild = parentMenu.children.find(child => child.id === menuNode.id);
        if (existingChild) {
            return null;
        }
    } 
    if (menuChildren.length === 0 && menu.resources) {
        for (const resource of menu.resources) {
          if(!resource){
            setDisplayNode(false);
          }
          // console.log("resource for menu",menu, "menu-----------",resource);
          processResourcesIds.add(resource.content);
            const resourceNode: TreeNode = {
                id: moduleIdCounters++, 
                name: `Resource:${resource.content} Type: ${resource.type}`,
                isOpen: false,
                children: [],
                display: false
            };
            menuNode.children.push(resourceNode);
            
        }
    }

    return menuNode;
    };
    var indexIncremrnt = 1;
  
    const treeDataResourcesPromises = processModulesChildrenRessources(appObj?.children);
    const treeDataResources = await Promise.all( await treeDataResourcesPromises);
    setTrRessource(treeDataResources.flat());
  } catch (error) {
    console.error('Error fetching data nana:', error);

}
};

fetchData();
}, [appObj,moduleName]);
  
const parseResource = (resourceElement: Element): any => {
  return {
      type: resourceElement.getAttribute("type"),
      content: resourceElement?.textContent?.trim()
  };
};
const parseXmlMenu = (xmlContent: string): any[] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(xmlContent, "application/xml");
  const elements = Array.from(doc.documentElement.children);
  return elements.map(parseElement);
};
const parseElement = (element: Element): any => {
  const tagName = element.tagName;
  if (tagName === "menu") {
      return parseMenu(element);
  } else if (tagName === "resource") {
      return parseResource(element);
  } 

}
const parseMenu = (menuElement: Element): any => {
  const id = menuElement.getAttribute("relId"||"absId");
  const label = menuElement.getAttribute("label");
  const childrenElements = Array.from(menuElement.getElementsByTagName("menu"));
  const resourcesElements = Array.from(menuElement.getElementsByTagName("resource"));
  const children = childrenElements.map(parseMenu);
  const resources = resourcesElements.map(parseResource);

  return { id, label, children , resources };
};

  const getActivitiesFromXml = (xml) => {
    const activities = Array();
    const parser = new DOMParser();
    const doc = parser.parseFromString(xml, "application/xml");
    Array.from(doc.getElementsByTagName("bpmn2:userTask")).map((task) => {
    const id = task.getAttribute("id");
    const name = task.getAttribute("name");
    const mask = task.getAttribute("tns:screenMask");
      activities.push({ id, name, mask });
    });

    return activities;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////



  const handleNouveauGroupeClick = () => {
    setShowModal(true);
    console.log("groupe from forage ", localforage.getItem('rolesData'));
    // localforage.getItem<{ role: string }[]>('rolesData').then(temp => {
    //   const tree : TreeNode[] = [];
    //   if (temp) {
    //     temp.forEach((item, i) => {
    //       const elem = {
    //         id: i++,
    //         name: item.role,
    //         isOpen: false,
    //         children: []
    //       };
    //       tree.push(elem);
    //     });
    //     setRolesData(tree);
    //   }
    // });
    
    
  };
  const handleGroupeNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupeName(event.target.value);
    setIsInputModified(true);
  };
  const resetCheckedNodes = (nodes) => {
    return nodes.map(node => {
      if (node.checked) {
        node.checked=false;
        console.log("the node is checked", node)
        resetCheckedNodes(node.children);
        return { ...node, checked: false }; 
      } else if (node.children && node.children.length > 0) {
        return { ...node, children: resetCheckedNodes(node.children) }; 
      } else {
        return node;
      }
    });
  };
  
  const resetProfileTreeCheckedNodes = () => {
    const updatedProfileTree = resetCheckedNodes(tr); 
    setTr(updatedProfileTree); 
  };
  const resetResourceTreeCheckedNodes = () => {

    const updatedResourceTree = resetCheckedNodes(trRessource); 
    setTrRessource(updatedResourceTree);
  };
  const closeModal = () => {
    setShowModal(false);
    setShowModalEdit(false);
    setActiveTabEdit('general');
    setIsInputModified(false);
    setGroupeName('');
    resetProfileTreeCheckedNodes();
    resetResourceTreeCheckedNodes(); 
    setShowSelectedOnly(false);
    setShowSelectedOnlyResources(false);
    

  };
  const closeModalDelete = () => {
    setShowModalDelete(false);
    setErrorMessage('');
  };

  const handleDeleteButtonClick = (enteredCode: string) => {
   
    if (enteredCode !== '111111') {
      setErrorMessage("Le code saisi n'est pas valide. Essayez-le à nouveau !");
    } else {
      const updatedGroupeData = groupesData.filter(grp => grp.groupe !== groupeNameDeleted);
      setGroupesData(updatedGroupeData);
      setShowModalDelete(false);
      setErrorMessage('');
      addToast(' Le groupe a été supprimé avec succès', 'success');
    }
  };

  useEffect(() => {
    if (!showModalDelete) {
        document.body.classList.remove("mobile-groups-verification-view")
    }
  }, [showModalDelete]
  )

    const handleTabsSelect = (tabName: string) => {
      setActiveTab(tabName);
    }
    const handleTabsEdit = (tabName: string) => {
      setActiveTabEdit(tabName);
    }
  const handleTabChange = (tabName: string) => {
    if (tabName === 'edit') {
      setShowModalEdit(true);
      setShowModal(true);

    }
    else if (tabName === 'delete') {
      setShowModalEdit(false);
      setShowModalDelete(true);

    }
    else if (tabName === 'duplicate') {
      setShowModal(true);

    }
    
   
  };
  
  const toggleChildren = (parentId: number) => {
    setTr(prevData =>
      prevData.map(node => {
        if (node.id === parentId) {
          return { ...node, isOpen: !node.isOpen };
        } else {
          return node;
        }
      })
    );
  };
 

  const handleCheckboxChangeRoles = (childId: number, checked: boolean) => {
    console.log("Checkbox clicked - Child ID:", childId, "Checked:", checked);
    let updatedTreeData = updateCheckedState(rolesStatiqueData, childId, checked);
    setRolesStatiqueData(updatedTreeData);
  };
  const handleCheckboxChange = (childId: number, checked: boolean) => {
    console.log("Checkbox clicked - Child ID:", childId, "Checked:", checked);
    let updatedTreeData = updateCheckedState(treeData, childId, checked);
    setTreeData(updatedTreeData);
  };

  const handleCheckboxChangeResource = (childId: number, checked: boolean) => {
    console.log("Checkbox clicked - Child ID:", childId, "Checked:", checked);
    let updatedTreeData = updateCheckedState(treeDataResource, childId, checked);
    setTreeDataResource(updatedTreeData);
  };

  const updateCheckedState = (nodes: TreeNode[], childId: number, checked: boolean): TreeNode[] => {
    return nodes.map(node => {
      if (node.id === childId) {
        return { ...node, checked: checked };
      } else if (node.children && node.children.length > 0) {
        const updatedChildren = updateCheckedState(node.children, childId, checked);
        const someChildrenChecked = updatedChildren.some(child => child.checked);
        const allChildrenChecked = updatedChildren.every(child => child.checked);
        return {
          ...node,
          checked: someChildrenChecked ? true : false,
          indeterminate: someChildrenChecked && !allChildrenChecked ? true : false,
          children: updatedChildren
        };
      } else {
        return node;
      }
    });
  };

  const [showSelectedOnly, setShowSelectedOnly] = useState(false); 
  const [showSelectedOnlyRoles, setShowSelectedOnlyRoles] = useState(false);
  const [showSelectedOnlyResources, setShowSelectedOnlyResources] = useState(false);

  const renderContent = () => {
    let filteredTreeData = treeData;
    if (showSelectedOnly) {
      filteredTreeData = filterCheckedNodes(treeData);
    }
    let filteredTreeDataResource = treeDataResource;
    if (showSelectedOnlyResources) {
      filteredTreeDataResource = filterCheckedNodes(treeDataResource);
    }
    let filteredTreeDataRoles= rolesStatiqueData;
    if (showSelectedOnlyRoles) {
      filteredTreeDataRoles = filterCheckedNodes(rolesStatiqueData);
    }
    switch (activeTab) {
        
      case 'tab1':
  const totalChildrenRoles = countLastChildren(rolesStatiqueData);
  const checkedChildrenRoles = countCheckedChildren(rolesStatiqueData);
      return rolesStatiqueData.length > 0 ? (
        <>
          <div className="header-selection-tree">
            <span className={`text-activity ${mode === 'dark' ? 'bg-light-dark' : ''}`}>
              {checkedChildrenRoles}/{totalChildrenRoles} roles alloués
            </span>
            <span className="text-select-tree"
              onClick={() => {
                setShowSelectedOnlyRoles(!showSelectedOnlyRoles);

              }}>
              {showSelectedOnlyRoles ? 'Afficher tous' : 'Afficher uniquement la sélection'}
            </span>
          </div>
        <TreeElementsModules
          treeData={filteredTreeDataRoles}
          toggleChildren={toggleChildren}
          handleCheckboxChange={handleCheckboxChangeRoles}
        /></>
      ) : (
        <div style={{color:'#94A3B8',display:'flex',justifyContent:'center',alignItems:'center',paddingTop:'20px'}}>Aucun résultat</div>
      );
      case 'tab2':
        const totalChildren = countLastChildren(treeData);
        const checkedChildren = countCheckedChildren(treeData);

        return (
          <div>
            <div className="header-selection-tree">
              <span className={`text-activity ${mode === 'dark' ? 'bg-light-dark' : ''}`}>
                {checkedChildren}/{totalChildren} activités allouées
              </span>
              <span className="text-select-tree"
                onClick={() => {
                  setShowSelectedOnly(!showSelectedOnly);

                }}>
                {showSelectedOnly ? 'Afficher tous' : 'Afficher uniquement la sélection'}
              </span>
            </div>
            <TreeElementsModules
              treeData={filteredTreeData}
              toggleChildren={toggleChildren}
              handleCheckboxChange={handleCheckboxChange}
            />
          </div>
        );
      case 'tab3':
        const totalChildrenR = countLastChildren(treeDataResource);
        const checkedChildrenR = countCheckedChildren(treeDataResource);

        return (
          <div>
            <div className="header-selection-tree">
              <span className={`text-activity ${mode === 'dark' ? 'bg-light-dark' : ''}`}>
                {checkedChildrenR}/{totalChildrenR} ressources allouées
              </span>
              <span className="text-select-tree" onClick={() =>
                setShowSelectedOnlyResources(!showSelectedOnlyResources)
              }>
                {showSelectedOnlyResources ? 'Afficher tous' : 'Afficher uniquement la sélection'}
              </span>
            </div>
            <TreeElementsModules
              treeData={filteredTreeDataResource}
              toggleChildren={toggleChildren}
              handleCheckboxChange={handleCheckboxChangeResource}
            />
          </div>
        ); default:
        return null;
    }
  };
  function MoreActions({ actions, closeCallback, style }: { actions: any, closeCallback?: Function, style?: React.CSSProperties }) {
    const moreOptions = useRef<HTMLDivElement>(null);
    const listObjActions = [
      {
        label: 'Editer',
        value: 'edit',
        icon: 'mode_edit_outline',
        color: '#64748B',
        handleClick: actions?.['edit']
      },

      {
        label: 'Dupliquer',
        value: 'duplicate',
        icon: 'content_copy',
        color: '#64748B',
        handleClick: actions?.['duplicate']
      },
      {
        label: 'Supprimer',
        value: 'delete',
        icon: 'delete_outline',
        labelColor: '#EF4444',
        handleClick: actions?.['delete']
      },

    ]

    return <div className="group-option-dropdown" ref={moreOptions}>
      <AdvancedDropDown data={listObjActions} enableSearch={false}
        light={mode === 'dark' ? false : true}
        handleChange={(item) => { item?.handleClick?.() }}
        size={1}
        selectedValue={''}
        style={style}
        closeWhenClickOutside={closeCallback}
        closeWhenSelect={closeCallback} />
    </div>
  }
  const [groupeNameDeleted, setGroupeNameDeleted] = useState('');

  const handleDeleteOnTable=(groupeDeleted)=>{
    setShowModalEdit(false);
    setShowModalDelete(true);
    setGroupeNameDeleted(groupeDeleted);
    window.scrollTo({ top: 0 })
    document.body.classList.add("mobile-groups-verification-view")  

  }
  const handleDuplicateOnTable = (groupeDuplicated) => {
    setShowModal(true);
    const traverseAndUpdate = (node) => {
      if (node.name === "Activite : Creer Article" ) {
                node.checked = true; 
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          traverseAndUpdate(child);
        });
      }
    };
  
    tr.forEach(node => {
      traverseAndUpdate(node); 
      let updatedTreeData = updateCheckedState(tr, node.id, node.checked||false);
    setTr(updatedTreeData);
    });
  const traverseAndUpdateR = (node) => {
      if ( node.name.includes("autorisations/articleae/process/GestionArticleAEProcess") || node.name==="Resource:autorisations/renouvellementodp/process/GestionRenouvellementODPProcess Type: launchProcess") {
                node.checked = true; 
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          traverseAndUpdateR(child);
        });
      }
    };
    trRessource.forEach(node => {
      traverseAndUpdateR(node); 
      let updatedTreeDataResources = updateCheckedState(trRessource, node.id, node.checked||false);
      console.log("updatedTreeDataResources", updatedTreeDataResources);
    setTrRessource(updatedTreeDataResources);
    });

    createGroupeObject(groupeName);
    

  }
  const mode = window.localStorage.getItem('mode')
  function AppTable({ servicesList }) {

    const data = servicesList.map((s) => {


      return {
        groupe: <span className={mode === 'dark' ? 'text-dark' : ''}>{s?.groupe || ''}</span>,
        profils: <span className={mode === 'dark' ? 'text-dark' : ''}>{s?.profils || ''}</span>,
        ressources: <span className={mode === 'dark' ? 'text-dark' : ''}>{s?.ressources || ''}</span>,
        utilisateurs: <span className={mode === 'dark' ? 'text-dark' : ''}>{s?.utilisateurs || ''}</span>,
        actions: <MoreActions
          actions={{
            'edit': () => handleEditOnTable(s?.groupe),
            'delete': () => handleDeleteOnTable(s?.groupe),
            'duplicate': () => handleDuplicateOnTable(s?.groupe)
          }}
          style={{ cursor: 'pointer' ,marginTop:'30px',}}
        />
      }
    })

    return <GroupesTable data={data} />
  }
  const filterCheckedNodes = (nodes: TreeNode[]): TreeNode[] => {
    let filteredNodes: TreeNode[] = [];
    nodes.forEach(node => {
      if (node.checked) {
        filteredNodes.push({
          ...node,
          children: node.children ? filterCheckedNodes(node.children) : []
        });
      }
    });
    return filteredNodes;
  };
  const countLastChildren = (nodes: TreeNode[]): number => {
    let count = 0;
    nodes.forEach(node => {
      if (node.children.length === 0) {
        count++;
      } else {
        count += countLastChildren(node.children);
      }
    });
    return count;
  };

  const countCheckedChildren = (nodes: TreeNode[]): number => {
    let count = 0;
    nodes.forEach(node => {
      if (node.children.length === 0 && node.checked) {
        count++;
      } else if (node.children.length > 0) {
        count += countCheckedChildren(node.children);
      }
    });
    return count;
  };

  const getLastCheckedNodeNames = (node, names: string[] = []) => {
    if (node.children && node.children.length > 0) {
const lastCheckedChildren = node.children.filter(child => child.checked);
if (lastCheckedChildren.length > 0) {
  lastCheckedChildren.forEach(child => {
    getLastCheckedNodeNames(child, names);
  });
}
} else if (node.checked) {
names.push(node.name);
}
return names;
};
  const createGroupeObject = async (groupeName) => {
    
    const apiKey="AB90G-BH903-W4EE1-Z66Q9-907E6";
    const profileData = filterCheckedNodes(tr).flatMap(node => {
      const lastCheckedNodeNames = getLastCheckedNodeNames(node);
      return lastCheckedNodeNames.map(name => ({
        profilename: name,
        penable: "true",
        ptag: "true",
        asset: "BASIC",
        addedby: currentUser().userId
      }));
    });
    
    const resourceData = filterCheckedNodes(trRessource).flatMap(node => {
      const lastCheckedNodeNames = getLastCheckedNodeNames(node);
      return lastCheckedNodeNames.map(name => ({
      rname: name?.split("Resource:")[1]?.split("Type")[0]?.trim()||'',
      type:name?.split(" Type: ")[1] || "",
      disabled: "false", 
      afficheMenu: "true",
      asset: "BASIC"
    }));
  });
    console.log("Profile data:", profileData);
    console.log("Resource data:", resourceData);

    const userId : string = currentUser().userId!;
    const groupeObject = {
      userId: userId,
      nameGroupe: groupeName,
      profile: profileData,
      resources: resourceData
    };
 
  let data = {
    apiKey: apiKey,
    userId: userId,
    name: groupeName,
    description:'',
    profiles: profileData,
    resources: resourceData
  };

  // let res = await fetch("/karazal/ow-kls-extrat/usermanager/createFullGroupe", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json"
  //   },
  //   body: JSON.stringify(data)
  // }).then(response => (response.json(),console.log("Vis result Groupe= ", response,"mydata",data)))
  //   .catch(error => console.log("error roledd", error));
  
  // console.log("data Groupe= ", data);  
  // console.log("postMap result = ", res);
    return groupeObject;
};

  const handleAjouterClick = async () => {
    if (groupeName.trim() === '') {
      setGroupeNameError('Ce champ est requis');
      return;
    }
    setGroupeNameError('');
    const newGroupeObject = createGroupeObject(groupeName);
    console.log("New groupe object:", newGroupeObject);
    // const profilsCheckedCount = countCheckedChildren(newRoleObject.profile);
    // const ressourcesCheckedCount = countCheckedChildren(newRoleObject.resources);
  
    const newGroupesData = [...groupesData, {
        groupe: (await newGroupeObject).nameGroupe,
        profils: (await newGroupeObject).profile.length, 
        ressources: (await newGroupeObject).resources.length, 
        utilisateurs: 0
    }];
  
    setGroupesData(newGroupesData as { groupe: string; profils: number; ressources: number; utilisateurs: number; }[]);
    addToast('Le groupe a été ajouté avec succès', 'success');
    localStorage.setItem('groupesData', JSON.stringify(newGroupesData));
    console.log("New Groupe data:", newGroupesData);
    console.log("Groupe data saved:", groupesData);  
  
    closeModal();
  };
  const [groupeNameEdit, setGroupeNameEdit] = useState('');

  const handleEdit=()=>{

    const updatedGroupesData = groupesData.map(groupe => {
      if (groupe.groupe === groupeNameEdit) {
        return {
          ...groupe,
          groupe: groupeName
        };
      }
      return groupe;
    });
  
    setGroupesData([...updatedGroupesData]);
    //setRolesData([...rolesData]);
    closeModal();
    
  }
  const handleEditOnTable = (groupeEdited) => {
    setShowModalEdit(true);
    setShowModal(true);
    setGroupeName(groupeEdited);
    setGroupeNameEdit(groupeEdited);
  
 
    const traverseAndUpdate = (node) => {
      if (node.name === "Activite : Creer Article" ) {
                node.checked = true; 
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          traverseAndUpdate(child);
        });
      }
    };
  
    tr.forEach(node => {
      traverseAndUpdate(node); 
      let updatedTreeData = updateCheckedState(tr, node.id, node.checked||false);
    setTr(updatedTreeData);
    });
  const traverseAndUpdateR = (node) => {
      if ( node.name.includes("autorisations/articleae/process/GestionArticleAEProcess") || node.name==="Resource:autorisations/renouvellementodp/process/GestionRenouvellementODPProcess Type: launchProcess") {
                node.checked = true; 
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          traverseAndUpdateR(child);
        });
      }
    };
    trRessource.forEach(node => {
      traverseAndUpdateR(node); 
      let updatedTreeDataResources = updateCheckedState(trRessource, node.id, node.checked||false);
      console.log("updatedTreeDataResources", updatedTreeDataResources);
    setTrRessource(updatedTreeDataResources);
    });
    
  };
  

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, groupeDataStatique.length);

  return (
    <div className='roles-empty-container'>
      {/* div1 */}      
        <div className="group-header-settings">
          <SettingsContainerHeader title="Groupes" subtitle="Regroupez les accès de vos utilisateurs à l'aide de groupes" enableAction={true}
          buttonName="Nouveau groupe" icon="add" onClick={handleNouveauGroupeClick}/>    
        </div>
      {/* horizontal line */}
      {/* div2 */}

      {groupeDataStatique.length <= 0  ? (
        <div className="container-roles">
          <div className="container-icon-role">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.9567 19.5753L26.8605 16.3565L29.125 20.025C30.9701 21.5011 33.4651 22.5 36.1562 22.5C38.8381 22.5 41.3345 21.5074 43.1875 20.025L46 15.4688L41.0759 12.5143C41.5112 11.716 41.7812 10.8154 41.7812 9.84375C41.7812 6.74147 39.2571 4.21875 36.1562 4.21875C33.0554 4.21875 30.5312 6.74147 30.5312 9.84375C30.5312 10.8154 30.8012 11.716 31.2366 12.5143L28.2564 14.3025C28.2312 14.2228 28.2262 14.1404 28.1857 14.0639C27.8218 13.3773 26.9745 13.1122 26.2851 13.4789L19.6412 17.0897C18.9545 17.4536 18.6922 18.3037 19.0562 18.9903C19.4219 19.6801 20.2746 19.9362 20.9567 19.5753ZM28.4892 33.1945C28.9108 32.5422 28.724 31.673 28.0717 31.25L21.8617 27.138L17.5324 24.4596L17.875 22.5938C17.875 19.4915 15.3509 16.9688 12.25 16.9688C9.14913 16.9688 6.625 19.4915 6.625 22.5938L6.96766 24.4596L2.40625 28.2188L5.21875 32.775C7.06384 34.2511 9.55881 35.25 12.25 35.25C14.9318 35.25 17.4283 34.2574 19.2812 32.775L21.0265 29.9478L26.5445 33.6121C27.2011 34.0357 28.0698 33.8427 28.4892 33.1945ZM41.7812 35.3438C41.7812 32.2415 39.2571 29.7188 36.1562 29.7188C33.0554 29.7188 30.5312 32.2415 30.5312 35.3438C30.5312 36.3154 30.8012 37.216 31.2366 38.0143L26.3125 40.9688L29.125 45.525C30.9701 47.0011 33.4651 48 36.1562 48C38.8381 48 41.3345 47.0074 43.1875 45.525L46 40.9688L41.0759 38.0143C41.5112 37.216 41.7812 36.3154 41.7812 35.3438Z" fill="#FFEDD5" />
              <path d="M29.125 20.025V18.2812C29.125 15.9469 31.0093 14.0625 33.3438 14.0625H36.1562C33.8299 14.0625 31.9375 12.1701 31.9375 9.84375C31.9375 7.51744 33.8299 5.625 36.1562 5.625C38.4826 5.625 40.375 7.51744 40.375 9.84375C40.375 12.1701 38.4826 14.0625 36.1562 14.0625H38.9688C41.3032 14.0625 43.1875 15.9469 43.1875 18.2812V20.025C45.7749 17.972 47.4062 14.7937 47.4062 11.25C47.4062 5.03428 42.372 0 36.1562 0C29.9405 0 24.9062 5.03428 24.9062 11.25C24.9062 14.7937 26.5376 17.9719 29.125 20.025ZM12.25 12.75C6.03428 12.75 1 17.7843 1 24C1 27.5438 2.63134 30.7219 5.21875 32.775V31.0312C5.21875 28.6969 7.10303 26.8125 9.4375 26.8125H12.25C9.92369 26.8125 8.03125 24.9201 8.03125 22.5938C8.03125 20.2674 9.92369 18.375 12.25 18.375C14.5763 18.375 16.4688 20.2674 16.4688 22.5938C16.4688 24.9201 14.5763 26.8125 12.25 26.8125H15.0625C17.397 26.8125 19.2812 28.6969 19.2812 31.0312V32.775C21.8687 30.722 23.5 27.5438 23.5 24C23.5 17.7843 18.4657 12.75 12.25 12.75ZM36.1562 25.5C29.9405 25.5 24.9062 30.5343 24.9062 36.75C24.9062 40.2938 26.5376 43.4719 29.125 45.525V43.7812C29.125 41.4469 31.0093 39.5625 33.3438 39.5625H36.1562C33.8299 39.5625 31.9375 37.6701 31.9375 35.3438C31.9375 33.0174 33.8299 31.125 36.1562 31.125C38.4826 31.125 40.375 33.0174 40.375 35.3438C40.375 37.6701 38.4826 39.5625 36.1562 39.5625H38.9688C41.3032 39.5625 43.1875 41.4469 43.1875 43.7812V45.525C45.7749 43.472 47.4062 40.2938 47.4062 36.75C47.4062 30.5343 42.372 25.5 36.1562 25.5Z" fill="#EA580C" />
            </svg>
          </div>
          <span className="empty-role-text">Aucun groupe pour l'instant</span>
          <span className="title-400">
            La liste des groupes créés sera affichée ici 
          </span>
        </div>
      ) : (
        <div style={{ padding: '35px' }}>
          <div style={{ marginBottom: '30px' }}> <SearchInput light={true} size={3} value={filter} setValue={handleSearchInputChange} addClearbutton={true} /></div>
          <div style={{ marginBottom: '20px' }} className="group-table-data-scroll">
            <AppTable servicesList={filteredGroupeData.slice(startIndex, endIndex)} /></div>
          {filteredGroupeData.length > itemsPerPage && <div style={{ height: '50px' }}>
            <Pagination
              light={true}
              totalItems={filteredGroupeData.length}
              itemsPerPage={itemsPerPage}
              maxPage={Math.ceil(filteredGroupeData.length / itemsPerPage)}
              handlePageChange={handlePageChange}
            />
          </div>}
        </div>)}
    
      {showModal && (
        <Modal
          icon=""
          leftBtnArgs={{
            args: { onClick: closeModal },
            color: 'neutral',
            label: 'Annuler',
            size: 'sm',
            styling: 'outline'
          }}
          rightBtnArgs={{
            args:  { onClick: showModalEdit ? handleEdit : handleAjouterClick },
            color: 'primary',
            label: {'Ajouter': 'Ajouter', 'Enregistrer': 'Enregistrer'}[showModalEdit ? 'Enregistrer' : 'Ajouter'],
            size: 'sm',
            styling: 'solid'
          }}
          title={showModalEdit ? "Détails du groupe" : "Nouveau groupe"}
          onClose={closeModal}
          bodyStyle={{ padding: '20px' }}
        >
          {showModalEdit && (
          <div className='tabs-roles-edit'>
            <Tabs current={'general'}
            align="horizontal"
            defSelected="general"
            icon={[]}
            iconAndName={[]}
            icons={[]}
            item={[]}
            onSelect={handleTabsEdit}
            size="small"
            tabs={[
              {
                label: 'Général',
                name: 'general'
              },
              {
                label: 'À propos',
                name: 'propos'
              },

            ]}
          /></div>)}
          {activeTabEdit === "general" ? (
  <>
          <Input
            label="Nom du groupe"
            size='sm'
            args={{
              placeholder: 'Entrer le nom du groupe',
              onChange: handleGroupeNameChange,
              value: groupeName
            }}
            helpText={helpText}
            errorMessage={!isInputModified ? groupeNameError : ''} 
            />

          <SelectTabs current={activeTab}
            align="horizontal"
            defSelected={activeTab}
            icon={[]}
            iconAndName={[]}
            icons={[]}
            item={[]}
            onSelect={handleTabsSelect}
            size="small"
            tabs={[
              {
                label: 'Roles',
                name: 'tab1'
              },
              {
                label: 'profils',
                name: 'tab2'
              },
              {
                label: 'Ressources',
                name: 'tab3'
              },
            ]}
          />
          <div>
            {renderContent()}
          </div>
          </>
):(activeTabEdit === "propos" &&
<div className="about-tabs-informations">

<div className='flex-space'><span className={`title-propos ${mode === 'dark' ? 'text-dark' : ''}`}>Date de création:</span> <span className={`descp-propos ${mode === 'dark' ? 'text-dark' : ''}`}>{aboutData.creationDate}</span></div>
<div className='flex-space'><span className={`title-propos ${mode === 'dark' ? 'text-dark' : ''}`}>Dernière modification: </span><span className={`descp-propos ${mode === 'dark' ? 'text-dark' : ''}`}>{aboutData.lastModification}</span></div>
<hr style={{ border:'none',borderTop:'1px solid #cdd4e0',margin:'10px 0'}}/>
<div className='flex-space'><span className={`title-propos ${mode === 'dark' ? 'text-dark' : ''}`}>Nombre d'utilisateurs:</span> <span className={`descp-propos ${mode === 'dark' ? 'text-dark' : ''}`}>{aboutData.numberOfUsers}</span></div>
</div>)}   
          <div />
        </Modal>
      )}
      <div className="app-groups-lap-deteleModal">
        {showModalDelete && (
          <ModalDelete
          closeModalDelete={closeModalDelete}
          handleDeleteButtonClick={handleDeleteButtonClick}
          errorMessage={errorMessage}></ModalDelete>

        )}
      </div>
      <div className="app-groups-mobile-viewVerification">
        {showModalDelete && (
          <MobileViewVerification title='Groupes' subtitle="Regroupez les accès de vos utilisateurs à l'aide de groupes" label="Suppression du rôle" labelContent="Nous avons envoyé un code à 6 caractères à votre adresse email.
          Veuillez le saisir ci-dessous :" labelbtn='Supprimer le role' findTitle="Vous ne trouvez pas votre code ? Vérifiez votre dossier Spam" handleVerificationClose={closeModalDelete} handleSubmit={handleDeleteButtonClick} />
        )}
      </div>
    </div>
  );
}