import { useEffect, useState } from "react";
import "../app-params/param-app.css"

import { Tabs } from "kls-ui";
import { AppComponent } from "./app-component";
import { ModuleComponent } from "./module-component";
import { ShemaComponent } from "./shema-component";
import { RolesComponent } from "./roles-component";
import { MenusComponent } from "./menus-component";
import { AccessControlComponent } from "./accessControl-component";
import { DeleteAppComponent } from "./deleteApp-component";
import { fetchKlsResource, getWorkingDirectorTree, listModules, writeKlsResource } from "kls-commons/service/repo-service";
import { useParams } from "react-router-dom";
import { WorkingDirTree } from "kls-commons/types/repo-model";
import { getTenant } from "kls-commons/service/resource-service";
import { strToXmlElement, xmlElement2str } from "kls-commons/help/tools";

export function ParamApp(props: any) {
  const [selectedTab, setSelectedTab] = useState('app');
  const { moduleName } = useParams() as any;
  const [directories, setDirectories] = useState<string[]>([]);
  const [appObj, setAppObj] = useState<WorkingDirTree>();
  const [direc, setDirec] = useState<string[]>([]);
  const [allMenus, setAllMenus] = useState<string[]>();
  const [defaultMenu, setDefaultMenu] = useState<string>();
  const [firstModel, setFirstModel] = useState("");
  const [kconfigContent, setKconfigContent] = useState("");
  const [activedMenus, setActivedMenus] = useState([""]);

  const [dataFromChild, setDataFromChild] = useState<string>('');

  // Callback function to receive data from child
  const handleDataFromChild = (data: string) => {
    setDataFromChild(data);
    console.log('test123',data);
    
  };
  const listParam = [

    { name: 'app', label: <><span className="icon">bubble_chart</span>Application</>, component: <AppComponent  moduleName={moduleName} sendDataToParent={handleDataFromChild}/>  },
    { name: 'modules', label: <><span className="icon">apps</span>Modules</>, component: <ModuleComponent /> },
    { name: 'shema', label: <><span className="icon">schema</span>Shéma relationnel </>, component: <ShemaComponent /> },
    { name: 'roles', label: <><span className="icon">workspaces</span>Roles</>, component: <RolesComponent /> },
    { name: 'menus', label: <><span className="icon">menu</span>Menus </>, component: <MenusComponent moduleName={moduleName} 
                                                                                                      modelName={firstModel} 
                                                                                                      allMenus={allMenus} 
                                                                                                      kconfigContent={kconfigContent} 
                                                                                                      activedMenus={activedMenus}
                                                                                                    /> },
    { name: 'accessControl', label: <><span className="icon">login</span>Contrôles d’accès </>, component: <AccessControlComponent /> },
    { name: 'deleteApp', label: <><span className="icon">remove_circle_outline</span>Suppression d’application </>, component: <DeleteAppComponent /> },

  ]



  useEffect(() => {
    getWorkingDirectorTree(moduleName).then(
      (tree: WorkingDirTree) => {
        setAppObj(tree);
        setDirectories(Object.keys(tree.children || {}));
      }
    )

    listModules().then(
      modules => {
        setDirec(modules);
      }
    )

  }, [moduleName])

  useEffect(() => {
    if (appObj && appObj.children) {
      let objAide = appObj;

      let firstModuleName = Object.keys(appObj.children)[0];
      if (firstModuleName === 'module.json' ) {
        firstModuleName = Object.keys(appObj.children)[1];
    }
      const firstModule = appObj.children[firstModuleName];
      setFirstModel(firstModuleName);

      setAllMenus(Object.keys(firstModule.children as { [key: string]: WorkingDirTree }).filter(key => key.toLocaleLowerCase().endsWith("-menu.xml") && key.toLowerCase() !== (firstModuleName.toLowerCase() + "-menu.xml")));
      setDefaultMenu(Object.keys(firstModule.children as { [key: string]: WorkingDirTree }).find(key =>
        key.toLowerCase() === (firstModuleName.toLowerCase() + "-menu.xml")

      ));
      fetchKlsResource(moduleName, 'klsapp/' + getTenant() + '/' + moduleName + '/' + firstModuleName + '/kconfig.xml').then((res) => {
        setKconfigContent(res.content!);
      });

    }
    console.log("heeeeeey");
  }, [appObj ])


  useEffect(() => {

    setActivedMenus(getMenuResourceNames(kconfigContent));

  }, [kconfigContent])
  useEffect(() => {
    console.log("ssssssssssssssss param app", activedMenus);

  }, [activedMenus])
  useEffect(() => {
    console.log("seeeee allMenus", allMenus);
    console.log("seeeee defaultMenu", defaultMenu)
  }, [allMenus, defaultMenu])
  useEffect(() => {
    console.log("seeeee directories", directories);
    console.log("seeeee direc", direc);

  }, [directories, direc])
  useEffect(() => {
    console.log("seeeee appobj", appObj);

  }, [appObj])

  useEffect(()=>{
    console.log("firstModel",firstModel)
  },[firstModel])
  const handleTabSelect = (tabName: string) => {
    setSelectedTab(tabName);
  };



  return (<>
    <div className="parametre-app">
      <div className="text-md font-500">Paramètres de l'application <span className="blue-600">{dataFromChild}</span> </div>
      <div className="parametre-app-details">
        <div className='tab-container'>
          <Tabs tabs={listParam} size="medium" align={'vertical'} onSelect={handleTabSelect} current={selectedTab}
            defSelected={"app"} />
        </div>

        <div className="parametre-app-items">

          {listParam.map((param) => (
            <div key={param.name} style={{ display: selectedTab === param.name ? 'block' : 'none' }}>
              {param.component}
            </div>
          ))}
        </div>
      </div>
    </div>
  </>);
}

export function getMenuResourceNames(kconfig: string): string[] {
  let content = strToXmlElement(kconfig, null);

  const menuTags = content?.getElementsByTagName("menu");

  const allResourceNames: string[] = [];
  if (menuTags) {

    for (let i = 0; i < menuTags.length; i++) {
      const menuTag = menuTags[i];
      const resourceName = menuTag.getAttribute("resourceName");
      if (resourceName) {
        allResourceNames.push(resourceName);
      }
    }

  }
  return allResourceNames;
}

export function activOrDesactivMenu(kconfig, fileName, rs, moduleName) {
  let content = strToXmlElement(kconfig, null);
  if (content) {
    const menuTags = content?.getElementsByTagName("menu");
    if (menuTags.length) {
      for (let i = 0; i < menuTags.length; i++) {
        const menu = menuTags[i];
        const res = menu.getAttribute("resourceName");
        if (res === fileName) {
          const menusElement = content?.querySelector("menus");
          menusElement?.removeChild(menu);
          let newContent = xmlElement2str(content);
          writeKlsResource(moduleName, rs, newContent)
          return;
        }
      }

      const newMenu = content.ownerDocument.createElement("menu");
      newMenu.setAttribute("resourceName", fileName);
      newMenu.setAttribute("webContext", "FrontEnd");
      const menusElement = content.getElementsByTagName("menus")[0];
      menusElement.appendChild(newMenu);
      let newContent = xmlElement2str(content);
      writeKlsResource(moduleName, rs, newContent)
    }
  }

}