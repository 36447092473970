import { Link, useHistory } from "react-router-dom";
import './layout.css';
import { MouseEventHandler, useEffect, useState ,useRef } from "react";
import { AppsExplorer } from "../apps-explorer/apps-explorer";
import { CreationModal } from "kls-ui/creation-modal/creation-modal";
import { getTenant, getTypeOf } from "kls-commons/service/resource-service";
import { NavBar } from "../App";
import { Modal } from "kls-ui";
export interface HeaderProps{
    children:any;
    handleChangeResource?:Function;
}

export const Header = ({children}:HeaderProps,props) => { 

    const [showAppsExplorer, setShowAppsExplorer] = useState<boolean>( false );
    const [showNavbarMenu, setShowNavbarMenu] = useState<boolean>( false );
    const [createNewElement, setCreateNewElement] = useState<any>( null ) ;
    const history = useHistory() ;
    const navbarRef = useRef<HTMLDivElement>(null);

    const handleClickLogo:MouseEventHandler<HTMLButtonElement> = () => {
       if(window.innerWidth > 768){
        setShowAppsExplorer( true );
       }
       else{
        setShowAppsExplorer(false)
        setShowNavbarMenu(true)
       }        
    }

    useEffect(() => {
        const handleResize = () => {
            if(window.innerWidth <= 768){
                setShowAppsExplorer(false)
            };
        };
    
        window.addEventListener("resize", handleResize);
    
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                setShowNavbarMenu(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
 
    console.log("setShowAppsExplorer",showAppsExplorer)
    console.log("showNavbarMenu",showNavbarMenu)

    const handleChangeResource = (item) => {
        console.log( 'item ' , item )
        let path = item?.value?.replace( 'klsapp/' + getTenant() + '/' , '' )?.split?.( '/' )||[] ; 
        if( path.length > 1 )  {
            path.splice(path.length-1, 0, getTypeOf( item.value ));
        }
        history.push( '/editService/' + path.join('/') );
        setCreateNewElement( false );
    }
    return  <header className={window.localStorage.getItem('mode') === 'dark' ? 'bg-dark' : 'bg-light'}>
        {
            showAppsExplorer && <div className='dropdown-module-explorer' >
                <AppsExplorer handleCreateNewElement={setCreateNewElement}  onClose={ () => setShowAppsExplorer(false)  }></AppsExplorer>
            </div>
        }
        {
            createNewElement && 
            <CreationModal  currentModel={createNewElement.model} 
                            currentModule={createNewElement.module} 
                            resourceType={createNewElement.typeResource} 
                            onClose={()=>{setCreateNewElement(null)}}
                            handleChange={ (item)=> handleChangeResource(item) }  />
        }
        {
            showNavbarMenu && <div className="header-pop-up-navbar" ref={navbarRef} >
                 <NavBar selectedItem={props.selectedItem} />                
            </div>

        }
        <div className="header-elements">
            <button className="block-header logo-container" style={{padding:"8px"}} onClick={ handleClickLogo } >
                <img className="kls-header-logo" src="/icons/karaz.svg" alt="KSW"></img>
            </button>
            { children }
        </div>
    </header>
}

