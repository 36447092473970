import React, { useEffect, useState } from "react";
import "./App.css";
import { HashRouter, Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { currentUser, fetchLocalUserProfile, fetchLocalUserDetails, checkFirstConnect, signout, storeLocalUserProfile } from "kls-commons/service/userService";
import { GS, setToStorage } from "kls-commons/state/global-state";
import { getTemplatesDirectoryTree, listModules } from "kls-commons/service/repo-service";
import ModuleEditor from "./module/module-editor";
import { Header } from "./layout/header";
import { Button, Dropdown, Hint, Input, ProfileHeader } from 'kls-ui';
import { ParamApp } from "./app-params/param-app";
import { PwdReset, Signup, Login, FirstLogin } from "kls-auth";
import MyAppsPage from "./on-boarding-pages/my-apps";
import { Home } from "./on-boarding-pages/home-page";
import { SettingsModule } from "./organization-settings/settings-module";
import { CataloguePage, MyOrganization } from "kls-catalog";
import { StepsBar, StepsBarMobile } from "./on-boarding-pages/steps-bar";
import { AllCourses } from "./courses/all-courses";
import { URL } from "url";
import { AppearanceServices } from "./settings/settings-services/appearance-services";

function AppHome(props: any) {
  const [firstConnection, setFirstConnection] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [itemToDisplay, setItemToDisplay] = useState<string>();
  const location = useLocation();
  const [themeHandler, setthemeHandler] = useState(false)
  const [hintType, sethintType] = useState("")
  const [themeMode, setthemeMode] = useState('')
  useEffect(
    () => {
      setLoader(true);
      let userPRofileText = localStorage.getItem("USER_PROFILE");
      let userID = '';
      if (userPRofileText) {
        let result = JSON.parse(userPRofileText);
        userID = result === null || result === void 0 ? void 0 : result.userId;
      }
      if (userID != undefined) {
        checkFirstConnect(userID).then(
          async (authenticated: any) => {
            if (authenticated) {
              setLoader(false);
              let result = fetchLocalUserDetails(); 
              if (result?.firstConnect == true) {
                setFirstConnection(result?.firstConnect)
              } 
            } else {
              console.log(authenticated)
            }
          }).catch(error => {
            setLoader(false);
            console.log(error)
          });
      }
      let loggedinUser = fetchLocalUserProfile();
      AppearanceServices(loggedinUser?.userId).then((res) => {
        const mode = res.data.mode;
        if(mode === 'light' || mode === 'clair') {
          window.localStorage.setItem('mode', 'light')
          setthemeMode('light')
        }
        else if(mode === 'dark' || mode === 'sombre') {
          window.localStorage.setItem('mode', 'dark')
          setthemeMode('dark')
        }
        else if(mode === 'system') {
          if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            window.localStorage.setItem('mode', 'dark')
            setthemeMode('dark')
          }
          else {
            window.localStorage.setItem('mode', 'light')
            setthemeMode('light')
          }
        }
        else {
          window.localStorage.setItem('mode', 'light')
          setthemeMode('light')
        }
      }).catch(() => {window.localStorage.setItem('mode', 'light'); setthemeMode('light')});
    }, []
  )
  const mode = window.localStorage.getItem('mode')
  useEffect(
    () => {
      setItemToDisplay(location.pathname.split("/")?.[1]);
    }, [location]
  )

  useEffect(() => {
    !themeHandler && sethintType("")
  }, [themeHandler])
  return <Switch>
    <Route path="/editService/:moduleName" exact strict>
      <ModuleEditor></ModuleEditor>
    </Route>

    <Route path="/editService/:moduleName/:modelName" exact strict>
      <ModuleEditor></ModuleEditor>
    </Route>

    <Route path="/editService/:moduleName/:modelName/:resourceType/:resourceName" exact strict>
      <ModuleEditor></ModuleEditor>
    </Route>

    <Route path="/parametreApp/:moduleName" exact>
      <CommonHeader selectedItem={'application'} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />
      <ParamApp />
    </Route>
    <Route path="/organisation-settings/:organisationID" exact>
      <CommonHeader selectedItem={itemToDisplay} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />
      {itemToDisplay === "organisation-settings" && <SettingsModule type="organizationSettings" tab="Organisation" gs={GS} />}
    </Route>
    <Route path="/application-settings/:applicationID" exact>
      <CommonHeader selectedItem={itemToDisplay} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />
      {itemToDisplay === "application-settings" && <SettingsModule type="applications" tab="application" gs={GS} />}
    </Route>
    <Route path="/courses" exact>
    <CommonHeader selectedItem={itemToDisplay} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />
      <AllCourses />
    </Route>
    <Route path="/courses/:courseID" exact>
      <CommonHeader selectedItem={itemToDisplay} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />
      {itemToDisplay === "courses" && <SettingsModule type="courses" tab="create apps" gs={GS} />}
    </Route>
    <Route path="/courses/:courseID/individual" exact>
      <CommonHeader selectedItem={itemToDisplay} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />
      {location.pathname.split("/")?.[3] === 'individual' && <SettingsModule type="individualCourse" gs={GS} />}
    </Route>
    <Route path="/module-settings/:moduleID" exact>
      <CommonHeader selectedItem={itemToDisplay} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />
      {itemToDisplay === "module-settings" && <SettingsModule type="modules" tab="module" gs={GS} />}
    </Route>
    <Route path="/notifications" exact>
      <CommonHeader selectedItem={itemToDisplay} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />
      {itemToDisplay === "notifications" && <SettingsModule type="settings" tab="notifications" gs={GS} />}
    </Route>
    <Route path="/">
      {
        firstConnection ?
          <FirstLogin gs={GS} connected={true}></FirstLogin>
          :
          loader ? <div className="inprogress-inner">
            <div className="spinner">
              <i className="fa fa-spinner fa-spin"></i>
            </div>
          </div> : <>
            <CommonHeader selectedItem={itemToDisplay} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />

            {itemToDisplay === 'application' && <MyAppsPage gs={GS} />}

            {itemToDisplay === 'catalog' && <CataloguePage />}

            {itemToDisplay === "settings" && <SettingsModule type="settings" tab={"workspace"} gs={GS} />}
            {itemToDisplay === "appearance" && <SettingsModule type="settings" tab={"appearance"} gs={GS} />}

            {itemToDisplay === "users" && <SettingsModule type="organisation" tab={"Users"} hintType={hintType} sethintType={sethintType} />}
            {itemToDisplay === "organisation" && <MyOrganization hintType={hintType} gs={GS} ></MyOrganization>}

            {itemToDisplay === "" && <Home gs={GS} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />}
          </>
      }


    </Route>
  </Switch>

}



function App() {
  let [userProfile, setUserProfile] = useState(fetchLocalUserProfile());
  const [themeMode, setthemeMode] = useState('')
  useEffect(() => {
    getTemplatesDirectoryTree(undefined, undefined).then((s) => {
      if (s) {
        setToStorage("TEMPLATE_MODELS", s);
      }
    });
    let loggedinUser = fetchLocalUserProfile();
      AppearanceServices(loggedinUser?.userId).then((res) => {
        const mode = res.data.mode;
        if(mode === 'light' || mode === 'clair') {
          window.localStorage.setItem('mode', 'light')
          setthemeMode('light')
        }
        else if(mode === 'dark' || mode === 'sombre') {
          window.localStorage.setItem('mode', 'dark')
          setthemeMode('dark')
        }
        else if(mode === 'system') {
          if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            window.localStorage.setItem('mode', 'dark')
            setthemeMode('dark')
          }
          else {
            window.localStorage.setItem('mode', 'light')
            setthemeMode('light')
          }
        }
        else {
          window.localStorage.setItem('mode', 'light')
          setthemeMode('light')
        }
      }).catch(() => {window.localStorage.setItem('mode', 'dark'); setthemeMode('dark')});
  }, []);
  return (
    <HashRouter>
      <div className={`App ${themeMode === 'dark' ? 'bg-light-dark' : ''}`}>
        <Switch>
          <Route path="/reset-pwd/:activationId/:email">
            <PwdReset></PwdReset>
          </Route>
          <Route path="/login">
            <Login postSignIn={setUserProfile}></Login>
          </Route>
          <Route path="/signup">
            <Signup postSignIn={setUserProfile}></Signup>
          </Route>
          <Route path="/">
            {userProfile && <AppHome gs={GS}></AppHome>}
            {!userProfile && <Login postSignIn={setUserProfile} ></Login>}
          </Route>
        </Switch>
        <div className="toasts-container"></div>
      </div>
    </HashRouter>
  );
}


function NavBar({ selectedItem }: { selectedItem: string }) {
  const mode = window.localStorage.getItem('mode')

  const navBar = [
    {
      link: '',
      label: 'Accueil'
    },
    {
      link: 'organisation',
      label: 'Organisations'
    },
    {
      link: 'application',
      label: 'Applications'
    },
    {
      link: 'catalog',
      label: 'Catalogue'
    },
    {
      link: 'resource',
      label: 'Ressources'
    }
  ]


  return <div className="navbar-container">
    {
      navBar.map(
        (item: any, index: number) => <Link key={index} className={`${mode === 'dark' ? selectedItem === item.link ? 'text-selected' : 'text-selected-hover' : ''} link-container ${selectedItem === item.link ? `selected` : ''} ${mode === 'dark' ? 'bg-light-dark' : ''}`} to={`/${item.link}`}><span className={`color-link ${mode === 'dark' ? selectedItem === item.link ? 'text-black' : 'text-selected-hover' : ''}`}>{item.label}</span></Link>
      )
    }
  </div>
}


function CommonHeader(props: any) {
  const history = useHistory()
  const [position, setposition] = useState("righttop")
  const [orgPosition, setOrgPosition] = useState("left")
  const [notifications, setnotifications] = useState(false)
  const mode = window.localStorage.getItem('mode')
  useEffect(() => {
    const updatePosition = () => {
      if (window.innerWidth <= 700) {
        setposition('topright')
        setOrgPosition('bottomright')
      }
      else {
        setposition("righttop")
        setOrgPosition('left')
      }
    }
    updatePosition();
    window.addEventListener('resize', updatePosition)
    return () => {
      window.removeEventListener('resize', updatePosition)
    }
  }, [])
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const d = document.getElementById('notification-div');
      if (d && !d.contains(e.target as HTMLElement)) {
        setnotifications(false)
      }
    };
    document.body.addEventListener('mousedown', handleClickOutside);
  }, [setnotifications]);
  const notificationsData = [
    {"image": "/photos/OrganizationLogo.png", "name": "Kamal Chaoui", "email": "@Imane.Lamani", "message": "Vous a ajouté à l’organisation", "represent": "Emdaa", "time": "1h"},
    {"image": "", "name": "Hafsa Radi", "email": "@Imane.Lamani", "message": "a demandé de rejoindre l’organisation", "represent": "UM6", "time": "1h"},
    {"image": "/photos/ribatis_notifications_icon.svg", "name": "Ribatis", "email": "@Ribatis", "message": "Veuillez remplir le reste de vos informations.", "represent": "Mon compte", "time": "1h"},
    {"image": "/photos/ribatis_notifications_icon.svg", "name": "Ribatis", "email": "@Ribatis", "message": "Votre mot de passe à été modifié.", "represent": "Mon compte", "time": "1h"}
  ]
  return (
    <>
      <Header>
        <NavBar selectedItem={props.selectedItem} />

        {/* <StepsBar themeHandler={props?.themeHandler} setthemeHandler={props?.setthemeHandler} hintType={props?.hintType} sethintType={props?.sethintType} /> */}
        <div className="profile-notifications">
          <div className='notifications' onClick={() => setnotifications(true)}>
            <span className='icon notifications-icon' style={{ fontSize: '24px' }}>notifications</span>
            {notifications && 
              <div className={`notifications-main ${mode === 'dark' ? 'bg-light-dark' : ''}`} id="notification-div">
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '12px'}}>
                  <div className="notifications-header">
                    <div className={`notifications-arrow ${mode === 'dark' ? 'bg-light-dark' : ''}`}></div>
                    <span className={`${mode === 'dark' ? 'text-dark' : ''}`}>Notification</span>
                    <div className="notifications-header-close" onClick={() => setnotifications(false)}><span className="icon" style={{fontSize: '20px', color: '#35405C'}}>close</span></div>
                  </div>
                  <div>
                    {notificationsData&& notificationsData.map((c) => {
                      return <NotificationsContent image={c.image} name={c.name} email={c.email} message={c.message} represent={c.represent} time={c.time} />
                    })}
                  </div>
                  <div className="notifications-footer">
                    <Button label="Voir toutes les notifications" size="md" color="primary" styling="solid" args={{onClick: () => {history.push('/notifications'); setnotifications(false)}, style: {width: '100%'}}} />
                  </div>
                </div>
              </div>
            }
          </div>
          <ProfileHeader profileName={currentUser().fullName} profileImage={currentUser().avatarUrl ?? ""} profileEmail={currentUser().id ?? ""} klsVersion="KLS version 0.7" settingsHandler={() => document.location = "/#/settings"} logoutHandler={() => { storeLocalUserProfile(null); signout(); document.location = "/"; }} themeHandler={props?.themeHandler} setthemeHandler={props?.setthemeHandler} hintType={props?.hintType} />
        </div>
      </Header>
      {/* <StepsBarMobile themeHandler={props?.themeHandler} setthemeHandler={props?.setthemeHandler} hintType={props?.hintType} sethintType={props?.sethintType} /> */}
      {props.themeHandler && props.hintType === "theme" ? <div className="common-hint-main">
        <div className="theme-setting-view" onClick={() => document.location = "/#/settings"}>
          <span className="icon">settings</span>
          <span>Paramètres</span>
        </div>
        <div className="theme-hint">
          <Hint
            title="Choisissez le thème de votre espace de travail"
            content="Vous pouvez choisir entre le mode sombre et le mode clair en utilisant l'icône en forme de soleil ou de lune située en haut à droite."
            button="Suivant"
            currentCount={1}
            totalCount={9}
            position={position}
            close={props.themeHandler}
            onClose={() => props.setthemeHandler(!props.themeHandler)}
            onClick={() => { props.setthemeHandler(!props.themeHandler); history.push('/appearance') }}
          />
        </div>
      </div> : props.themeHandler && props.hintType === "orgData" ? <><div className="common-hint-main orgData-hint-main">
        <div className="orgData-setting-view">
          <div style={{padding: "6px 16px"}}>Editer</div>
          <div style={{padding: "6px 16px", background: "#F1F5F9"}}>Paramètres</div>
          <div style={{padding: "6px 16px"}}>Monitoring</div>
        </div>
        <div className="orgData-hint">
          <Hint
            title="Paramètres de l’organisation"
            content='Vous pouvez compléter les informations de votre organisation en cliquant sur "Paramètres".'
            button="Suivant"
            currentCount={2}
            totalCount={9}
            position={orgPosition}
            close={props.themeHandler}
            onClose={() => props.setthemeHandler(!props.themeHandler)}
            onClick={() => { props.setthemeHandler(!props.themeHandler); history.push('/organisation-settings/1') }}
          />
        </div>
      </div></> : props.themeHandler && props.hintType === "users" ? <div className="common-hint-main orgData-hint-main">
      <div className="orgData-setting-view">
          <div style={{padding: "6px 16px"}}>Editer</div>
          <div style={{padding: "6px 16px", background: "#F1F5F9"}}>Paramètres</div>
          <div style={{padding: "6px 16px"}}>Monitoring</div>
        </div>
        <div className="orgData-hint orgData-user-hint-view">
          <Hint
            title="Ajouter des collaborateurs"
            content='Vous pouvez ajouter des collaborateurs en cliquant sur "Paramètres", puis sur "Utilisateurs".'
            button="Suivant"
            currentCount={3}
            totalCount={9}
            position={orgPosition}
            close={props.themeHandler}
            onClose={() => props.setthemeHandler(!props.themeHandler)}
            onClick={() => { history.push('/users', { message: "true" }); props.sethintType("userSetting") }}
          />
        </div>
      </div> : props.themeHandler && props.hintType === "application" ? <div className="common-hint-main application-hint-main">
        <div className="application-hint">
          <Hint
            title="Editer une application"
            content='Vous pouvez éditer cette application à Vous pouvez regarder le tutoriel pour les étapes complètes.'
            button="Terminer"
            currentCount={9}
            totalCount={9}
            position="left"
            close={props.themeHandler}
            onClose={() => props.setthemeHandler(!props.themeHandler)}
          />
        </div>
      </div> : props.themeHandler && props.hintType === "userSetting" ? <div className="common-hint-main">
        <div className="user-setting-hint">
          <Hint
            title="Ajouter des collaborateurs"
            content='Cliquez sur le bouton "Nouvel utilisateur" pour ouvrir une fenêtre contextuelle et remplir les informations.'
            button="Suivant"
            currentCount={4}
            totalCount={9}
            position="right"
            close={props.themeHandler}
            onClose={() => props.setthemeHandler(!props.themeHandler)}
            onClick={() => { props.sethintType("userModal"); props.setthemeHandler(false) }}
          />
        </div>
        <div className="users-setting-view ">
          <span className="icon">people_alt</span>
          <span>Utilisateurs</span>
        </div>
      </div> : props.themeHandler && props.hintType === "groupHint" ? <div className="common-hint-main">
        <div className="user-setting-hint">
          <Hint
            title="Créer des groupes"
            content={`Pour créer des "groupes", veuillez regarder les tutoriels sur la page d'accueil expliquant comment ajouter des profils et ressources.`}
            button="Suivant"
            currentCount={5}
            totalCount={9}
            position="left"
            close={props.themeHandler}
            onClose={() => props.setthemeHandler(!props.themeHandler)}
            onClick={() => { props.sethintType("addgroup") }}
          />
        </div>
      </div> : props.themeHandler && props.hintType === "addGroup" ? <div className="common-hint-main">
        <div className="user-setting-hint">
          <Hint
            title="Ajouter des groupes"
            content={`Cliquez sur le bouton "Nouveau groupe" pour ouvrir une fenêtre contextuelle et remplir les informations.`}
            button="Suivant"
            currentCount={6}
            totalCount={9}
            position="right"
            close={props.themeHandler}
            onClose={() => props.setthemeHandler(!props.themeHandler)}
            onClick={() => { props.sethintType("groupModal"); props.setthemeHandler(false) }}
          />
        </div>
      </div> : props.themeHandler && props.hintType === "soucheHint" ? <div className="common-hint-main">
        <div className="user-setting-hint">
          <Hint
            title="Créer des souches"
            content={`Pour créer des "Souches", veuillez regarder les tutoriels sur la page d'accueil expliquant comment ajouter des utilisateurs.`}
            button="Suivant"
            currentCount={7}
            totalCount={9}
            position="left"
            close={props.themeHandler}
            onClose={() => props.setthemeHandler(!props.themeHandler)}
            onClick={() => { props.sethintType("addSouche") }}
          />
        </div>
      </div> : props.themeHandler && props.hintType === "addSouche" ? <div className="common-hint-main">
        <div className="user-setting-hint">
          <Hint
            title="Ajouter des souches"
            content={`Cliquez sur le bouton "Nouvelle souche" pour ouvrir une fenêtre contextuelle et remplir les informations.`}
            button="Suivant"
            currentCount={8}
            totalCount={9}
            position="left"
            close={props.themeHandler}
            onClose={() => props.setthemeHandler(!props.themeHandler)}
            onClick={() => { props.sethintType("soucheModal") }}
          />
        </div>
      </div> : <></>
      }
    </>
  )
}

export{NavBar}

export default App;

export const NotificationsContent = ({image, name, email, message, represent, time, style={}}) => {
  const mode = window.localStorage.getItem('mode')
  const InitialName = name.toUpperCase().split(' ')
  const userInitials = InitialName.length > 1 ?  InitialName[0][0] + InitialName[1][0] : InitialName[0].slice(0, 2)
  return(
    <div className={`notifications-content-main ${mode === 'dark' ? 'border-dark' : ''}`} style={style}>
      <div className="notifications-content-profile">
        <div className={`notifications-content-img ${mode === 'dark' ? 'text-dark' : ''}`} style={{background: image !== '' ? `url(${image}) 50% 0% / contain no-repeat` : 'none'}}>{image === '' && userInitials}</div>
        <div style={{fontWeight: '500', fontSize: '15px', display: 'flex', flexDirection: 'column', gap: '2px', justifyContent: 'space-between', width: '100%'}}>
          <div style={{display: 'flex', gap: '2px'}}>
            <span className={`notifications-name ${mode === 'dark' ? 'text-dark' : ''}`}>{name}</span>
            <span style={{color: '#9A9FA5'}}>{email}</span>
          </div>
          <div style={{display: 'flex', gap: '2px'}}>
            <span style={{color: '#6F767E'}}>{message}</span>
            <span style={{fontWeight: '700', color: '#35405C'}}>{represent}</span>
          </div>
        </div>
      </div>
      <div className="notifications-content-profile">
        <span style={{fontSize: '13px', fontWeight: '600', color: '#9A9FA5'}}>{time}</span>
        <span style={{width: '12px', height: '12px', borderRadius: '6px', backgroundColor: '#2C61E7'}}></span>
      </div>
    </div>
  )
}